import * as React from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';
import { Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Navbar, NavItem, NavLink, Input, Col, Row, NavbarBrand, NavbarToggler, UncontrolledDropdown, Nav, InputGroup, Button, Form } from 'reactstrap';
import { checkExp, getRedirectAppPoolUrl, parseJwt, convertSearchKey } from '../modules/common';
import Swal from 'sweetalert2';
import './dms/dms.css';
import './NavMenu.css';

import { ApplicationState } from '../store';
import * as MasterCompanyStore from '../store/CompanyMaster'
import * as MasterCompanyModels from '../models/CompanyMaster'

import * as AppStateStore from '../store/AppState'
import * as AppStateModels from '../models/AppState'

import * as CompanyShortNameStore from '../store/CompanyShortName'
import * as CompanyShortNameModels from '../models/CompanyShortName'

import * as CheckCompanyUserStore from '../store/CheckCompanyUser'
import * as CheckCompanyUserModels from '../models/Employee'

import { RoleAccessResponse, UserRoleAccessModel } from '../models/RoleAccess';

import { IUserContext, UserContext } from '../context/UserProvider';
import { AdminContext, AdminContextType } from '../context/adminProvider';

type Store = MasterCompanyModels.CompanyMasterState & typeof MasterCompanyStore.actionCreators | AppStateModels.AppState & typeof AppStateStore.actionCreators | CompanyShortNameModels.CompanyShortNameState & typeof CompanyShortNameStore.actionCreators | CheckCompanyUserModels.EmployeeState & typeof CheckCompanyUserStore.actionCreators

let imgFile = require('../Images/DMS Logo.png');

interface States {
    checkTypeUser: any,
    dropdownOpen: boolean,
    isOpen: boolean,
    version: string,
    isHideAlert: boolean,
    name: string,
    userId: string,
    pathName: string,
    urlShowMenu: any,
    checkDisableMenu: boolean,
    checkInitialpath: boolean,
    CompanValue: string,
    checkInitialSelectedCompany: boolean,
    searchVal: string,
    CheckCompanyShortName: boolean,
    checkCompanyShortNameList: boolean,
    userRoleAccess: string,
}

class NavMenu extends React.Component<any, States, Store> {
    static contextType: React.Context<any> | undefined = UserContext;

    constructor(props: any) {
        super(props);

        this.state = {
            isOpen: false,
            version: '',
            name: '',
            userId: '',
            isHideAlert: false,
            dropdownOpen: false,
            checkTypeUser: undefined,
            pathName: "",
            urlShowMenu: ['/dms/role-access', '/callback', '/access-management'],
            checkDisableMenu: true,
            checkInitialpath: true,
            CompanValue: "",
            checkInitialSelectedCompany: true,
            searchVal: "",
            CheckCompanyShortName: true,
            checkCompanyShortNameList: true,
            userRoleAccess: "",
        };

        this.toggleIsOpen = this.toggleIsOpen.bind(this);
        this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
    }

    public componentDidMount() {
        let ls: any = localStorage.getItem("DMS_APPLICATION");
        let userId = "";
        let checkUser = "";

        if (ls) {
            let jls: any = JSON.parse(ls)
            let accessToken = jls.access_token
            let jwt = parseJwt(accessToken);
            let extra = JSON.parse(jwt.extra);

            checkExp(jwt)

            if (extra.username) {
                userId = extra.username
                checkUser = extra.username
            } else if (extra.first_name) {
                userId = extra.first_name + " " + extra.last_name
                checkUser = extra.citizen_id
            } else if (extra.citizen_id) {
                userId = extra.citizen_id
                checkUser = extra.citizen_id
            }

            if (checkUser === "") {
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'ไม่พบ user ใน token',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ยืนยัน',
                }).then((result) => { getRedirectAppPoolUrl() })
            }

            this.setState({
                name: userId,
                userId: checkUser
            })
        }

        fetch("/v1/version")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        version: result.message
                    });
                },
                (error) => {
                }
            )

        fetch("/v1/admin?citizen_id=" + checkUser,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + ls
                }
            })
            .then(res => res)
            .then((result) => {
                if (result.ok) {
                    result.json().then((data: RoleAccessResponse) => {
                        //console.log("[Debug] data", data)

                        const { /*userRole,*/ setUserRole } = this.props.admin;
                        const filterAdminUser = data.result_list.filter(result => result.roleAccess === 'super_admin' || result.roleAccess === 'admin');

                        // Set state for use in other components
                        setUserRole(data.result_list);

                        if (filterAdminUser) {
                            this.setState({
                                checkTypeUser: result,
                                userRoleAccess: filterAdminUser.find(user => user.roleAccess === "super_admin")
                                    ? "super_admin"
                                    : "admin"
                            });
                        }
                    })
                } else {
                    result.json().then(data => {
                        if (data.message === "No such host is known." || data.message === "An invalid request URI was provided. The request URI must either be an absolute URI or BaseAddress must be set.") {
                            Swal.fire({
                                title: 'เกิดข้อผิดพลาด',
                                text: 'ไม่สามารถเชื่อมต่อกับ server ได้ในขณะนี้',
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'ยืนยัน',
                            }).then((result) => { window.location.reload() })
                        }
                    })
                }
            });

        // If user doesn't has role admin, that user can't see menu.
        if (checkUser !== "") {
            fetch("/v1/user?id=" + checkUser + "&mode=CheckUser", {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + ls
                }
            })
                .then(res => res.json())
                .then((result: CheckCompanyUserModels.ResponseEmployee) => {
                    let total_count = result.total_count

                    if (total_count === 0) {
                        Swal.fire({
                            title: 'เกิดข้อผิดพลาด',
                            text: 'ไม่พบ user นี้ในระบบ',
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'ยืนยัน',
                        }).then(() => { getRedirectAppPoolUrl() })
                    } else {
                        if (result) {
                            const user = result.result_list[0];
                            const { actions }: IUserContext = this.context;
                            actions.setId(user.id);
                            actions.setListCompany(user.list_company);
                        }
                    }
                });

            this.props.requestFunctionCheckCompanyUser(0, "GET", checkUser, "", "", checkUser);
        }

        let path = window.location.pathname
        console.log(path)
        if (path !== '' && this.state.checkInitialpath) {
            for (let i = 0; i < this.state.urlShowMenu.length; i++) {
                if (path === this.state.urlShowMenu[i]) {
                    this.setState({
                        checkDisableMenu: true
                    })
                    break;
                } else {
                    this.setState({
                        checkDisableMenu: false
                    })
                }
            }
            this.setState({
                pathName: path,
                checkInitialpath: false
            })
        }
        this.props.requestFunctionCompanyMaster(true, "GET", "", "active", "");
        //this.props.requestFunctionCompanyShortName(true, "GET", "", "active", userId)
        //this.props.requestFunctionCompanyShortName(true, "GET", "", "active", "wf002")
    }

    public toggledropdown = () => {
        const { dropdownOpen } = this.state;
        this.setState({
            dropdownOpen: !dropdownOpen
        })
    }

    public componentDidUpdate() {
        const { isLoadingCompanyMaster, responseCompanyMaster, statusCompanyMaster, isLoadCompanyShortName, statusCompanyShortName, responseCompanyShortName, isLoadingEmployee, statusEmployee, responseEmployee } = this.props
        let path = window.location.pathname
        if (path !== this.state.pathName) {
            console.log(path)
            if (path !== '') {
                this.setState({
                    pathName: path,
                })
                for (let i = 0; i < this.state.urlShowMenu.length; i++) {
                    if (path === this.state.urlShowMenu[i]) {
                        this.setState({
                            checkDisableMenu: true
                        })
                        break;
                    } else {
                        this.setState({
                            checkDisableMenu: false
                        })
                    }
                }
            }
        }

        if (isLoadingCompanyMaster && statusCompanyMaster === 200 && responseCompanyMaster.total_count !== 0 && this.state.checkInitialSelectedCompany) {
            this.setState({ CompanValue: responseCompanyMaster.result_list[0].sid });
            this.props.updateCompany(responseCompanyMaster.result_list[0].sid);
            this.setState({
                checkInitialSelectedCompany: false
            })
        }

        if (isLoadCompanyShortName && statusCompanyShortName === 200 && responseCompanyShortName.total_count !== 0 && this.state.CheckCompanyShortName) {
            let CheckCompanyShortName_: Array<string> = []
            for (let i = 0; i < responseCompanyShortName.total_count; i++) {
                CheckCompanyShortName_.push(responseCompanyShortName.result_list[i].company_short_name)
            }
            this.props.updateCompanyShortName(CheckCompanyShortName_);
            this.setState({
                CheckCompanyShortName: false
            })
        }

        if (isLoadingCompanyMaster && statusCompanyMaster === 200 && responseCompanyMaster.total_count !== 0 && this.state.checkCompanyShortNameList && isLoadingEmployee && statusEmployee === 200 && responseEmployee !== undefined) {
            console.log("into get company shortname")
            let listCompany = [...responseEmployee.result_list]
            let listCompanyUser = listCompany.find(item => item.id.toLowerCase() === this.state.userId.toLowerCase())
            console.log("listCompanyUser :", listCompanyUser)
            //listCompanyUser.list_company
            let arrCompany: any = []
            for (let i = 0; i < responseCompanyMaster.result_list.length; i++) {
                if (listCompanyUser != undefined) {
                    for (let j = 0; j < listCompanyUser.list_company.length; j++) {
                        if (responseCompanyMaster.result_list[i].sid === listCompanyUser.list_company[j].sid) {
                            arrCompany.push(responseCompanyMaster.result_list[i])
                        }
                    }
                }
            }
            // console.log('ngame availableCompanies ->', arrCompany)
            let CheckCompanyShortName_: Array<string> = []
            for (let i = 0; i < arrCompany.length; i++) {
                CheckCompanyShortName_.push(arrCompany[i].company_short_name)
            }

            const { actions }: IUserContext = this.context;
            actions.setAvailableCompanies(arrCompany);
            this.props.updateCompanyShortName(CheckCompanyShortName_);
            this.props.updateAvailableCompany(arrCompany);


            this.setState({
                checkCompanyShortNameList: false
            })
        }
    }

    public handleChangeCompany = (event) => {
        //this.setState({ value: event.target.value });
        console.log(event.target.value);
        this.setState({ CompanValue: event.target.value });
        this.props.updateCompany(event.target.value);
    };

    public handleChangeSearch = (e: any) => {
        //this.setState({ value: event.target.value });
        console.log(e.target.value);
        this.setState({ searchVal: e.target.value });
        //this.props.updateCompany(event.target.value);
    };

    public handleSubmitSearch(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const searchVal = this.state.searchVal;

        if (searchVal !== '') {
            this.props.history.push(`/dms/search-file?search=${convertSearchKey(this.state.searchVal)}`);
        }
    }

    public toggleIsOpen() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    public render() {
        const { isLoadingCompanyMaster, responseCompanyMaster, statusCompanyMaster } = this.props;

        return (
            <header>
                <Navbar
                    expand="lg"
                    light
                    className="bg-navbar d-flex align-items-center mb-3"
                >
                    <div className="d-flex align-items-center">
                        <div className="trapezoid-logo d-inline-block">
                            <Link to={'/'} className="d-inline-flex mt-1" style={{ width: 'auto', height: "40px" }}>
                                <img src={imgFile} alt="DMS" />
                            </Link>
                        </div>
                        <div className="d-none d-sm-block" style={{ fontFamily: 'sans-serif' }}>
                            <span>DMS Web</span>
                            <span style={{ fontSize: '8pt', paddingLeft: '5px' }}>V {this.state.version}</span>
                        </div>
                    </div>
                    <NavbarToggler onClick={this.toggleIsOpen} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="d-flex align-items-end align-items-lg-center w-100 justify-content-end" navbar>
                            <NavItem className="w-lg-auto px-5 pt-2 pt-lg-0 mr-auto">
                                <Form onSubmit={this.handleSubmitSearch}>
                                    <InputGroup size="sm">
                                        <Input
                                            type="text"
                                            placeholder="Search..."
                                            onChange={this.handleChangeSearch}
                                        />
                                        <Button
                                            type="submit"
                                            size="sm"
                                            color="primary"
                                            onClick={this.handleSubmitSearch}
                                        >
                                            <BsSearch />
                                        </Button>
                                    </InputGroup>
                                </Form>
                            </NavItem>
                            <NavItem>
                                {
                                    this.state.checkTypeUser
                                    && this.state.checkDisableMenu
                                    && (
                                        <Input type="select" name="select" className="mx-4 form-control-sm mt-1" onChange={this.handleChangeCompany} defaultValue={this.state.CompanValue} /*disabled={this.state.checkReadOnlyMenu}*/>
                                            {isLoadingCompanyMaster && statusCompanyMaster === 200 && responseCompanyMaster.total_count !== 0 && responseCompanyMaster.result_list.map((data: MasterCompanyModels.CompanyMaster, index: number) => {
                                                return (
                                                    <option value={data.sid}>{data.company_name}</option>
                                                )
                                            })}
                                        </Input>
                                    )
                                }
                            </NavItem>
                            <NavItem>
                                <NavLink className="text-dark font-navbar">{this.state.name}</NavLink>
                            </NavItem>
                            <div>
                                {
                                    this.state.checkTypeUser
                                    && (
                                        <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggledropdown}>
                                            <DropdownToggle nav caret className="text-dark font-navbar" style={{ textAlign: 'end' }}>
                                                Menu
                                            </DropdownToggle>
                                            <DropdownMenu style={{ left: "-50px" }}>
                                                {this.state.userRoleAccess === "admin" &&
                                                    <div>
                                                        <DropdownItem><Link to="/user-folder-authorizev2">Folder Authorize Mapping</Link></DropdownItem>
                                                        <DropdownItem><Link to="/user-folder-authorizev2/log">Folder Authorize Log</Link></DropdownItem>
                                                    </div>
                                                }
                                                {this.state.userRoleAccess === "super_admin" &&
                                                    <div>
                                                        <DropdownItem><Link to="/dms/meta-data-group">Metadata Group</Link></DropdownItem>
                                                        <DropdownItem><Link to="/dms/meta-data">Metadata</Link></DropdownItem>
                                                        {/*<DropdownItem><Link to="/dms/role-access">Authorization</Link></DropdownItem>*/}
                                                        <DropdownItem><Link to="/employee">Employee</Link></DropdownItem>
                                                        <DropdownItem><Link to="/folder-authorize">Folder Authorize</Link></DropdownItem>
                                                        <DropdownItem><Link to="/user-folder-authorizev2">Folder Authorize Mapping</Link></DropdownItem>
                                                        <DropdownItem><Link to="/user-folder-authorizev2/log">Folder Authorize Log</Link></DropdownItem>
                                                        <DropdownItem><Link to="/callback">Callback</Link></DropdownItem>
                                                        <DropdownItem><Link to="/access-management">Access Management</Link></DropdownItem>
                                                        <DropdownItem><Link to="/dms/log">DMS Log</Link></DropdownItem>
                                                        <DropdownItem><Link to="/dms/auth-history">Authentication History</Link></DropdownItem>
                                                    </div>
                                                }
                                            </DropdownMenu>
                                        </Dropdown>
                                    )
                                }
                            </div>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark font-navbar text-nowrap" to="/auth/signout">Back to Portal</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </header>
        );

        return (
            <header>
                <Navbar className="bg-navbar navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <div className="navbar-header">
                        <div className="navbar-brand" style={{ padding: '0', width: '380px' }}>
                            <div className="trapezoid-logo d-none d-md-inline-block" style={{ color: '#0D0D0D' }}>
                                <a href={'/'}>
                                    < img src={imgFile} style={{ marginTop: '4px', width: 'auto', height: "40px" }} alt="file" />
                                </a>
                                {/*< img src={'https://slsn-o2c-uat.thailanderp.com/images/f1-new-logo.png'} width="96px" height="40" style={{marginTop:'4px'}} />*/}
                            </div>
                            <div className="nav-brand-name" style={{ fontFamily: 'sans-serif' }}>
                                DMS Web
                                <span style={{ fontSize: '8pt', paddingLeft: '5px' }}>V {this.state.version}</span>
                            </div>
                        </div>
                    </div>
                    <Row>
                        <Col sm={4}>
                            <Input type="text" name="txtSearch" className="mx-4 form-control-sm mt-1" placeholder='Search...' onChange={this.handleChangeSearch} />
                        </Col>
                        <Col sm={3}>
                            <NavLink tag={Link} className="px-0 mx-0 text-dark font-navbar" to={"/dms/search-file?search=" + this.state.searchVal}>
                                <i className="fa fa-search" aria-hidden="true"></i> Search
                            </NavLink>
                        </Col>
                        <Col sm={5}>
                            {/*<NavLink tag={Link} className="px-0 mx-0 text-dark font-navbar" to={"/dms/search-with-criteria"}>*/}
                            {/*    <i className="fa fa-search" aria-hidden="true"></i> Search with Criteria*/}
                            {/*</NavLink>*/}
                        </Col>
                    </Row>
                    {/*<Container fluid>
                        <NavbarBrand tag={Link} to="/">DMS Web<span style={{ fontSize: '8pt', paddingLeft: '5px' }}>V {this.state.version}</span></NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2" />*/}
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse font-weight-bold" isOpen={this.state.isOpen} navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>

                            </NavItem>

                            {this.state.checkTypeUser && this.state.checkDisableMenu &&
                                <Row>
                                    <Col sm={10}>
                                        <Input type="select" name="select" className="mx-4 form-control-sm mt-1" onChange={this.handleChangeCompany} defaultValue={this.state.CompanValue} /*disabled={this.state.checkReadOnlyMenu}*/>
                                            {isLoadingCompanyMaster && statusCompanyMaster === 200 && responseCompanyMaster.total_count !== 0 && responseCompanyMaster.result_list.map((data: MasterCompanyModels.CompanyMaster, index) => {
                                                return (
                                                    <option value={data.sid}>{data.company_name}</option>
                                                )
                                            })}
                                        </Input>
                                    </Col>
                                </Row>
                            }

                            <NavItem>
                                <NavLink className="text-dark font-navbar" >{this.state.name}</NavLink>
                            </NavItem>

                            {this.state.checkTypeUser &&
                                <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggledropdown}>
                                    <DropdownToggle nav caret className="text-dark font-navbar">
                                        Menu
                                    </DropdownToggle>
                                    <DropdownMenu style={{ left: "-50px" }}>
                                        <DropdownItem><Link to="/dms/meta-data-group">Metadata Group</Link></DropdownItem>
                                        <DropdownItem><Link to="/dms/meta-data">Metadata</Link></DropdownItem>
                                        {/*<DropdownItem><Link to="/dms/role-access">Authorization</Link></DropdownItem>*/}
                                        <DropdownItem><Link to="/employee">Employee</Link></DropdownItem>
                                        <DropdownItem><Link to="/callback">Callback</Link></DropdownItem>
                                        <DropdownItem><Link to="/access-management">Access Management</Link></DropdownItem>
                                        <DropdownItem><Link to="/dms/log">DMS Log</Link></DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            }

                            <NavItem>
                                <NavLink tag={Link} className="text-dark font-navbar" to="/auth/signout">Back to Portal</NavLink>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}
export default withRouter(
    connect(
        (state: ApplicationState) => ({ ...state.companyMaster, ...state.appState, ...state.CompanyShortName, ...state.CheckCompanyUser }),
        ({ ...MasterCompanyStore.actionCreators, ...AppStateStore.actionCreators, ...CompanyShortNameStore.actionCreators, ...CheckCompanyUserStore.actionCreators })
    )(NavMenu)
)