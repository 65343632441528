import React from 'react';
import { RouteProps } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import * as QueryString from 'query-string'
import { connect } from 'react-redux';
import { formatYMDToDMY } from '../../../modules/common'

import { ApplicationState } from '../../../store';
import { ActionCreators } from '../../../store/FileLog';
import * as FileLogStore from '../../../store/FileLog';

import { FileLogStoreState } from '../../../models/FileLog';
import { Message } from '@material-ui/icons';


interface LogComponentProps { }

interface State { }

type Props =
    & LogComponent
    & RouteProps
    & ActionCreators
    & FileLogStoreState;
class LogComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.handleAction = this.handleAction.bind(this)
        this.handleMessage = this.handleMessage.bind(this)
        this.replaceAll = this.replaceAll.bind(this)
    }

    public componentDidMount(): void {
        const { location } = this.props;

        if (location !== undefined) {
            const { p_id } = QueryString.parse(location.search);

            if (p_id) {
                this.props.requestFileLog({
                    method: 'GET',
                    params: { p_id: p_id as string }
                });
            }
        }
    }

    handleAction(action: string) {
        if (!action) {
            return '';
        }

        switch (action) {
            case 'view':
                return 'ดูไฟล์';
            case 'create':
                return 'อัพโหลดไฟล์';
            case 'edit':
                return 'แก้ไขข้อมูล';
            case 'copy':
                return 'คัดลอกไฟล์';
            case 'delete':
                return 'ลบไฟล์';
            default:
                return action;
        }
    }

    handleMessage(key: string, message: string) {
        if (!message) {
            return '';
        }

        var reMessage = '';
        let obj = JSON.parse(message);
        if (key === 'copy') {
            reMessage += ' ไฟล์ ' + obj.file_name;

            if (obj.full_path) {
                reMessage += ' เข้า Folder ' + obj.full_path;
            }
            else if (obj.bucket) {
                reMessage += ' เข้า Bucket ' + obj.bucket;
            }

            if (obj.group_id) {
                reMessage += ' ด้วย Group Id ' + obj.group_id;
            }

            if (obj.sub_group_id) {
                reMessage += ' ด้วย Sub Group Id ' + obj.sub_group_id;
            }

            //if (obj.index_string) {
            //    reMessage += ' ด้วย Searck Key ' + this.replaceAll(obj.index_string, '|', ', ');
            //}

            //if (obj.metadata) {
            //    reMessage += ' ด้วย Metadata ' + this.replaceAll(obj.metadata, '|', ', ');
            //}
        }
        else if (key === 'create') {
            reMessage += ' ไฟล์ ' + obj.file_name;

            if (obj.full_path) {
                reMessage += ' เข้า Folder ' + obj.full_path;
            }
            else if (obj.bucket) {
                reMessage += ' เข้า Bucket ' + obj.bucket;
            }

            if (obj.group_id) {
                reMessage += ' ด้วย Group Id ' + obj.group_id;
            }

            if (obj.sub_group_id) {
                reMessage += ' ด้วย Sub Group Id ' + obj.sub_group_id;
            }

            //if (obj.index_string) {
            //    reMessage += ' ด้วย Searck Key ' + this.replaceAll(obj.index_string, '|', ', ');
            //}

            //if (obj.metadata) {
            //    reMessage += ' ด้วย Metadata ' + this.replaceAll(obj.metadata, '|', ', ');
            //}
        }
        else if (key === 'edit') {
            if (obj.full_path) {
                reMessage += ' เปลี่ยน Folder เป็น ' + obj.full_path;
            } else if (obj.bucket) {
                reMessage += ' เปลี่ยน Bucket เป็น ' + obj.bucket;
            }

            if (obj.box_id) {
                reMessage += ' เปลียน Box Id เป็น ' + obj.box_id;
            }

            if (obj.group_id) {
                reMessage += ' เปลียน Group Id เป็น ' + obj.group_id;
            }

            if (obj.sub_group_id) {
                reMessage += ' เปลียน Sub Group Id เป็น ' + obj.sub_group_id;
            }

            if (obj.index_string) {
                reMessage += ' เปลียน Searck Key';
            }

            if (obj.metadata) {
                reMessage += ' เปลียน Metadata';
            }
        }

        return reMessage;
    }

    replaceAll(target, search, replace) {
        return target.split(search).join(replace)
    }

    public render() {
        return (

            <div className="form-group div-main-style" style={{ backgroundColor: '#ffff' }}>
                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="home" title="Log">
                        <table className='table custom-table'>
                            <thead>
                                <tr>
                                    <th>Action By</th>
                                    {/*<th>Primary Index</th>*/}
                                    {/*<th>DMS ID</th>*/}
                                    <th>Log Action</th>
                                    {/*<th>Request Message</th>*/}
                                    <th>Message</th>

                                    <th>Action On</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    // Need to fix when no data
                                    (this.props.status === 200 && this.props.response !== null)
                                        ? (
                                            this.props.response.result_list !== null
                                            && this.props.response.result_list.map((log, index) => (
                                                (((log.log_action === 'create' || log.log_action === 'copy') && log.message) ?
                                                    (
                                                        <tr key={`log-${log.id}-${index}`}>
                                                            <td>{log.created_by}</td>
                                                            {/*<td>{log.dms_primary_index}</td>*/}
                                                            {/*<td>{log.dms_id}</td>*/}
                                                            <td>{this.handleAction(log.log_action)}</td>
                                                            {/*<td>{log.request_message}</td>*/}
                                                            <td>{this.handleMessage(log.log_action, (log.log_action === 'create' || log.log_action === 'copy') ? log.message : log.request_message)}</td>
                                                            <td>{formatYMDToDMY(log.created_on)}</td>
                                                        </tr>
                                                    ) : (log.log_action === 'view' && 
                                                        <tr key={`log-${log.id}-${index}`}>
                                                            <td>{log.created_by}</td>
                                                            {/*<td>{log.dms_primary_index}</td>*/}
                                                            {/*<td>{log.dms_id}</td>*/}
                                                            <td>{this.handleAction(log.log_action)}</td>
                                                            {/*<td>{log.request_message}</td>*/}
                                                            <td>{this.handleMessage(log.log_action, log.request_message)}</td>
                                                            <td>{formatYMDToDMY(log.created_on)}</td>
                                                        </tr>
                                                    ))
                                            ))
                                        )
                                        : (
                                            <tr>
                                                <td colSpan={7}>No log</td>
                                            </tr>
                                        )
                                }
                            </tbody>
                        </table>
                    </Tab>
                    <Tab eventKey="raw" title="Raw Log">
                        <table className='table custom-table'>
                            <thead>
                                <tr>
                                    <th>Primary Index</th>
                                    <th>DMS ID</th>
                                    <th>Log Action</th>
                                    <th>Request Message</th>
                                    <th>Message</th>
                                    <th>Created By</th>
                                    <th>Created On</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    // Need to fix when no data
                                    (this.props.status === 200 && this.props.response !== null)
                                        ? (
                                            this.props.response.result_list !== null
                                            && this.props.response.result_list.map((log, index) => (
                                                <tr key={`log-${log.id}-${index}`}>
                                                    <td>{log.dms_primary_index}</td>
                                                    <td>{log.dms_id}</td>
                                                    <td>{log.log_action}</td>
                                                    <td>{log.request_message}</td>
                                                    <td>{log.message}</td>
                                                    <td>{log.created_by}</td>
                                                    <td>{formatYMDToDMY(log.created_on)}</td>
                                                </tr>
                                            ))
                                        )
                                        : (
                                            <tr>
                                                <td colSpan={7}>No log</td>
                                            </tr>
                                        )
                                }
                            </tbody>
                        </table>
                    </Tab>
                </Tabs>
                {/*<div className='trapezoid' style={{ width: 'auto', display: 'inline-block', marginLeft: '0px' }}>*/}
                {/*    <span style={{ fontFamily: "sans-serif" }}>*/}
                {/*        Log*/}
                {/*    </span>*/}
                {/*</div>*/}


            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.fileLog,
    FileLogStore.actionCreators
)(LogComponent as any);