import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as AccessManagement from '../models/AccessManagement';

interface ReqGenerateToken {
    type: 'REQ_GENERATETOKEN';
}
interface GenerateTokenSuccess {
    type: 'GENERATETOKEN_SUCCESS';
    response: string;
    status: number
}
interface GenerateTokenFail {
    type: 'GENERATETOKEN_FAIL';
    status?: number
    message: string
}
interface GenerateTokenClear {
    type: 'GENERATETOKEN_CLEAR';
    status: number
}

type KnownAction = ReqGenerateToken | GenerateTokenSuccess | GenerateTokenFail | GenerateTokenClear
export const actionCreators = {
    requestGenerateToken: (check: boolean, method: string, sid: string, legacy_id: string, address: string, bucket_id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (check && appState && appState.generateToken && method === "GET") {
            var body = {
                sid: sid,
                legacy_id: legacy_id,
                address: address,
                bucket_id: bucket_id
            }
            fetch(`/v1/token`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    //console.log(data)
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err)
                            dispatch({ type: 'GENERATETOKEN_FAIL', status: 204, message: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            //console.log(data)
                            dispatch({ type: 'GENERATETOKEN_SUCCESS', response: data, status: 200 });
                        })
                    }
                })
            dispatch({ type: 'REQ_GENERATETOKEN' });
        } else if (method === "CLEAR") {
            dispatch({ type: 'GENERATETOKEN_CLEAR', status: 0 });
        }
    }
};

const unloadedState: AccessManagement.GenerateTokenState = { isLoadingGenerate: false, responseGenerate: "" };
export const reducer: Reducer<AccessManagement.GenerateTokenState> = (state: AccessManagement.GenerateTokenState | undefined, incomingAction: Action): AccessManagement.GenerateTokenState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQ_GENERATETOKEN':
            return {
                isLoadingGenerate: false,
                responseGenerate: ''
            };
        case 'GENERATETOKEN_SUCCESS':
            return {
                isLoadingGenerate: true,
                responseGenerate: action.response,
                statusGenerate: action.status
            };
        case 'GENERATETOKEN_FAIL':
            return {
                isLoadingGenerate: true,
                responseGenerate: '',
                statusGenerate: action.status,
                messageGenerate: action.message
            };
        case 'GENERATETOKEN_CLEAR':
            return {
                isLoadingGenerate: false,
                responseGenerate: '',
                statusGenerate: action.status
            };

        default: return state;
    }
};