import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as Models from '../models/CompanyShortName';

interface RequestFunctionAction {
    type: 'REQUEST_COMPANYSHORTNAME';
    status: number;
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_COMPANYSHORTNAME';
    model: Models.ResponseCompanyShortName;
    status: number;
}

interface ClearFunctionAction {
    type: 'CLEAR_COMPANYSHORTNAME';
    status: number;
}

interface WhaitFunctionAction {
    type: 'WHAIT_COMPANYSHORTNAME';
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction | ClearFunctionAction | WhaitFunctionAction

export const actionCreators = {
    requestFunctionCompanyShortName: (check: boolean, method: string, sid: string, status: string, citizen_id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.CompanyShortName && method === "GET" && check) {
            console.log('short name fetch')
            console.log('/v1/company_master/user?sid=' + sid + `&status=` + status + '&citizen_id=' + citizen_id)
            fetch(`/v1/company_master/user?sid=` + sid + `&status=` + status + '&citizen_id=' + citizen_id, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION')
                }
            })
                .then(response => {
                    return response.json() as Promise<Models.ResponseCompanyShortName>
                })
                .then(data => {
                    console.log(data);
                    dispatch({ type: 'RECEIVE_COMPANYSHORTNAME', model: data, status: 200 });
                })
                .catch(error => {
                    dispatch({ type: 'REQUEST_COMPANYSHORTNAME', status: 204 });
                });
        }

        dispatch({ type: 'WHAIT_COMPANYSHORTNAME' });

        if (method === "CLEAR") {
            dispatch({ type: 'CLEAR_COMPANYSHORTNAME', status: 400 });
        }

    }
};

const unloadedState: Models.CompanyShortNameState = { isLoadCompanyShortName: false, responseCompanyShortName: undefined, statusCompanyShortName: 0 };

export const reducer: Reducer<Models.CompanyShortNameState> = (state: Models.CompanyShortNameState | undefined, incomingAction: Action): Models.CompanyShortNameState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'WHAIT_COMPANYSHORTNAME':
            return {
                isLoadCompanyShortName: false,
                statusCompanyShortName: 400,
            };
        case 'REQUEST_COMPANYSHORTNAME':
            return {
                isLoadCompanyShortName: false,
                statusCompanyShortName: action.status,
            };
        case 'RECEIVE_COMPANYSHORTNAME':
            return {
                isLoadCompanyShortName: true,
                responseCompanyShortName: action.model,
                statusCompanyShortName: action.status
            };
        case 'CLEAR_COMPANYSHORTNAME':
            return {
                isLoadCompanyShortName: false,
                statusCompanyShortName: action.status
            };

        default: return state;
    }
};