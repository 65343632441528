import React, { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

import InfinitePagination from '../common/InfinitePagination';
import AuthenticationHistorySearchBar from './AuthenticationHistorySearchBar';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { Badge } from 'react-bootstrap';

type ListResponse<T> = {
    totalCount: number;
    resultList: T[];
};

interface AuthenticationHistory {
    citizenId: string;
    actionId: string;
    action: 'login';
    actionResponse: 'success' | 'fail';
    actionMessage?: string;
    timestamp: string;
    ipAddress: string;
    clientIPAddress: string;
    keyUUID: string;
    mode: string;
}

function AuthenticationHistoryLogs() {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [searchByCitizenId, setSearchByCitizenId] = useState<string>();

    const { error, data, isPlaceholderData } = useQuery({
        queryKey: ['auth-history', { page, pageSize, searchByCitizenId }],
        queryFn: () =>
            axios.get<ListResponse<AuthenticationHistory>>('/v1/auth-history', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        'DMS_APPLICATION'
                    )}`,
                },
                params: {
                    page,
                    page_size: pageSize,
                    citizen_id: searchByCitizenId,
                },
            }),
        select: (response) => response.data.resultList,
        keepPreviousData: true,
    });

    // Error handling
    useEffect(() => {
        if (!error) return;

        if (error instanceof AxiosError) {
            Swal.fire('Error', error.response?.data.message || 'ไม่ทราบสาเหตุ');
        } else if (error instanceof Error) {
            Swal.fire('Error', error.message);
        }
    }, [error]);

    const handleSubmitSearch = (citizenId: string) =>
        setSearchByCitizenId(citizenId);

    const paginationJSX = (
        <InfinitePagination
            disabledNext={!data || data.length === 0}
            page={page}
            style={{
                padding: '0px',
                marginTop: '16px',
                justifyContent: 'flex-end',
            }}
            onChangePage={(_event, page) => setPage(page)}
        />
    );

    return (
        <div>
            <AuthenticationHistorySearchBar
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                onSubmitSearch={handleSubmitSearch}
            />

            {paginationJSX}

            <table className="table table-bordered mt-3">
                <thead className="thead-light">
                    <tr>
                        <th>Mode</th>
                        <th>User</th>
                        <th>Action</th>
                        <th>Action Result</th>
                        <th>Action Message</th>
                        <th>Timestamp</th>
                        <th>IP Address</th>
                        <th>Client IP Address</th>
                        <th>Key UUID</th>
                    </tr>
                </thead>
                <tbody>
                    {!data || data?.length === 0 ? (
                        <tr className="text-center">
                            <td colSpan={9}>No data</td>
                        </tr>
                    ) : (
                        data.map((log) => {
                            const formattedDate = dayjs(log.timestamp).format(
                                'DD/MM/YYYY HH:mm:ss น.'
                            );

                            return (
                                <tr key={log.actionId}>
                                    <td>
                                        <Badge
                                            variant={
                                                log.mode === 'gui'
                                                    ? 'primary'
                                                    : 'info'
                                            }
                                        >
                                            <span className="relative top-[1px]">
                                                {log.mode.toUpperCase()}
                                            </span>
                                        </Badge>
                                    </td>
                                    <td>{log.citizenId}</td>
                                    <td>{log.action}</td>
                                    <td>
                                        <Badge
                                            variant={
                                                log.actionResponse === 'success'
                                                    ? 'success'
                                                    : 'danger'
                                            }
                                        >
                                            {log.actionResponse}
                                        </Badge>
                                    </td>
                                    <td>{log.actionMessage}</td>
                                    <td>{formattedDate}</td>
                                    <td>{log.ipAddress}</td>
                                    <td>{log.clientIPAddress}</td>
                                    <td>{log.keyUUID}</td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>

            {paginationJSX}
        </div>
    );
}

export default AuthenticationHistoryLogs;
