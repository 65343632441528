import * as React from 'react';
//import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Container, Row, Col, Button, Modal, InputGroup, Form, FormControl, Card, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
//import Swal from 'sweetalert2'
//import PullJobConfigTable from './PullJobConfigTable'
import * as GetLogModel from '../../models/Logger'
import { FaSearch, FaCheckCircle, FaTimesCircle, FaPlayCircle, FaFolderOpen, FaAngleDown, FaPlusCircle, FaFileExport } from "react-icons/fa";
//import Tab from 'react-bootstrap/Tab';
//import Tabs from 'react-bootstrap/Tabs';

import LogTable from './LogTable'
//import LogTableRawView from './LogTableRawView'

class Logger extends React.Component<any, { key: string, SearchLoggerReq: GetLogModel.SearchLoggerReq }>{

    constructor(props: any) {
        super(props);
        this.state = {
            key: "",
            SearchLoggerReq: {
                function_name: '',
                action: '',
                log_type: '',
                legacy_id: '',
                created_by: '',
                created_on: '',
            },
        }
        this.callbackReset = this.callbackReset.bind(this)
    }

    handleChangeSearchInput = (e: any, key: string) => {
        if (key !== "") {
            var data = this.state.SearchLoggerReq
            data[key] = e.target.value
            this.setState({
                SearchLoggerReq: data
            })
        }
    }

    public callbackReset() {
        this.setState({
            key: ''
        })
    }

    Reset = () => {
        console.log('Reset function')
        this.setState({
            key: 'Reset'
        })
    }

    handleClearInput = (key: string) => {
        console.log(key)
        if (key !== "") {
            var data = JSON.parse(JSON.stringify(this.state.SearchLoggerReq))
            data[key] = ""
            this.setState({
                SearchLoggerReq: data
            })
        }
    }

    render() {
        return (
            <div className="div-meta-data-main div-meta-role">
                <div>
                    <div className='trapezoid' style={{ width: 'auto', display: 'inline-block' }}>
                        <span className="metadata-font">
                            Metadata
                        </span>
                    </div>

                </div>
                <div className="mat-box">
                    <Row>
                        <Col>
                            {/*<Accordion style={{ width: '100%' }} className='h-50 d-inline-block'>*/}
                            <Card>
                                <Card.Header className='h-50 d-inline-block' style={{ marginBottom: '-12px', marginTop: '-12px' }}>
                                    <Row>
                                        <div className='col-lg-6'>
                                            <div className='d-flex justify-content-start my-2 my-lg-0'>
                                                {/*<Accordion.Toggle as={Button} variant="light" eventKey="0">*/}
                                                <Form.Label column sm="3" className="mt-2">ค้นหาข้อมูล</Form.Label>
                                                {/*</Accordion.Toggle>*/}
                                            </div>
                                        </div>
                                        {/*<div className='col-lg-6'>
                                                <div className='d-flex justify-content-end my-2 my-lg-0'>
                                                    <Accordion.Toggle as={Button} variant="light" eventKey="0">
                                                        <FaAngleDown />
                                                    </Accordion.Toggle>
                                                </div>
                                            </div>*/}
                                    </Row>
                                </Card.Header>
                                {/*<Accordion.Collapse eventKey="0">*/}
                                <Card.Body style={{ overflow: 'none' }}>
                                    <Row className="mt-2">
                                        <Col lg="6">
                                            <Form className="mb-3" as={Row} >
                                                <Form.Label column sm="3" className="text-center">Function Name :</Form.Label>
                                                <Col sm="9">
                                                    <InputGroup>
                                                        <Form.Control type="text" size='sm' value={this.state.SearchLoggerReq.function_name} onChange={(e) => this.handleChangeSearchInput(e, 'function_name')} />
                                                        <Button variant="outline-secondary" size="sm" onClick={() => this.handleClearInput('function_name')}><FaTimesCircle /></Button>
                                                    </InputGroup>
                                                </Col>
                                            </Form >
                                        </Col>
                                        <Col lg="6">
                                            <Form className="mb-3" as={Row} >
                                                <Form.Label column sm="3" className="text-center">Legacy ID :</Form.Label>
                                                <Col sm="9">
                                                    <InputGroup>
                                                        <Form.Control type="text" size='sm' value={this.state.SearchLoggerReq.legacy_id} onChange={(e) => this.handleChangeSearchInput(e, 'legacy_id')} />
                                                        <Button variant="outline-secondary" size="sm" onClick={() => this.handleClearInput('legacy_id')}><FaTimesCircle /></Button>
                                                    </InputGroup>
                                                </Col>
                                            </Form >
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <Form className="mb-3" as={Row} >
                                                <Form.Label column sm="3" className="text-center">Action :</Form.Label>
                                                <Col sm="9">
                                                    <InputGroup>
                                                        <Form.Control type="text" size='sm' value={this.state.SearchLoggerReq.action} onChange={(e) => this.handleChangeSearchInput(e, 'action')} />
                                                        <Button variant="outline-secondary" size="sm" onClick={() => this.handleClearInput('action')}><FaTimesCircle /></Button>
                                                    </InputGroup>
                                                </Col>
                                            </Form >
                                        </Col>
                                        <Col lg="6">
                                            <Form className="mb-3" as={Row} >
                                                <Form.Label column sm="3" className="text-center">Log Type :</Form.Label>
                                                <Col sm="9">
                                                    <InputGroup>
                                                        <Form.Control type="text" size='sm' value={this.state.SearchLoggerReq.log_type} onChange={(e) => this.handleChangeSearchInput(e, 'log_type')} />
                                                        <Button variant="outline-secondary" size="sm" onClick={() => this.handleClearInput('log_type')}><FaTimesCircle /></Button>
                                                    </InputGroup>
                                                </Col>
                                            </Form >
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <Form className="mb-3" as={Row} >
                                                <Form.Label column sm="3" className="text-center">Create By :</Form.Label>
                                                <Col sm="9">
                                                    <InputGroup>
                                                        <Form.Control type="text" size='sm' value={this.state.SearchLoggerReq.created_by} onChange={(e) => this.handleChangeSearchInput(e, 'created_by')} />
                                                        <Button variant="outline-secondary" size="sm" onClick={() => this.handleClearInput('created_by')}><FaTimesCircle /></Button>
                                                    </InputGroup>
                                                </Col>
                                            </Form >
                                        </Col>
                                        <Col lg="6">
                                            <Form className="mb-3" as={Row} >
                                                <Form.Label column sm="3" className="text-center">Time Stamp :</Form.Label>
                                                <Col sm="9">
                                                    <InputGroup>
                                                        <Form.Control type="date" size='sm' value={this.state.SearchLoggerReq.created_on} onChange={(e) => this.handleChangeSearchInput(e, 'created_on')} />
                                                        <Button variant="outline-secondary" size="sm" onClick={() => this.handleClearInput('created_on')}><FaTimesCircle /></Button>
                                                    </InputGroup>
                                                </Col>
                                            </Form >
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button style={{ marginBottom: '10px', marginRight: '10px', float: 'right' }} variant="outline-success" size="sm" onClick={this.Reset} >
                                                <FaSearch />
                                                &nbsp;ค้นหา
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                {/*</Accordion.Collapse>*/}
                            </Card>
                            {/*</Accordion>*/}
                        </Col>
                    </Row>
                    {/*<Tabs*/}
                    {/*    defaultActiveKey="log"*/}
                    {/*    id="uncontrolled-tab-example"*/}
                    {/*    className="mb-3"*/}
                    {/*>*/}
                    {/*    <Tab eventKey="log" title="Logz">*/}
                            <LogTable checkkey={this.state.key} callbackkey={this.callbackReset.bind(this)} searchLoggerReq={this.state.SearchLoggerReq} />
                    {/*    </Tab>*/}
                    {/*    <Tab eventKey="raw_log" title="RawLog">*/}
                    {/*        <LogTableRawView checkkey={this.state.key} callbackkey={this.callbackReset.bind(this)} searchLoggerReq={this.state.SearchLoggerReq} />*/}
                    {/*    </Tab>*/}
                    {/*</Tabs>*/}

                </div>
            </div>
        )
    }

}
export default Logger