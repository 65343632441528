import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as RoleAccess from '../models/RoleAccess';

interface RequestFunctionAction {
    type: 'REQUEST_ROLEACCESS';
    version: number;
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_ROLEACCESS';
    model: RoleAccess.ResponseRoleAccess;
    version: number;
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction

export const actionCreators = {
    requestFunctionRole: (pageload: boolean, version: number, method: string, body: RoleAccess.RoleAccess): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.file && pageload === true && method === "GET") {
            fetch(`/v1/role-access?code=` + body.role_access_code + '&app_id=' + body.sid, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                },
            })
                .then(response => response.json() as Promise<RoleAccess.ResponseRoleAccess>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_ROLEACCESS', model: data, version: version + 2 });
                })
                .then(error => {
                    dispatch({ type: 'REQUEST_ROLEACCESS', version: version + 1 });
                });
        }

        if (appState && appState.file && method === "POST") {

            fetch(`/v1/role-access/add`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
            })
                .then(response => response.json() as Promise<RoleAccess.ResponseRoleAccess>)
                .then(data => {
                    console.warn(data)
                    dispatch({ type: 'RECEIVE_ROLEACCESS', model: data, version: version + 3 });
                })
                .then(error => {
                    console.log(error)
                    dispatch({ type: 'REQUEST_ROLEACCESS', version: version + 1 });
                });
        }
        if (appState && appState.file && method === "PUT") {

            fetch(`/v1/role-access/edit`, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
            })
                .then(response => response.json() as Promise<RoleAccess.ResponseRoleAccess>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_ROLEACCESS', model: data, version: version + 4 });
                })
                .then(error => {
                    dispatch({ type: 'REQUEST_ROLEACCESS', version: version + 1 });
                });
        }
        if (appState && appState.file && method === "DELETE") {

            fetch(`/v1/role-access?code=` + body.role_access_code, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                },
            })
                .then(response => response.json() as Promise<RoleAccess.ResponseRoleAccess>)
                .then(data => {
                    console.log(data)
                    dispatch({ type: 'RECEIVE_ROLEACCESS', model: data, version: version + 2 });
                })
                .then(error => {
                    dispatch({ type: 'REQUEST_ROLEACCESS', version: version + 1 });
                });
        }

    }
};

const unloadedState: RoleAccess.RoleAccessState = { isLoadingRole: false, response: undefined, version: 0 };

export const reducer: Reducer<RoleAccess.RoleAccessState> = (state: RoleAccess.RoleAccessState | undefined, incomingAction: Action): RoleAccess.RoleAccessState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_ROLEACCESS':
            return {
                isLoadingRole: false,
                version: action.version
            };
        case 'RECEIVE_ROLEACCESS':
            return {
                isLoadingRole: true,
                response: action.model,
                version: action.version
            };

        default: return state;
    }
};