import React, { createContext, useContext, useState } from 'react';

import { CompanyMaster } from '../models/CompanyMaster';
import { EmployeeMain, EmployeeMainList } from '../models/Employee';

export interface IUserContext extends EmployeeMain {
    isRoleAccessLoaded: boolean;
    availableCompanies: Array<CompanyMaster>;
    roles: Array<string>;
    actions: {
        setId: (id: string) => void;
        setListCompany: (listCompany: Array<EmployeeMainList>) => void;
        setAvailableCompanies: (companies: Array<any>) => void;
        setRoles: (roles: Array<string>) => void;
    };
}

export const UserContext = createContext<IUserContext>({
    id: '',
    list_company: [],
    isRoleAccessLoaded: false,
    availableCompanies: [],
    roles: [],
    actions: {
        setId: (_: string) => undefined,
        setListCompany: (_: Array<EmployeeMainList>) => undefined,
        setAvailableCompanies: (_: Array<any>) => undefined,
        setRoles: (_: Array<string>) => undefined
    }
});

export function useUserContext() {
    return useContext<IUserContext>(UserContext);
}

function UserProvider({ children }) {
    const [id, setId] = useState<string>('');
    const [list_company, setListCompany] = useState<EmployeeMainList[]>([]);
    const [isRoleAccessLoaded, setIsRoleAccessLoaded] = useState<boolean>(false);
    const [availableCompanies, setAvailableCompanies] = useState<CompanyMaster[]>([]);
    const [roles, setRoles] = useState<string[]>([]);

    const willSetListCompany = (companies: EmployeeMainList[]) => {
        Promise.resolve(companies)
            .then(companies => setListCompany(companies))
            .then(() => setIsRoleAccessLoaded(true));
    };

    const provider: IUserContext = {
        id,
        list_company,
        isRoleAccessLoaded,
        availableCompanies,
        roles,
        actions: {
            setId,
            setListCompany: willSetListCompany,
            setRoles,
            setAvailableCompanies
        }
    };

    return (
        <UserContext.Provider value={provider}>
            {children}
        </UserContext.Provider>
    );
}

export default UserProvider;