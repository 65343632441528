import * as React from 'react';
import { connect } from 'react-redux';
import imgFile from '../../Images/Icon/icon-File.png';
import imgPDF from '../../Images/Icon/icon-PDF.png';
import imgPowerPoint from '../../Images/Icon/icon-PowerPoint.png';
import imgWord from '../../Images/Icon/icon-Word.png';
import imgXLS from '../../Images/Icon/icon-XLS.png';
import * as Models from '../../models/File';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/FilePrimaryIndex';
import { onClickTr, formatYMDToDMY } from '../../modules/common'

type Store = Models.FileState & typeof Stores.actionCreators;

class FilesVersionComponent extends React.PureComponent<any, { pageLoad: boolean, vertion: Array<string>, data: Array<Array<string>>, isNewLoad: boolean }, Store> {

    constructor(props: any) {
        super(props);

        this.state = {
            pageLoad: true,
            vertion: [],
            data: [],
            isNewLoad: true
        }

        this.onClickTr = this.onClickTr.bind(this);
        this.previewFile = this.previewFile.bind(this);
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.setState({
            pageLoad: true
        })

        this.props.requestFunction(this.state.pageLoad, this.props.primaryIndex, false);

        this.setState({
            pageLoad: false
        })
    }

    private onClickTr(e: any) {
        var path = e.target.dataset.filepath

        window.open(
            path,
            '_blank'
        );
    }

    private replaceDate(e: string) {

        var date = "";
        if (e !== null) {
            if (e.length >= 10) {
                var date = e.substring(0, 10);
            }
        }

        return date;
    }

    private previewFile(item: Models.File) {

        if (item.file_name.indexOf('.jpg') !== -1 || item.file_name.indexOf('.png') !== -1) {
            return item.file_path;
        } else if (item.file_name.indexOf('.pdf') !== -1) {
            return imgPDF;
        } else if (item.file_name.indexOf('.xls') !== -1 || item.file_name.indexOf('.xlsx') !== -1) {
            return imgXLS;
        } else if (item.file_name.indexOf('.docx') !== -1) {
            return imgWord;
        } else if (item.file_name.indexOf('.pptx') !== -1) {
            return imgPowerPoint;
        } else {
            return imgFile;
        }

    }

    // Check role access before open a file
    public handleBeforeOpenFile(indexString: string) {
        //const companyCode = indexString.split('|')[1];

        return (event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => {
            event.persist();
            onClickTr(event);

            //try {
            //    const { availableCompanies, list_company }: IUserContext = this.context;

            //    // Don't forget to remove first if statement, We don't need it anymore
            //    const foundAvailableCompany = availableCompanies.find(availableCompany => availableCompany.company_code === companyCode);

            //    if (foundAvailableCompany !== undefined) {
            //        const foundCompany = list_company.find(company => company.sid === foundAvailableCompany.sid);

            //        if (foundCompany !== undefined) {
            //            const roles = foundCompany.role_access.split('|');

            //            if (roles.includes('03')) {
            //                onClickTr(event);
            //            } else {
            //                throw null;
            //            }
            //        } else {
            //            throw null;
            //        }
            //    } else {
            //        throw null;
            //    }
            //} catch (_error) {
            //    // console.log('ngame you cannot access this file!');

            //    Swal.fire({
            //        title: 'คุณไม่มีสิทธิ์การดูไฟล์นี้',
            //        icon: 'error',
            //        showCancelButton: false,
            //        confirmButtonColor: '#3085d6',
            //        confirmButtonText: 'ตกลง'
            //    });
            //}
        }
    }

    render() {
        const { response } = this.props
        if (this.props.isReLoadFile) {
            this.ensureDataFetched();
        }

        if (response !== undefined && this.state.isNewLoad && response.result_list !== null) {
            this.setState({
                vertion: [],
                data: []
            });

            var listMetaData: Array<string> = [];
            if (response.total_count !== 0) {
                for (var i = 0; i < response.result_list.length; i++) {
                    var metaData: string[] = (response.result_list[i].metadata as string).split("|")
                    for (var j = 0; j < metaData.length; j++) {
                        listMetaData.push((metaData[j].split(":")[0]))
                    }
                }
                var metaDataName = new Set(listMetaData)
                let arrayMetaDataName = Array.from(metaDataName);
                var versionList: Array<string> = [];
                var reversedList: Array<Array<string>> = [];
                for (var n = 0; n < arrayMetaDataName.length; n++) {
                    var version: Array<string> = [];
                    var MetaDataInName: Array<string> = [];
                    for (var i = 0; i < response.result_list.length; i++) {
                        var dataInMeta = false
                        var data = ""
                        var metaData: string[] = (response.result_list[i].metadata as string).split("|")
                        for (var j = 0; j < metaData.length; j++) {
                            listMetaData.push(metaData[j].split(":")[0])
                            if (arrayMetaDataName[n] === metaData[j].split(":")[0]) {
                                data = metaData[j].split(":")[1]
                                dataInMeta = true
                            }
                        }
                        if (dataInMeta === false) {
                            MetaDataInName.push("")
                        } else {
                            MetaDataInName.push(data)
                        }
                        version.push(response.result_list[i].version)

                    }
                    const reversedVersion = version.reverse();
                    versionList = reversedVersion
                    const reversed = MetaDataInName.reverse();

                    reversedList.push(reversed)

                    MetaDataInName.unshift(arrayMetaDataName[n])
                }

                this.setState({
                    vertion: versionList,
                    data: reversedList,
                    isNewLoad: false
                });
            }
        }
        return (
            <div className="form-group div-main-style" style={{ backgroundColor: '#ffff' }}>
                <div className='trapezoid' style={{ width: 'auto', display: 'inline-block', marginLeft: '0px' }}>
                    <span style={{ fontFamily: "sans-serif" }}>
                        Files Version
                    </span>
                </div>
                <table className='table custom-table'>
                    <thead>
                        {this.props.isVesionFile ?
                            <>
                                <tr>
                                    <th rowSpan={2} style={{ textAlign: "center" }}>Metadata Key</th>
                                    <th style={{ textAlign: "center" }} colSpan={(this.state.vertion.length)} className="td-pi-header-version">Version</th>
                                </tr>
                                <tr>
                                    {this.props.isLoading && this.state.vertion.map((item) =>
                                        <td className="td-pi-list-version" >{item}</td>
                                    )}
                                </tr>
                            </>
                            :
                            <tr>
                                <th className="td-icon td-center" style={{ width: '50px' }}><img src={imgFile} alt="file" width="25" height="25" /></th>
                                <th>Name</th>
                                <th className="td-path" style={{ width: "500px" }} >Path</th>
                                <th className="td-update-on" >Modified</th>
                                <th className="td-update-by" >Modified By</th>
                                <th className="td-version th-center" >Version</th>
                            </tr>
                        }
                    </thead>
                    <tbody>

                        {this.props.isLoading && this.props.isVesionFile && this.props.response.result_list !== null && this.state.data.map((item, key) =>
                            <tr key={item[0]} className="tr-meta-data" style={{ fontWeight: "bold" }}>
                                {this.props.isLoading && (this.state.data[key]).map((item1, key1) =>
                                    <>
                                        {this.state.data[key][1] !== item1 && key1 > 0 ?
                                            <td className="td-file-name-version" style={{ color: "red" }}>{item1}</td>
                                            :
                                            <>
                                                {key1 === 0 ?
                                                    <td className="td-file-name-version" style={{ fontWeight: "bold" }}>{item1}</td>
                                                    :
                                                    <td className="td-file-name-version">{item1}</td>
                                                }
                                            </>
                                        }
                                    </>
                                )}
                            </tr>
                        )}

                        {this.props.isLoading && this.props.isVesionFile === false && this.props.response.result_list !== null && this.props.response.result_list.map((item: Models.File) =>
                            <tr key={item.id} className="table-tbody" >
                                <td data-filepath={item.file_path} style={{ width: '50px' }} className="td-icon td-center" ><img src={this.previewFile(item)} alt="file" width="25" height="25" /></td>
                                {/*<td data-filepath={item.file_path} onClick={this.onClickTr} className="td-file-name">{item.file_name}</td>*/}
                                <td data-filepath={item.file_path} data-id={item.id} onClick={this.handleBeforeOpenFile(item.primary_index)} className="td-file-name">{item.file_name}</td>
                                <td data-filepath={item.file_path} className="td-path" >{item.full_path}</td>
                                <td data-filepath={item.file_path} className="td-update-on" >{formatYMDToDMY(item.created_on)}</td>
                                <td data-filepath={item.file_path} className="td-update-by" >{item.created_by}</td>
                                <td data-filepath={item.file_path} className="td-version td-center" >{item.version}</td>
                            </tr>
                        )}

                        {(this.props.isLoading && this.props.response.result_list === null) ? <tr><td colSpan={5} style={{ color: "black", fontSize: '10pt', textAlign: "center" }}>No data.</td></tr> : ''}
                    </tbody>
                </table>

            </div>
        );
    }
}

export default connect((state: ApplicationState) => state.file,
    Stores.actionCreators
)(FilesVersionComponent);