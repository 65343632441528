import { Reducer } from 'redux';
import { AppThunkAction } from '..';
import { ResponseList, StateStore } from '../../models/Common';
import { FolderAuthorize } from '../../models/FolderAuthorize';
import { FolderAuthorizeUserMapping } from '../../models/FolderAuthorizeUserMapping';

interface ToggleButtonStates {
    type: 'TOGGLE_BUTTON_STATES',
    buttonStates: ButtonStatesType
}

interface ResetButtonStates {
    type: 'RESET_BUTTON_STATES'
}

interface SetUserRoles {
    type: 'SET_USER_ROLE',
    roles: string[]
}

type KnownAction =
    | ToggleButtonStates
    | ResetButtonStates
    | SetUserRoles;

export const actionCreators = {
    onSelectFolder({ sid, nodeCode, username }: { sid: string, nodeCode: string, username: string }, callback?: (buttonStates: ButtonStatesType) => void): AppThunkAction<KnownAction> {
        return async (dispatch, getState) => {
            const token = localStorage.getItem('DMS_APPLICATION');
            const requestOptions: RequestInit = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            const getFolderRoleIdsAsync = fetch('/v1/folder-role?' + new URLSearchParams({ app_id: sid, node_code: nodeCode }), requestOptions);
            const getFolderAuthorizeUserMappingsAsync = fetch('/v1/folder-auth-user-mapping?' + new URLSearchParams({ app_id: sid, username, bucket: nodeCode }), requestOptions);

            Promise.all([
                getFolderRoleIdsAsync,
                getFolderAuthorizeUserMappingsAsync
            ])
                .then(([folderRolesResponse, folderMappingsResponse]) => {
                    if (folderRolesResponse.ok && folderMappingsResponse.ok) {
                        return Promise.all<[ResponseList<FolderAuthorize>, ResponseList<FolderAuthorizeUserMapping>]>([
                            folderRolesResponse.json(),
                            folderMappingsResponse.json()
                        ]);
                    } else {
                        return Promise.reject('Fail to fetched folderRoles or folderMappings');
                    }
                })
                .then(([folderRolesResult, folderMappingsResult]: any) => {
                    const result1 = (folderRolesResult.total_count > 0) ? folderRolesResult.result_list : [];
                    const result2 = (folderMappingsResult.total_count > 0) ? folderMappingsResult.result_list : [];

                    //console.log(folderRolesResult)
                    //console.log(folderMappingsResult)

                    return [
                        result1,
                        result2
                    ] as [FolderAuthorize[], FolderAuthorizeUserMapping[]];
                })
                .then(([folderRoles, folderMappings]) => {
                    const buttonStates: ButtonStatesType = {
                        disabledUpload: true,
                        disabledEdit: true,
                        disabledCopy: true,
                        disabledDelete: true,
                        disabledDisplay: true,
                        disabledMoveLink: true,
                        disabledUpdateBoxId: true
                    };

                    //console.log(folderRoles);
                    //console.log(folderMappings);

                    if (
                        folderRoles !== undefined &&
                        folderRoles.length > 0 &&
                        folderMappings !== undefined &&
                        folderMappings.length > 0
                    ) {
                        const folderMapping = folderMappings[0];
                        const folderRole = folderRoles.find(folderRole => folderRole.role_id === folderMapping.roleId);

                        if (folderRole !== undefined) {
                            const folderRoleAccesses = folderRole.auth_ids.split('|');

                            for (const folderRole of folderRoleAccesses) {
                                switch (folderRole) {
                                    case '01': // Create
                                        buttonStates.disabledUpload = false;
                                        break;
                                    case '02': // Change
                                        buttonStates.disabledEdit = false;
                                        break;
                                    case '03': // Display
                                        buttonStates.disabledDisplay = false;
                                        break;
                                    case '04': // Delete
                                        buttonStates.disabledDelete = false;
                                        break;
                                    case '05': // Copy Link
                                        buttonStates.disabledCopy = false;
                                        break;
                                    case '06': // Move Link
                                        buttonStates.disabledMoveLink = false;
                                        break;
                                    case '00': // Admin
                                    case '99': // Super Admin
                                        Object.entries(buttonStates).forEach(([key]) => buttonStates[key] = false);
                                        break;
                                    case '51': // Display Update Box ID
                                    case '52': // Edit Update Box ID
                                        buttonStates.disabledUpdateBoxId = false;
                                        break;
                                }
                            }

                            /* Old Fashion Way
                            const { userRoles } = getState().folderRole!;

                            for (const userRole of userRoles) {
                                const hasRole = folderRoleAccesses.includes(userRole);

                                if (hasRole) {
                                    switch (userRole) {
                                        case '01': // Create
                                            buttonStates.disabledUpload = false;
                                            break;
                                        case '02': // Change
                                            buttonStates.disabledEdit = false;
                                            break;
                                        case '03': // Display
                                            buttonStates.disabledDisplay = false;
                                            break;
                                        case '04': // Delete
                                            buttonStates.disabledDelete = false;
                                            break;
                                        case '05': // Copy Link
                                            buttonStates.disabledCopy = false;
                                            break;
                                        case '06': // Move Link
                                            buttonStates.disabledMoveLink = false;
                                            break;
                                        case '00': // Admin
                                        case '99': // Super Admin
                                            Object.entries(buttonStates).forEach(([key]) => buttonStates[key] = false);
                                            break;
                                        case '51': // Display Update Box ID
                                        case '52': // Edit Update Box ID
                                            buttonStates.disabledUpdateBoxId = false;
                                            break;
                                    }
                                }
                            }
                            */
                        }
                    }

                    dispatch({ type: 'TOGGLE_BUTTON_STATES', buttonStates });

                    if (callback !== undefined) {
                        callback(buttonStates);
                    }
                })
                .catch(error => console.error(error));
        }
    },
    calculateRoles: (listOfRoles: string[][]): AppThunkAction<KnownAction> => (dispatch, _getState) => {
        const buttonStates: ButtonStatesType = {
            disabledUpload: false,
            disabledEdit: false,
            disabledCopy: false,
            disabledDelete: false,
            disabledDisplay: false,
            disabledMoveLink: false,
            disabledUpdateBoxId: false
        };

        const switchToggler = (role: string) => {
            switch (role) {
                case '01': // Create
                    buttonStates.disabledUpload = true;
                    break;
                case '02': // Change
                    buttonStates.disabledEdit = true;
                    break;
                case '03': // Display
                    buttonStates.disabledDisplay = true;
                    break;
                case '04': // Delete
                    buttonStates.disabledDelete = true;
                    break;
                case '05': // Copy Link
                    buttonStates.disabledCopy = true;
                    break;
                case '06': // Move Link
                    buttonStates.disabledMoveLink = true;
                    break;
                case '00': // Admin
                case '99': // Super Admin
                    // Object.entries(buttonStates).forEach(([key]) => buttonStates[key] = true);
                    break;
                case '51': // Display Update Box ID
                case '52': // Edit Update Box ID
                    buttonStates.disabledUpdateBoxId = true;
                    break;
            }
        };

        const listOfRoleLength = listOfRoles.length; // Mimic file count

        // if (listOfRoleLength === 0) {
        //     return;
        // }

        const defaultRoles = ['01', '02', '03', '04', '05', '06', '99', '00', '51', '52'];

        const willCheckRoles = listOfRoles.reduce((prev, curr) => prev.concat(curr), []);
        const willCheckRoleLength = willCheckRoles.length;
        const mustDisabledRoles = defaultRoles.filter(role => !willCheckRoles.includes(role));
        const mustDisabledRoleLength = mustDisabledRoles.length;

        console.log('ngame will check', willCheckRoles);
        console.log('ngame must dis', mustDisabledRoles);

        for (let i = 0; i < willCheckRoleLength; i++) {
            const checkRole = willCheckRoles[i];

            for (let j = 0; j < listOfRoleLength; j++) {
                const roles = listOfRoles[j];

                if (!roles.includes(checkRole)) {
                    switchToggler(checkRole);

                    break;
                }
            }
        }

        for (let i = 0; i < mustDisabledRoleLength; i++) {
            const role = mustDisabledRoles[i];
            switchToggler(role);
        }

        dispatch({ type: 'TOGGLE_BUTTON_STATES', buttonStates });
    },
    resetButtonStates: (): AppThunkAction<KnownAction> => (dispatch, _getState) => {
        dispatch({ type: 'RESET_BUTTON_STATES' });
    },
    setRoles: (roles: string[]): AppThunkAction<KnownAction> => (dispatch, _getState) => {
        dispatch({ type: 'SET_USER_ROLE', roles });
    }
};

const defaultButtonStates = {
    disabledUpload: true,
    disabledEdit: true,
    disabledDisplay: true,
    disabledDelete: true,
    disabledCopy: true,
    disabledMoveLink: true,
    disabledUpdateBoxId: true
};

const defaultUserRolesState: UserRolesState = {
    userRoles: []
}

type UserRolesState = { userRoles: string[] };
export type ButtonStatesType = typeof defaultButtonStates;
export type FolderRoleStoreState =
    & ButtonStatesType
    & UserRolesState;

const initialState: FolderRoleStoreState = {
    ...defaultButtonStates,
    ...defaultUserRolesState
};

export const reducer: Reducer<FolderRoleStoreState, KnownAction> = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_BUTTON_STATES': {
            return {
                ...state,
                ...action.buttonStates
            };
        }
        case 'RESET_BUTTON_STATES': {
            return {
                ...state,
                ...defaultButtonStates
            };
        }
        case 'SET_USER_ROLE': {
            return {
                ...state,
                userRoles: action.roles
            };
        }
        default: return state;
    }
}