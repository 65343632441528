import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/MetaData';
import * as Models from '../../models/MetaData';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { FaPlusCircle, FaEdit, FaTimesCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';

type Store = Models.MetaDataState & typeof Stores.actionCreators;

class MetaDataComponent extends React.PureComponent<any, { pageLoad: boolean, isOpenModal: boolean, modelMetaData: Models.MetaData, manageMode: string, version: number, modelListGet: Array<Models.MetaData> }, Store> {

    constructor(props: any) {
        super(props);

        this.state = {
            pageLoad: true,
            isOpenModal: false,
            modelMetaData: {
                sid: "DEV",
                metadata_code: "metadata_code",
                metadata_name: "metadata_name",
                created_by: "created_by",
                created_on: "created_on",
                updated_by: "updated_by",
                updated_on: "updated_on",
            },
            manageMode: "",
            version: 0,
            modelListGet: []
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.replaceDate = this.replaceDate.bind(this);
        this.handleChangeMataDataCode = this.handleChangeMataDataCode.bind(this);
        this.handleChangeMataDataName = this.handleChangeMataDataName.bind(this);
        this.actionsClick = this.actionsClick.bind(this)
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {

        this.props.requestFunction(this.state.version, "GET");

    }

    private toggleModal() {
        this.setState({
            isOpenModal: !this.state.isOpenModal
        });
    }

    private replaceDate(e: string) {

        var date = "";
        if (e !== null) {
            if (e.length >= 10) {
                var date = e.substring(0, 10);
            }
        }

        return date;
    }

    private handleChangeMataDataCode(e: any) {
        var data = this.state.modelMetaData;
        data.metadata_code = e.target.value;

        this.setState({
            modelMetaData: data
        });

    }

    private handleChangeMataDataName(e: any) {
        var data = this.state.modelMetaData;
        data.metadata_name = e.target.value;

        this.setState({
            modelMetaData: data
        });

    }

    private actionsClick(type) {
        const { modelMetaData } = this.state
        if (modelMetaData.metadata_code !== "metadata_code" && modelMetaData.metadata_name !== "metadata_name" && modelMetaData.metadata_code !== "" && modelMetaData.metadata_name !== "") {
            this.props.requestFunction(this.state.version, type, this.state.modelMetaData);
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        } else {
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: 'โปรดกรอกข้อมูลให้ครบถ้วน',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            })
        }
    }

    openModal = (type, code, name) => {
        var data = this.state.modelMetaData;
        data.metadata_code = code
        data.metadata_name = name

        this.setState({ manageMode: type, modelMetaData: data });

        this.toggleModal();
    }

    setBodyModal = () => {

        if (this.state.manageMode === "Add") {
            return <div>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="metadata-font">Metadata Code</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="" type="text" onChange={this.handleChangeMataDataCode} />
                </InputGroup>
                <br />
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="metadata-font">Metadata Name</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="" type="text" onChange={this.handleChangeMataDataName} />
                </InputGroup>
            </div>;
        } else if (this.state.manageMode === "Edit") {
            return <div>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="metadata-font">Metadata Code</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="" type="text" defaultValue={this.state.modelMetaData.metadata_code} readOnly onChange={this.handleChangeMataDataCode} />
                </InputGroup>
                <br />
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="metadata-font">Metadata Name</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="" type="text" defaultValue={this.state.modelMetaData.metadata_name} onChange={this.handleChangeMataDataName} />
                </InputGroup>
            </div>;
        } else if (this.state.manageMode === "Delete") {
            return <div>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="metadata-font">Metadata Code</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Metadata Code" type="text" value={this.state.modelMetaData.metadata_code} onChange={this.handleChangeMataDataCode} disabled />
                </InputGroup>
                <br />
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="metadata-font">Metadata Name</InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Metadata Name" type="text" value={this.state.modelMetaData.metadata_name} onChange={this.handleChangeMataDataName} disabled />
                </InputGroup>
            </div>;
        }

    }

    setButtonModal = () => {

        if (this.state.manageMode === "Add") {
            return <Button color="primary" className="metadata-font" onClick={() => this.actionsClick('POST')}>Add</Button>;
        } else if (this.state.manageMode === "Edit") {
            return <Button color="warning" className="metadata-font" onClick={() => this.actionsClick('PUT')}>Edit</Button>;
        } else if (this.state.manageMode === "Delete") {
            return <Button color="danger" className="metadata-font" onClick={() => this.actionsClick('DEL')}>Delete</Button>;
        }

    }

    render() {
        if (this.props.isLoading) {
            this.setState({ modelListGet: this.props.response.result_list as Array<Models.MetaData>, isOpenModal: false });
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        }
        return (
            <div className="div-meta-data-main div-meta-role">
                <div>
                    <div className='trapezoid' style={{ width: 'auto', display: 'inline-block' }}>
                        <span className="metadata-font">
                            Metadata
                        </span>
                    </div>
                    <div style={{ display: 'inline', float: 'right' }}>
                        <Button className="btn-add metadata-font" onClick={() => this.openModal('Add', '', '')}> <FaPlusCircle style={{ marginBottom: '4px' }} /> Add</Button>

                    </div>
                </div>
                <table className='table custom-table table-hover'>
                    <thead>
                        <tr>
                            <th className="td-code">ID</th>
                            <th className="td-name">Name</th>
                            <th className="td-update-on" >Modified</th>
                            <th className="td-update-by" >Modified By</th>
                            <th className="td-menu" >Menu</th>
                        </tr>
                    </thead>
                    <tbody>

                        {this.state.modelListGet !== undefined && this.state.modelListGet.length > 0 && this.state.modelListGet.map((item: Models.MetaData) =>
                            <tr key={item.metadata_code} className="table-tbody">
                                <td className="td-code">{item.metadata_code}</td>
                                <td className="td-name">{item.metadata_name}</td>
                                <td className="td-update-on" >{this.replaceDate(item.created_on)}</td>
                                <td className="td-update-by" >{item.created_by}</td>
                                <td className="td-menu" >
                                    <Button className="btn-edit-file" size="sm" onClick={() => this.openModal('Edit', item.metadata_code, item.metadata_name)}><FaEdit style={{ marginBottom: '4px' }} /> Edit</Button>{' '}
                                    <Button className="btn-delete-file" size="sm" style={{ padding: '3px 5px' }} onClick={() => this.openModal('Delete', item.metadata_code, item.metadata_name)}><FaTimesCircle style={{ marginBottom: '2px' }} /> Delete</Button>
                                </td>
                            </tr>
                        )}

                        {this.state.modelListGet === null ? <tr><td colSpan={5} style={{ color: "black", fontSize: '10pt', textAlign: "center" }}>No data.</td></tr> : ''}
                    </tbody>
                </table>

                <Modal isOpen={this.state.isOpenModal} className="modal-upload-file">
                    <ModalHeader className="metadata-font" toggle={this.toggleModal}>{this.state.manageMode + ' Mata Data'}</ModalHeader>
                    <ModalBody>

                        {this.setBodyModal()}

                    </ModalBody>
                    <ModalFooter>
                        {this.setButtonModal()}
                        <Button color="secondary" className="metadata-font" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>

            </div>
        );
    }
}

export default connect((state: ApplicationState) => state.metaData,
    Stores.actionCreators
)(MetaDataComponent);