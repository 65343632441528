import * as React from 'react';
import { connect } from 'react-redux';
import FilesComponent from './FilesComponent';
import FoldersComponent from './FoldersComponent';
import UploadFileComponent from './UploadFileComponent';
import ButtonDeleteFileComponent from './ButtonDeleteFileComponent';
import ButtonEditFileV2Component from './ButtonEditFileV2Component';
import ButtonCopyFileComponent from './ButtonCopyFileComponent'
import BoxIDComponent from './BoxIDComponent'
import FolderImg from '../../Images/Icon/folder.png';
import { Button, ButtonGroup, Row, Col } from 'reactstrap';
import * as Models from '../../models/File';
import { FaFile, FaSync } from "react-icons/fa";
import { UserContext, IUserContext } from '../../context/UserProvider';
import { ResponseList } from '../../models/Common';
import { convertMetadataToEntries, convertMetadataToString } from '../../modules/common';
import { FolderAuthorize } from '../../models/FolderAuthorize';
import { FolderAuthorizeUserMapping } from '../../models/FolderAuthorizeUserMapping';
import { ApplicationState } from '../../store';
import * as FolderRoleStore from '../../store/ui/Role';

interface State {
    folderSelectedFullPath: string,
    valueCodeAndName: any,
    reloadFolder: boolean,
    checkFile: boolean,
    checkRefresh: boolean,
    switchPage: string,
    folderSelectedCode: string,
    folderSelectedName: string,
    isReLoadFile: boolean,
    getValueEdit: boolean,
    btClick: string,
    metadataGroup: string,
    modelListFileIsSelected: Array<Models.File>
    strictMode: boolean;
    disabledUpload: boolean;
    disabledCopy: boolean;
    disabledEdit: boolean;
    disabledDelete: boolean;
}

class FileExplorerComponent extends React.Component<any, State, any> {
    static contextType: React.Context<any> | undefined = UserContext;

    constructor(props: any) {
        super(props);

        this.state = {
            folderSelectedCode: "",
            folderSelectedFullPath: "All",
            folderSelectedName: "All",
            isReLoadFile: false,
            modelListFileIsSelected: [],
            switchPage: "File",
            checkRefresh: false,
            checkFile: false,
            reloadFolder: false,
            valueCodeAndName: {},
            getValueEdit: false,
            btClick: "",
            metadataGroup: "",
            strictMode: false,

            disabledUpload: true,
            disabledCopy: true,
            disabledEdit: true,
            disabledDelete: true
        }

        this.onSelectFolder = this.onSelectFolder.bind(this);
        this.onUploadSuccess = this.onUploadSuccess.bind(this);
        this.handleChangeSelectedFiles = this.handleChangeSelectedFiles.bind(this);
        this.handleCreatableSelectChange = this.handleCreatableSelectChange.bind(this);
        this.onMetadataChange = this.onMetadataChange.bind(this);
        this.addMetadata = this.addMetadata.bind(this);
        this.removeMetadata = this.removeMetadata.bind(this);
        this.changeSelectedFiles = this.changeSelectedFiles.bind(this);
        this.getValueFileList = this.getValueFileList.bind(this);
        this.removeSelectedFile = this.removeSelectedFile.bind(this);
    }

    // componentDidUpdate() {
    //     const path = this.state.folderSelectedFullPath;
    //     const pathArray = path.split(' > ');

    //     const { availableCompanies }: IUserContext = this.context;

    //     for (const company of availableCompanies) {
    //         if (pathArray.includes(company.company_short_name)) {
    //             if (!this.state.strictMode) {
    //                 this.setState({ strictMode: true });
    //                 return;
    //             }
    //         }
    //     }
    // }

    public onSelectFolder(fullpath: string, nodeCode: string, name: string, metadata: string, sid: string) {
        const { id: username, roles: userRoles }: IUserContext = this.context;

        this.props.onSelectFolder({ sid: sid || '*', nodeCode, username });

        const pathArray = fullpath.split(' > ');
        const { availableCompanies, list_company, actions }: IUserContext = this.context;

        let strictMode = false;
        let roles: Array<string> = [];

        //console.log(username)
        //console.log(list_company)
        //console.log(availableCompanies)

        //console.log(this.props)

        for (let i = 0; i < availableCompanies.length; i++) {
            if (pathArray.includes(availableCompanies[i].company_short_name)) {
                const rolesRaw = list_company[i].role_access;
                roles = rolesRaw.split('|');
                strictMode = true;

                break;
            }
        }

        var codeandname = {
            fullpath: fullpath,
            code: nodeCode,
            name: name
        }

        this.props.setRoles(roles);
        actions.setRoles(roles);
        this.setState({
            folderSelectedFullPath: fullpath,
            folderSelectedCode: nodeCode,
            folderSelectedName: name,
            checkFile: true,
            valueCodeAndName: codeandname,
            metadataGroup: metadata,
            strictMode
        });
    }

    public changeSelectedFiles(files: Models.File[]) {
        this.setState({ modelListFileIsSelected: files });
    }

    public handleChangeSelectedFiles(key: keyof Pick<Models.File, 'group_id' | 'bucket'>, atIndex: number) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            event.persist();
            const value = event.target.value;

            this.setState(prevState => {
                const selectedFiles = [...prevState.modelListFileIsSelected];
                selectedFiles[atIndex][key] = value;

                return {
                    ...prevState,
                    modelListFileIsSelected: selectedFiles
                };
            });
        }
    }

    public handleCreatableSelectChange(key: keyof Pick<Models.File, 'index_string' | 'metadata'>, atIndex: number) {
        return (newValue: { label: string, value: string, __isNew__: boolean }[], _actionMeta: unknown) => {
            this.setState(prevState => {
                const selectedFiles = [...prevState.modelListFileIsSelected];

                if (key === 'index_string') {
                    selectedFiles[atIndex][key] = newValue.map(value => value.value).join('|');
                } else if (key === 'metadata') {
                    // under-implement
                }

                return {
                    ...prevState,
                    modelListFileIsSelected: selectedFiles
                };
            });
        }
    }

    resetCheckFile(value) {
        if (value === "reset") {
            this.setState({
                checkFile: false
            });
        }
    }

    oncheckRefresh = () => {
        this.setState({
            checkRefresh: true
        });
    }

    resetoncheckRefresh = (check) => {
        if (check === "reset") {
            this.setState({
                checkRefresh: false
            });
        }
    }

    public onUploadSuccess() {
        this.setState({ isReLoadFile: true });
        setTimeout(() => {
            this.setState({ isReLoadFile: false });
        }, 100);
    }

    public onEditSuccess() {
        this.setState({ isReLoadFile: true });
        setTimeout(() => {
            this.setState({ isReLoadFile: false });
        }, 100);
    }

    public switchPage(check) {
        console.log(check)
        this.setState({ switchPage: check })
    }

    //to do get data from file component
    GetDataSelectFileToButton = () => {
        return this.state.modelListFileIsSelected
    }

    // ??? Get or update ???
    GetDataSelectFile = (fileSelect: Array<Models.File>) => {
        this.setState({
            modelListFileIsSelected: fileSelect
        })
    }

    public getValueFileList(check: string) {
        if (check === "get") {
            var node = "node"
            var wfList: any = [];
            Array.from(document.querySelectorAll("input[name=" + node + "]"))
                .forEach((checkbox) => {
                    var cb = document.getElementById(checkbox.id) as HTMLInputElement;
                    if (cb.checked) {
                        if (cb.dataset.filelist) {
                            var filelist = JSON.parse(cb.dataset.filelist)
                            wfList.push(filelist)
                        }
                    }
                    this.setState({ modelListFileIsSelected: wfList, getValueEdit: true })
                })
        }
    }

    reloading(value) {
        if (value === "reset") {
            this.setState({
                checkRefresh: true,
                reloadFolder: true
            });
        }
    }

    resetReloadFolder(value) {
        if (value === "reset") {
            this.setState({
                reloadFolder: false
            });
        }
    }

    resetGetValueEdit(val) {
        if (val === "reset") {
            this.setState({ getValueEdit: false })
        }
    }

    onClickSaveBt(val) {
        if (val === "copy") {
            this.setState({ btClick: "copy" })
        } else if (val === "edit") {
            this.setState({ btClick: "edit" })
        }
    }

    public forceRerender(): void {
        this.forceUpdate();
    }

    public onMetadataChange(atFileIndex: number, atMetadataIndex: number) {
        return (key: string, value: string) => {
            // console.log('ngame key/value change', [key, value]);
            this.setState(prevState => {
                const nextSelectedFileState = [...prevState.modelListFileIsSelected];
                const metadataEntries = convertMetadataToEntries(nextSelectedFileState[atFileIndex].metadata);
                const metadataTarget = metadataEntries[atMetadataIndex];
                metadataTarget[0] = key;
                metadataTarget[1] = value;

                nextSelectedFileState[atFileIndex].metadata = convertMetadataToString(metadataEntries);

                return {
                    ...prevState,
                    modelListFileIsSelected: nextSelectedFileState
                };
            });
        }
    }

    public addMetadata(atFileIndex: number): void {
        this.setState(prevState => {
            const nextSelectedFileState = [...prevState.modelListFileIsSelected];
            const metadataEntries = convertMetadataToEntries(nextSelectedFileState[atFileIndex].metadata);
            metadataEntries.push(['', '']);

            nextSelectedFileState[atFileIndex].metadata = convertMetadataToString(metadataEntries);

            return {
                ...prevState,
                modelListFileIsSelected: nextSelectedFileState
            };
        });
    }

    public removeMetadata(atFileIndex: number, removeAtIndex: number): void {
        this.setState(prevState => {
            const nextSelectedFileState = [...prevState.modelListFileIsSelected];
            const metadataEntries = convertMetadataToEntries(nextSelectedFileState[atFileIndex].metadata);
            metadataEntries.splice(removeAtIndex, 1);

            nextSelectedFileState[atFileIndex].metadata = convertMetadataToString(metadataEntries);

            return {
                ...prevState,
                modelListFileIsSelected: nextSelectedFileState
            };
        });
    }

    public removeSelectedFile(atIndex: number) {
        this.setState(prevState => {
            const nextSelectedFiles = [...prevState.modelListFileIsSelected];
            nextSelectedFiles.splice(atIndex, 1);

            return {
                ...prevState,
                modelListFileIsSelected: nextSelectedFiles
            };
        });
    }

    render() {
        var { isReLoadFile, folderSelectedCode, folderSelectedName, folderSelectedFullPath, metadataGroup, strictMode } = this.state;
        const { roles }: IUserContext = this.context;

        return (
            <div>
                <div className="row row-file-explorer" >
                    <div className="col-3 sub-left" >
                        <Row>
                            <Col>
                                {/* <div className="d-inline float-right">
                                    <Button color="info" outline onClick={event => { this.onSelectFolder("All", "", "All", "") }} className="btn-reset-folder" style={{ fontFamily: 'sans-serif' }} >All</Button>
                                </div>
                                <ButtonGroup>
                                    <Button outline color="primary" className="btn-file-explorer" onClick={() => this.switchPage("File")}><FaFile style={{ marginBottom: '4px', height: 15 }} /> File Explorer</Button>{' '}&nbsp;
                                    <Button outline color="primary" className="btn-boxid" onClick={() => this.switchPage("Box")}><FaFile style={{ marginBottom: '4px', height: 15 }} /> Box ID</Button>{'  '}&nbsp;
                                    <Button outline color="primary" className="btn-boxid" onClick={() => this.switchPage("Relate")} >Relation</Button>
                                </ButtonGroup> */}
                                {this.state.switchPage === "File" &&
                                    <FoldersComponent
                                        valueCodeAndName={this.state.valueCodeAndName}
                                        resetReloadFolder={this.resetReloadFolder.bind(this)}
                                        reloadFolder={this.state.reloadFolder}
                                        oncheckRefresh={this.oncheckRefresh.bind(this)}
                                        onSelectFolder={this.onSelectFolder.bind(this)}
                                    />
                                }
                                {this.state.switchPage === "Box" &&
                                    <BoxIDComponent
                                        onSelectFolder={this.onSelectFolder.bind(this)}
                                    />
                                }
                            </Col>
                        </Row>
                    </div>
                    <div className="col-9 sub-right">
                        <div className="">
                            <div className="row" style={{ width: "101%" }}>
                                <div className="col-1">
                                    <Button outline color="info" className="btn-boxid" onClick={() => this.oncheckRefresh()} ><FaSync style={{ marginBottom: '4px', height: 15 }} />&nbsp;Refresh</Button>
                                </div>
                                <div className="col-11" style={{ textAlignLast: "right" }}>
                                    <UploadFileComponent
                                        // disabled={this.state.disabledUpload}
                                        disabled={this.props.disabledUpload}
                                        reloading={this.reloading.bind(this)}
                                        onUploadSuccess={this.onUploadSuccess.bind(this)}
                                        isReLoadFile={isReLoadFile}
                                        folderSelectedFullPath={folderSelectedFullPath}
                                        folderSelectedCode={folderSelectedCode}
                                        folderSelectedName={folderSelectedName}
                                        metadataGroup={metadataGroup}
                                    />
                                    <ButtonCopyFileComponent
                                        // disabled={(strictMode) ? !(roles.includes('02') || roles.includes('05')) : false}
                                        // disabled={this.state.disabledCopy}
                                        disabled={this.props.disabledCopy}
                                        getValueFileList={this.getValueFileList}
                                        onEditSuccess={this.onEditSuccess.bind(this)}
                                        reloading={this.reloading.bind(this)}
                                        folderSelectedCode={folderSelectedCode}
                                        GetDataSelectFileToButton={this.GetDataSelectFileToButton.bind(this)} // Deprecated (messy, verbose)
                                        selectedFiles={this.state.modelListFileIsSelected}
                                        onClickSaveBt={this.onClickSaveBt.bind(this)}
                                        btClick={this.state.btClick}
                                        forceRerender={this.forceRerender.bind(this)}
                                        handleChangeSelectedFiles={this.handleChangeSelectedFiles}
                                        handleCreatableSelectChange={this.handleCreatableSelectChange}
                                        onMetadataChange={this.onMetadataChange}
                                        addMetadata={this.addMetadata}
                                        removeMetadata={this.removeMetadata}
                                        setFileExplorerState={this.setState.bind(this)}
                                        changeSelectedFiles={this.changeSelectedFiles}
                                        removeSelectedFile={this.removeSelectedFile}
                                    />
                                    <ButtonEditFileV2Component
                                        // disabled={(strictMode) ? !(roles.includes('02')) : false}
                                        // disabled={this.state.disabledEdit}
                                        disabledEdit={this.props.disabledEdit}
                                        disabledMoveLink={this.props.disabledMoveLink}
                                        disabledUpdateBoxId={this.props.disabledUpdateBoxId}
                                        resetGetValueEdit={this.resetGetValueEdit.bind(this)}
                                        getValueEdit={this.state.getValueEdit}
                                        getValueFileList={this.getValueFileList}
                                        onEditSuccess={this.onEditSuccess.bind(this)}
                                        reloading={this.reloading.bind(this)}
                                        folderSelectedCode={folderSelectedCode}
                                        GetDataSelectFileToButton={this.GetDataSelectFileToButton.bind(this)}
                                        onClickSaveBt={this.onClickSaveBt.bind(this)}
                                        btClick={this.state.btClick}
                                        forceRerender={this.forceRerender.bind(this)}
                                    />
                                    <ButtonDeleteFileComponent
                                        // disabled={(strictMode) ? !(roles.includes('02') || roles.includes('04')) : false}
                                        // disabled={this.state.disabledDelete}
                                        disabled={this.props.disabledDelete}
                                        reloading={this.reloading.bind(this)}
                                        folderSelectedCode={folderSelectedCode}
                                        getValueFileList={this.getValueFileList}
                                        GetDataSelectFileToButton={this.GetDataSelectFileToButton.bind(this)}
                                        isReLoadFile={isReLoadFile}
                                        removeSelectedFile={this.removeSelectedFile}
                                        selectedFiles={this.state.modelListFileIsSelected}
                                    />
                                </div>
                            </div>
                            <div className='trapezoid' style={{ width: 'auto', display: 'inline-block', marginLeft: '0px' }}>
                                <span style={{ fontFamily: 'sans-serif' }}>
                                    <img src={FolderImg} alt="file" style={{ width: "18px", marginRight: "3px" }} />&nbsp;
                                    {this.state.folderSelectedFullPath !== "" ? this.state.folderSelectedFullPath : "All"}
                                </span>
                            </div>
                        </div>
                        <div className="div-file-component-explorer">
                            <FilesComponent
                                resetCheckFile={this.resetCheckFile.bind(this)}
                                checkFile={this.state.checkFile}
                                resetoncheckRefresh={this.resetoncheckRefresh.bind(this)}
                                checkRefresh={this.state.checkRefresh}
                                isReLoadFile={isReLoadFile}
                                folderSelectedCode={folderSelectedCode}
                                GetDataSelectFile={this.GetDataSelectFile.bind(this)}
                                getValueFileList={this.getValueFileList}
                                strictMode={strictMode}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// export default FileExplorerComponent;
export default connect(
    (state: ApplicationState) => state.folderRole,
    FolderRoleStore.actionCreators
)(FileExplorerComponent);