import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as Model from '../models/Folder';

interface RequestFunctionAction {
    type: 'REQUEST_FOLDER';
    version: number;
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_FOLDER';
    model: Model.ResponseFolderMap;
    version: number;
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction

export const actionCreators = {
    requestFunction: (pageload: boolean, sid: string, companyCode: string, version: number, method: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.folderMap && pageload === true && method === "GET") {

            fetch(`/v1/folder/map?sid=` + sid + `&companyCode=` + companyCode, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                },
            })
                .then(response => {
                    return response.json() as Promise<Model.ResponseFolderMap>
                })
                .then(data => {
                    var bufMainFolder = data[0];
                    data[0] = data[1];
                    data[1] = bufMainFolder;
                    console.log(data);
                    dispatch({ type: 'RECEIVE_FOLDER', model: data, version: version });
                })
                .catch(error => {

                    dispatch({ type: 'REQUEST_FOLDER', version: version });
                });

        }

    }
};

const unloadedState: Model.FolderMapState = { isLoading: false, response: undefined, version: 0 };

export const reducer: Reducer<Model.FolderMapState> = (state: Model.FolderMapState | undefined, incomingAction: Action): Model.FolderMapState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_FOLDER':
            return {
                isLoading: false,
                version: action.version + 1
            };
        case 'RECEIVE_FOLDER':
            return {
                isLoading: true,
                response: action.model,
                version: action.version + 2
            };

        default: return state;
    }
};