import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Row, Col, Button } from 'react-bootstrap';
import { FaFileExcel } from 'react-icons/fa';
import readXlsxFile from 'read-excel-file';
import { SearchCriteria } from '../../../models/SearchCriteria';
import * as SearchCriteriaStore from '../../../store/SearchCriteria';
import Swal from 'sweetalert2';

interface SearchCriteriaImportExcelModalProps {
    isOpen: boolean;
    toggle: React.KeyboardEventHandler<any> | React.MouseEventHandler<any>;
    toggleIsLoading: () => void;
}

type Props =
    & SearchCriteriaImportExcelModalProps
    & SearchCriteriaStore.ActionCreators;

interface State {
    importedDataImport: SearchCriteria[];
}

class SearchCriteriaImportExcelModal extends React.Component<Props, State> {
    public inputExcelFileRef: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);

        this.state = {
            importedDataImport: []
        };

        this.inputExcelFileRef = React.createRef();

        this.handleForwardClickImportExcel = this.handleForwardClickImportExcel.bind(this);
        this.handleExcelImported = this.handleExcelImported.bind(this);
        this.handleUpdateBoxId = this.handleUpdateBoxId.bind(this);
        this.handleBeforeClose = this.handleBeforeClose.bind(this);
    }

    public handleForwardClickImportExcel(_event: React.MouseEvent<HTMLElement, MouseEvent>): void {
        this.inputExcelFileRef.current?.click();
    }

    public handleExcelImported(event: React.ChangeEvent<HTMLInputElement>): void {
        this.props.toggleIsLoading();

        const files = event.target.files;

        if (files !== null && files.length > 0) {
            readXlsxFile(files[0])
                .then(rows => {
                    if (rows.length > 800) {
                        Swal.fire({
                            title: 'Import Excel ไม่สำเร็จ',
                            text: 'ข้อมูล Excel จะต้องไม่เกิน 800 แถว',
                            icon: 'error',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'ยืนยัน',
                        });

                        this.props.toggleIsLoading();

                        return;
                    }

                    // Lookup array
                    const keys: (keyof SearchCriteria)[] = ['boxId', 'primaryIndex', 'companyCode'
                        , 'documentNo', 'documentType'
                        , 'year', 'postingDate', 'invoiceDate', 'referenceDoc'
                        , 'irmNo', 'irmYear', 'vatCode', 'user', 'fileName', 'imageDocumentType'
                        , 'id']
                    const lowerCaseKeys = keys.map(key => key.toLowerCase());

                    const mappedDataImport: SearchCriteria[] = [];

                    // Access each row
                    for (let i = 0; i < rows.length; i++) {
                        const row = rows[i];
                        const mappedRow: Partial<SearchCriteria> = {};

                        // Access each cell
                        if (row.length < lowerCaseKeys.length) {
                            for (let j = 0; j < row.length; j++) {
                                const cell = (row[j] === null) ? '' : String(row[j]);

                                // Validate header name first time loop
                                if (i === 0) {
                                    if (!lowerCaseKeys.includes(cell.toLowerCase())) {
                                        throw `Invalid header name ${cell}`;
                                    }
                                } else {
                                    if (j == 10) {
                                        mappedRow[keys[j]] = '' as any;
                                    }
                                    if (j >= 10) {
                                        mappedRow[keys[j + 1]] = cell as any;
                                    }
                                    else {
                                        mappedRow[keys[j]] = cell as any;
                                    }

                                }
                            }

                            //if (Object.keys(mappedRow).length > 0) {
                            //    mappedDataImport.push(mappedRow as SearchCriteria);
                            //}
                        }
                        else {
                            for (let j = 0; j < row.length; j++) {
                                const cell = (row[j] === null) ? '' : String(row[j]);

                                // Validate header name first time loop
                                if (i === 0) {
                                    if (!lowerCaseKeys.includes(cell.toLowerCase())) {
                                        throw `Invalid header name ${cell}`;
                                    }
                                } else {
                                    mappedRow[keys[j]] = cell as any;
                                }
                            }

                            //if (Object.keys(mappedRow).length > 0) {
                            //    mappedDataImport.push(mappedRow as SearchCriteria);
                            //}
                        }

                        if (Object.keys(mappedRow).length > 0) {
                            mappedDataImport.push(mappedRow as SearchCriteria);
                        }
                    }

                    this.setState({ importedDataImport: mappedDataImport });
                    setTimeout(() => this.props.toggleIsLoading(), 0);
                })
                .catch(error => alert(error));
        }
    }

    public handleUpdateBoxId(_event: React.MouseEvent<HTMLElement, MouseEvent>): void {
        const updateData: Pick<SearchCriteria, 'id' | 'boxId'>[] = (
            this.state.importedDataImport
                .filter(item => item.id !== '')
                .map(item => ({ id: item.id, boxId: item.boxId }))
        );

        this.props.requestUpdateBoxId({ method: 'POST', body: updateData }, (data, error) => {
            if (!error) {
                this.props.toggle(_event as any);
                Swal.fire({
                    title: 'แก้ไข Box ID สำเร็จ',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ตกลง',
                });
            } else {
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'แก้ไข Box ID ไม่สำเร็จ',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ยืนยัน',
                });
            }
        });
    }

    public hasValidDigits(boxId: string): number {
        switch (boxId.length) {
            case 15: {
                return 1
            }
            case 0: {
                return -1
            }
            default: {
                return 0
            }
        }
    }

    public hasId(id: string): boolean {
        return id.length > 0;
    }

    public HintColor(props: { color: 'success' | 'danger' | 'warning' | 'primary' | 'secondary', hint: string, isShow: boolean }) {
        return (
            <div className={`${(props.isShow) ? 'd-flex' : 'd-none'} justify-content-center align-items-center`} style={{ gap: '0.25rem' }}>
                <div
                    className={`bg-${props.color} rounded-circle`}
                    style={{ width: '15px', height: '15px' }}
                >
                </div>
                <span className={`text-${props.color}`}>{props.hint}</span>
            </div>
        );
    }

    public handleBeforeClose(event: any) {
        this.setState({ importedDataImport: [] });
        this.props.toggle(event);
    }

    public canUpdate(item?: { id: string, boxId: string }): boolean {
        if (item !== undefined) {
            return this.hasId(item.id) && this.hasValidDigits(item.boxId) === 1;
        }

        const importedDataImport = this.state.importedDataImport;
        const filteredImportedDataImport = importedDataImport.filter(item => !this.hasId(item.id) || this.hasValidDigits(item.boxId) === -1 || this.hasValidDigits(item.boxId) === 0);
        const canUpdate = filteredImportedDataImport.length === 0;

        return canUpdate;
    }

    public render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.handleBeforeClose}
                size="xl"
                style={{ maxWidth: '95vw' }}
                className="employee-font font-size-employee mw"
            >
                <ModalHeader toggle={this.handleBeforeClose as React.MouseEventHandler<any>}>Import File</ModalHeader>
                <ModalBody>
                    <input
                        ref={this.inputExcelFileRef}
                        type="file"
                        name="file"
                        id="fileImport"
                        style={{ display: "none" }}
                        onChange={this.handleExcelImported}
                        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                    <Row>
                        <Col>
                            <Button
                                className="employee-font"
                                variant="info"
                                size="sm"
                                onClick={this.handleForwardClickImportExcel}
                            >
                                <FaFileExcel />
                                &nbsp;Select File
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col className="d-flex justify-content-end" style={{ gap: '0.5rem' }}>
                            <this.HintColor color="warning" hint="Invalid" isShow={this.state.importedDataImport.length > 0} />
                            <this.HintColor color="danger" hint="Empty" isShow={this.state.importedDataImport.length > 0} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="responsive-table">
                                <table className="table custom-table table-hover">
                                    <thead>
                                        <tr style={{ width: '100%' }} className="text-center table-active thead-light font-weight-bold require-field">
                                            <th>Box ID</th>
                                            <th>Company Code</th>
                                            <th>Document No.</th>
                                            <th>Doc. Type</th>
                                            <th>Doc. Year</th>
                                            <th>Posting Date</th>
                                            <th>Invoice Date</th>
                                            <th>Reference Doc.</th>
                                            <th>IRM No.</th>
                                            <th>IRM Year</th>
                                            <th>VAT Code</th>
                                            <th>User</th>
                                            <th>File Name</th>
                                            <th>Image Document Type</th>
                                            <th>ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.importedDataImport.length > 0
                                            && this.state.importedDataImport.map((item, index) => {
                                                const value = this.hasValidDigits(item.boxId);
                                                const hasId = this.hasId(item.id);

                                                return (
                                                    <tr
                                                        key={(hasId) ? item.id : index + 1}
                                                        // className={`text-center ${hasId && 'bg-success text-white'}`}
                                                        className={`text-center text-white ${this.canUpdate({ id: item.id, boxId: item.boxId }) ? 'bg-success' : 'bg-danger'}`}
                                                    >
                                                        <td style={{ padding: '10px' }}>{item.boxId}</td>
                                                        <td style={{ padding: '10px' }}>{item.companyCode}</td>
                                                        <td style={{ padding: '10px' }}>{item.documentNo}</td>
                                                        <td style={{ padding: '10px' }}>{item.documentType}</td>
                                                        <td style={{ padding: '10px' }}>{item.year}</td>
                                                        <td style={{ padding: '10px' }}>{item.postingDate}</td>
                                                        <td style={{ padding: '10px' }}>{item.invoiceDate}</td>
                                                        <td style={{ padding: '10px' }}>{item.referenceDoc}</td>
                                                        <td style={{ padding: '10px' }}>{item.irmNo}</td>
                                                        <td style={{ padding: '10px' }}>{item.irmYear}</td>
                                                        <td style={{ padding: '10px' }}>{item.vatCode}</td>
                                                        <td style={{ padding: '10px' }}>{item.user}</td>
                                                        <td style={{ padding: '10px' }}>{item.fileName}</td>
                                                        <td style={{ padding: '10px' }}>{item.imageDocumentType}</td>
                                                        <td style={{ padding: '10px' }}>{item.id}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <this.HintColor color="success" hint="Only green highlight will be update." isShow={this.state.importedDataImport.length > 0} />
                    <Button
                        color="primary"
                        onClick={this.handleUpdateBoxId}
                        // disabled={this.state.importedDataImport.filter(item => item.id !== '').length === 0}
                        disabled={!this.canUpdate()}
                    >
                        Update Box ID
                    </Button>
                    <Button variant="danger" onClick={this.handleBeforeClose as React.MouseEventHandler<any>}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default connect(
    (_state, ownProps: SearchCriteriaImportExcelModalProps) => ownProps, { ...SearchCriteriaStore.actionCreators })(SearchCriteriaImportExcelModal as any);