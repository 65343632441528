import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as File from '../models/File';


interface RequestFunctionAction {
    type: 'REQUEST_FILE';
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_FILE';
    model: File.ResponseFile;

}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction

export const actionCreators = {
    requestFunction: (pageload: boolean, key: string, isSearch: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (isSearch && (key === null || key === "" || key === " ")) {
            dispatch({ type: 'REQUEST_FILE' });
        } else {
            if (appState && appState.file && pageload === true) {
                fetch(`/v1/dms/primary?key=` + key, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    },
                })
                    .then(response => {
                        return response.json() as Promise<File.ResponseFile>
                    })
                    .then(data => {
                        dispatch({ type: 'RECEIVE_FILE', model: data });
                    })
                    .catch(error => {

                    });

                dispatch({ type: 'REQUEST_FILE' });
            }
        }
    }
};

const unloadedState: File.FileState = { isLoading: false, response: undefined };

export const reducer: Reducer<File.FileState> = (state: File.FileState | undefined, incomingAction: Action): File.FileState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_FILE':
            return {
                isLoading: false
            };
        case 'RECEIVE_FILE':
            return {
                isLoading: true,
                response: action.model
            };

        default: return state;
    }
};