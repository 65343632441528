import React from 'react';
import { connect } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';
import { Col, Row, Card, Form, Button, Table } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { Input, Label } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';

import * as AuthorizeStore from '../../../store/Authorize';
import * as FolderAuthorizeStore from '../../../store/FolderAuthorizeUserMapping';
import { ApplicationState } from '../../../store';
import { AuthorizeStateStore, DisplayAuthorize } from '../../../models/Authorize';
import { FolderAuthorizeUserMappingStateStore } from '../../../models/FolderAuthorizeUserMapping';

interface OwnProps {
    authorize: AuthorizeStateStore;
    folderAuthUserMapping: FolderAuthorizeUserMappingStateStore;
}

type Props =
    & OwnProps
    & typeof AuthorizeStore.actionCreators
    & typeof FolderAuthorizeStore.actionCreators;

interface SearchTerms {
    username: string;
    bucketId: string;
    roleAccess: string[];
}

interface State {
    page: number;
    pageSize: number;
    pageBtn: number[];
    searchTerms: SearchTerms;
}

class UserFolderAuthorizeMapping extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            page: 1,
            pageSize: 25,
            pageBtn: [],
            searchTerms: {
                username: '',
                bucketId: '',
                roleAccess: []
            }
        }

        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.handleChangeSearchTerms = this.handleChangeSearchTerms.bind(this);
    }

    public componentDidMount(): void {
        this.props.requestGetAuthorize();
        this.props.requestGetFolderAuthUserMapping({ app_id: '*', username: '' });
    }

    public handleChangeSearchTerms(key: keyof Omit<SearchTerms, 'roleAccess'>) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            event.persist();

            this.setState({
                searchTerms: {
                    ...this.state.searchTerms,
                    [key]: event.target.value
                }
            });
        }
    }

    public handleChangeRole(newValue: DisplayAuthorize[], _metaAction: any): void {
        this.setState({
            searchTerms: {
                ...this.state.searchTerms,
                roleAccess: newValue.map(value => value.value)
            }
        });
    }

    public render(): React.ReactNode {
        const searchTerms = this.state.searchTerms;
        const { displayAuthorize } = this.props.authorize;
        const { isLoadingFAUM, statusFAUM, responseFAUM } = this.props.folderAuthUserMapping;

        return (
            <React.Fragment>
                <div>
                    <Row>
                        <Col>
                            <div className='trapezoid' style={{ width: 'auto', display: 'inline-block' }}>
                                <span className="employee-font font-size-employee" >
                                    User Folder Authorize Mapping
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="mat-box employee-font font-size-employee s">
                    <Row>
                        <Col xs={12}>
                            <div className="trapezoid trapezoid-purple" style={{ width: 'fit-content' }}>
                                <span>
                                    Search menu
                                </span>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <Card.Body className="mat-box shadow">
                                <Row>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Label>Username :</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={searchTerms.username}
                                                onChange={this.handleChangeSearchTerms('username')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group>
                                            <Form.Label>Folder ID :</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={searchTerms.bucketId}
                                                onChange={this.handleChangeSearchTerms('bucketId')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Group className="mb-3" controlId="roleAccess">
                                            <Form.Label>Role Access :</Form.Label>
                                            <CreatableSelect
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                isMulti
                                                isClearable
                                                onChange={this.handleChangeRole}
                                                options={displayAuthorize}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label className="invisible">Search</Form.Label>
                                            <Button variant="outline-success" className="d-block">
                                                <FaSearch />&nbsp;ค้นหา
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <div className='d-flex justify-content-start my-2 my-lg-0'>
                                <Form inline>
                                    <Label style={{ fontSize: '14px' }}>
                                        Show&nbsp;&nbsp;
                                    </Label>
                                    <Input
                                        type="select"
                                        name="select"
                                        bsSize="sm"
                                        value={this.state.pageSize}
                                    >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </Input >
                                    <Label style={{ fontSize: '14px' }}>
                                        &nbsp;&nbsp;entries
                                    </Label>
                                </Form>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className='d-flex justify-content-end my-2 my-lg-0'>
                                <Pagination
                                    count={this.state.pageBtn.length}
                                    shape="rounded"
                                    showFirstButton
                                    showLastButton
                                    page={this.state.page}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <Table className="table-bordered align-middle employee-font font-size-employee" size='sm'>
                                <thead>
                                    <tr style={{ width: '100%' }} className="text-center table-active thead-light">
                                        <th className="td-menu" style={{ width: '7%', top: -1, position: 'sticky', zIndex: 1 }} ></th>
                                        <th style={{ width: '30%', position: 'sticky', top: -1, zIndex: 1 }} className="td-id">Username</th>
                                        <th style={{ width: '30%', position: 'sticky', top: -1, zIndex: 1 }} className="td-id">Folder</th>
                                        <th className="td-role-access" style={{ width: '30%', top: -1, position: 'sticky', zIndex: 1 }} >Role Access</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isLoadingFAUM
                                        && statusFAUM === 200
                                        && responseFAUM !== null
                                        && responseFAUM.total_count > 0
                                        && responseFAUM.result_list.map(folder => (
                                            <tr>
                                                <td></td>
                                                <td>{folder.username}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

// 0120 scgcement
// 7940 scieco
// 0170 eps
// 0550 sric

export default connect(
    (state: ApplicationState, ownProps) => ({ ...ownProps, authorize: state.authorize, folderAuthUserMapping: state.folderAuthorizeUserMapping }),
    { ...AuthorizeStore.actionCreators, ...FolderAuthorizeStore.actionCreators }
)(UserFolderAuthorizeMapping as any);