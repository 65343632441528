import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as Models from '../models/AppState';

interface AppStateAction {
    type: 'UPDATE_COMPANY';
    company: string;
}
interface AppStatePathAction {
    type: 'UPDATE_COMPANYSHORTNAME';
    CompanyShortName: Array<string>;
}

interface AppStateAvailableCompany {
    type: 'UPDATE_AVAILABLE_COMPANY';
    companies: Array<any>;
}

type KnownAction = AppStateAction | AppStatePathAction | AppStateAvailableCompany;

export const actionCreators = {
    updateCompany: (company: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState) {
            dispatch({ type: 'UPDATE_COMPANY', company: company });
        }
    },

    updateCompanyShortName: (CompanyShortName: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState) {
            dispatch({ type: 'UPDATE_COMPANYSHORTNAME', CompanyShortName: CompanyShortName });
        }
    },

    updateAvailableCompany: (companies: Array<any>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState) {
            dispatch({ type: 'UPDATE_AVAILABLE_COMPANY', companies });
        }
    }
};

const unloadedState: Models.AppState = { company: undefined, CompanyShortName: undefined, availableCompanies: [] };

export const reducer: Reducer<Models.AppState> = (state: Models.AppState | undefined, incomingAction: Action): Models.AppState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'UPDATE_COMPANY':
            return {
                ...state,
                company: action.company,
                CompanyShortName: state.CompanyShortName
            };
        case 'UPDATE_COMPANYSHORTNAME':
            return {
                ...state,
                CompanyShortName: action.CompanyShortName,
                company: state.company
            };
        case 'UPDATE_AVAILABLE_COMPANY':
            return {
                ...state,
                availableCompanies: action.companies
            }
        default: return state;
    }
};