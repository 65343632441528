import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/FileManage';
import * as Models from '../../models/File';
import Swal from 'sweetalert2';
import * as Model from '../../models/Folder';
import { FaEdit } from "react-icons/fa";
import CreatableSelect from 'react-select/creatable';
import FoldersComponent from './FoldersComponent';
import OutsideClickFallback from '../common/hoc/OutsideClickFallback';
import { convertFullpathToFolderName } from '../../modules/common';
import DeleteButton from '../common/DeleteButton';
import F1ConnectForm, { F1ConnectFormValues } from '../common/F1ConnectForm';

interface Props {
    [key: string]: any;
}

interface State {
    folderSelectedFullPath: string;
    version: number;
    pageLoad: boolean;
    isLoadSuccess: boolean;
    isOpenModal: boolean;
    IsOpenDropdown: boolean;
    folderSelectedCode: string;
    folderSelectedName: string;
    folderName: string;
    bucket: any;
    boxId: any;
    groupId: any;
    subGroupId: any;
    relation: any;
    searchKey: any;
    mataData: any;
    listFolder: Array<Model.FolderMap>;
    fileSelected: Array<Models.File>;
    isShowFolders: boolean;
    isShowFolderMap: Map<number, boolean>;
}

const components = {
    DropdownIndicator: null,
};

class ButtonEditFileV2Component extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            version: 0,
            pageLoad: true,
            isLoadSuccess: false,
            isOpenModal: false,
            IsOpenDropdown: false,
            folderSelectedCode: "",
            folderSelectedName: "",
            folderSelectedFullPath: "",
            listFolder: [],
            fileSelected: [],
            folderName: '',
            bucket: '',
            boxId: '',
            groupId: '',
            subGroupId: '',
            relation: '',
            searchKey: '',
            mataData: '',
            isShowFolders: false,
            isShowFolderMap: new Map()
        }
        this.split_val = this.split_val.bind(this);
        this.removeSelectedFile = this.removeSelectedFile.bind(this);
        this.handleClickApplyAll = this.handleClickApplyAll.bind(this);
    }

    componentDidUpdate() {
        if (this.props.version > this.state.version) {
            if (this.props.version - this.state.version === 3 && this.props.btClick === "edit") {
                Swal.fire({
                    title: 'Success',
                    icon: 'success'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // this.props.onEditSuccess();
                        this.props.reloading("reset")
                        // this.toggleModalEdit();
                        // this.props.onClickSaveBt("");
                        this.props.forceRerender();
                    }
                })
            }
            this.setState({ version: this.props.version });

        }

        if (this.props.getValueEdit) {
            this.setState({ fileSelected: this.props.GetDataSelectFileToButton() })
            this.props.resetGetValueEdit("reset")
        }

    }

    OnEditClick = (e: any) => {
        this.setState({ pageLoad: true, isLoadSuccess: false });
        this.toggleModalEdit();
        this.props.getValueFileList("get")
    }

    setTextHeaderModal = () => {
        var txtHead = "Edit Files";
        return txtHead;
    }

    toggleModalEdit = () => {

        this.setState({
            isOpenModal: !this.state.isOpenModal
        });
    }

    ClickSelected = (path: string, code: string, name: string) => {
        this.setState({
            folderSelectedFullPath: path,
            folderSelectedCode: code,
            folderSelectedName: name
        });
    }

    private replaceDate(e: string) {

        var date = "";
        if (e !== null) {
            if (e.length >= 10) {
                var date = e.substring(0, 10);
            }
        }
        return date;
    }

    private split_val(data) {
        var DataSelectArray: any = []
        var Data: any = []
        var val = "";

        if (data !== null && data !== undefined && data !== "") {
            if (data.length === 1) {
                if (data[0].value !== undefined) {
                    let arr = {}
                    arr['value'] = data[0].value
                    arr['label'] = data[0].label
                    DataSelectArray.push(arr)

                } else {
                    val = data
                    for (var i = 0; i < val.length; i++) {
                        let data = {}
                        data['value'] = val[i]
                        data['label'] = val[i]
                        DataSelectArray.push(data)
                    }
                }

            } else {
                val = data.split("|")
                for (var i = 0; i < val.length; i++) {
                    let data = {}
                    data['value'] = val[i]
                    data['label'] = val[i]
                    DataSelectArray.push(data)
                }
            }


        }
        return DataSelectArray;
    }

    OnEditFilesClick = (e: any) => {
        this.setState({ pageLoad: true, isLoadSuccess: false });
        this.props.onClickSaveBt("edit")
        this.props.requestFunction(this.state.fileSelected, this.state.folderSelectedCode, this.state.version, "PUT");
        this.toggleModalEdit();
    }

    handleChangeData = (valueChange: any, actionMeta: any, checkRow: any, key: any) => {
        const { fileSelected } = this.state
        var onclickMass: any = fileSelected
        if (actionMeta === "relation") {
            var data: string = ""
            if (valueChange.length === 1) {
                data = valueChange[0].value
            } else if (valueChange.length > 1) {
                for (var i = 0; i < valueChange.length; i++) {
                    if (valueChange.length <= 1) {
                        data += valueChange[i].value
                    } else if (i >= valueChange.length - 1) {
                        data += valueChange[i].value
                    } else {
                        data += valueChange[i].value + "|"
                    }
                }
            }
            for (var i = 0; i < fileSelected.length; i++) {
                if (key === i) {
                    onclickMass[i].relation = data
                }
            }
            this.setState({ fileSelected: onclickMass })
        } else if (actionMeta === "indexString") {
            var data: string = ""
            if (valueChange.length === 1) {
                data = valueChange[0].value
            } else if (valueChange.length > 1) {
                for (var i = 0; i < valueChange.length; i++) {
                    if (valueChange.length <= 1) {
                        data += valueChange[i].value
                    } else if (i >= valueChange.length - 1) {
                        data += valueChange[i].value
                    } else {
                        data += valueChange[i].value + "|"
                    }
                }
            }
            for (var i = 0; i < fileSelected.length; i++) {
                if (key === i) {
                    onclickMass[i].index_string = data
                }
            }
            this.setState({ fileSelected: onclickMass })
        } else if (actionMeta === "metadata") {
            var data: string = ""
            if (valueChange.length === 1) {
                data = valueChange[0].value
            } else if (valueChange.length > 1) {
                for (var i = 0; i < valueChange.length; i++) {
                    if (valueChange.length <= 1) {
                        data += valueChange[i].value
                    } else if (i >= valueChange.length - 1) {
                        data += valueChange[i].value
                    } else {
                        data += valueChange[i].value + "|"
                    }
                }
            }
            for (var i = 0; i < fileSelected.length; i++) {
                if (key === i) {
                    onclickMass[i].metadata = data
                }
            }
            this.setState({ fileSelected: onclickMass })
        }
    }


    handleChange = (valueChange: any, actionMeta: any, checkRow: any) => {
        const { relation, searchKey, mataData } = this.state
        if (actionMeta === "relation") {
            var data: string = ""
            if (valueChange.length === 1) {
                data = valueChange
            } else if (valueChange.length > 1) {
                for (var i = 0; i < valueChange.length; i++) {
                    if (valueChange.length <= 1) {
                        data += valueChange[i].value
                    } else if (i >= valueChange.length - 1) {
                        data += valueChange[i].value
                    } else {
                        data += valueChange[i].value + "|"
                    }

                }
            }
            this.setState({ relation: data })

        } else if (actionMeta === "indexString") {
            var data: string = ""
            if (valueChange.length === 1) {
                data = valueChange
            } else if (valueChange.length > 1) {
                for (var i = 0; i < valueChange.length; i++) {
                    if (valueChange.length <= 1) {
                        data += valueChange[i].value
                    } else if (i >= valueChange.length - 1) {
                        data += valueChange[i].value
                    } else {
                        data += valueChange[i].value + "|"
                    }

                }
            }

            this.setState({ searchKey: data })

        } else if (actionMeta === "metadata") {
            var data: string = ""
            if (valueChange.length === 1) {
                data = valueChange
            } else if (valueChange.length > 1) {
                for (var i = 0; i < valueChange.length; i++) {
                    if (valueChange.length <= 1) {
                        data += valueChange[i].value
                    } else if (i >= valueChange.length - 1) {
                        data += valueChange[i].value
                    } else {
                        data += valueChange[i].value + "|"
                    }

                }
            }
            this.setState({ mataData: data })

        } else if (actionMeta === "") {
            var data: string = ""
            if (valueChange.length === 1) {
                data = valueChange
            } else if (valueChange.length > 1) {
                for (var i = 0; i < valueChange.length; i++) {
                    if (valueChange.length <= 1) {
                        data += valueChange[i].value
                    } else if (i >= valueChange.length - 1) {
                        data += valueChange[i].value
                    } else {
                        data += valueChange[i].value + "|"
                    }

                }
            }
            return data
        }
    };

    public toggleShowFolders = (key: number, value: boolean): void => {
        this.setState(prevState => {
            const nextIsShowFolderMap = new Map(prevState.isShowFolderMap);
            return { isShowFolderMap: nextIsShowFolderMap.set(key, value) };
        });
    }

    public changeState = (key: keyof Pick<State, 'bucket'>, value: string) => {
        this.setState({ [key]: value });
    }

    addTextvalue(e, check) {
        var Group: string = ""
        Group = e.target.value;
        console.log(Group)
        if (check === "bucket") {
            this.setState({
                bucket: Group
            })
        } else if (check === "boxId") {
            this.setState({
                boxId: Group
            })
        } else if (check === "groupId") {
            this.setState({
                groupId: Group
            })
        } else if (check === "subGroupId") {
            this.setState({
                subGroupId: Group
            })
        }
    }

    setValue(mass) {
        const { fileSelected } = this.state
        if (this.props.GetDataSelectFileToButton().length > 0) {
            var onclickMass: any = fileSelected
            if (mass === "bucket") {
                for (var i = 0; i < fileSelected.length; i++) {
                    onclickMass[i].full_path = this.state.folderName
                    onclickMass[i].bucket = this.state.bucket
                }

            } else if (mass === "boxId") {
                for (var i = 0; i < fileSelected.length; i++) {
                    onclickMass[i].box_id = this.state.boxId
                }

            } else if (mass === "groupId") {
                for (var i = 0; i < fileSelected.length; i++) {
                    onclickMass[i].group_id = this.state.groupId
                }

            } else if (mass === "subGroupId") {
                for (var i = 0; i < fileSelected.length; i++) {
                    onclickMass[i].sub_group_id = this.state.subGroupId
                }

            } else if (mass === "relation") {
                for (var i = 0; i < fileSelected.length; i++) {
                    onclickMass[i].relation = this.state.relation
                }

            } else if (mass === "indexString") {
                for (var i = 0; i < fileSelected.length; i++) {
                    onclickMass[i].index_string = this.state.searchKey
                }

            } else if (mass === "metadata") {
                for (var i = 0; i < fileSelected.length; i++) {
                    onclickMass[i].metadata = this.state.mataData
                }

            }
            this.setState({ fileSelected: onclickMass })
        }
    }

    // Deprecated (verbose)
    // addDatavalue(e, key, check) {
    //     const { fileSelected } = this.state
    //     var Group: string = ""
    //     Group = e.target.value;
    //     console.log(Group)
    //     var allData: any = fileSelected
    //     if (check === "bucket") {
    //         allData[key].bucket = Group
    //     } else if (check === "fileName") {
    //         allData[key].file_name = Group
    //     } else if (check === "boxId") {
    //         allData[key].box_id = Group
    //     } else if (check === "groupId") {
    //         allData[key].group_id = Group
    //     } else if (check === "subGroupId") {
    //         allData[key].sub_group_id = Group
    //     }
    //     this.setState({ fileSelected: allData })
    // }

    public addDatavalue(event: any, index: number, check: keyof Models.File) {
        const value = event.target.value;

        const nextData: any = [...this.state.fileSelected];
        nextData[index][check] = value;

        this.setState({ fileSelected: nextData });
    }

    public removeSelectedFile(atIndex: number) {
        if (this.state.fileSelected.length === 1) {
            this.toggleModalEdit();
        }

        this.setState(prevState => {
            const nextSelectedFiles = [...prevState.fileSelected];
            nextSelectedFiles.splice(atIndex, 1);

            return {
                ...prevState,
                fileSelected: nextSelectedFiles
            };
        });
    }

    public handleClickApplyAll(formValues: F1ConnectFormValues) {
        const keys: (keyof F1ConnectFormValues)[] = ['companyCode', 'documentType', 'fiscalYear', 'documentNo'];

        const prepareValue = keys
            .map(key => formValues[key])
            .filter(value => (value as string).length !== 0)
        const newGroupId = prepareValue.join('');
        const newSearchKey = prepareValue.join('|');

        this.setState(prevState => {
            const nextSelectedFiles = [...prevState.fileSelected].map(file => {
                file.group_id = newGroupId;

                if (newGroupId.length > 0) {
                    file.index_string = file.index_string + '|' + newSearchKey + '|' + newGroupId;
                }

                return file;
            });

            return {
                ...prevState,
                fileSelected: nextSelectedFiles
            };
        });
    }

    render() {
        const { disabledEdit, disabledMoveLink, disabledUpdateBoxId } = this.props;
        let isDisableButton = false;
        if (this.props.folderSelectedCode.includes("S-")) {
            isDisableButton = true;
        }

        // Css style
        const border_highlight_allow_edit = {
            fontSize: "16px",
            border: 'solid 3px #59CE8F'
        }

        return (
            <div style={{ display: "inline", paddingLeft: "5px" }}>
                {/* {!this.props.disabled ?
                    <label className="btn-edit-file " htmlFor="btnEditFile" ><FaEdit style={{ marginBottom: '4px' }} />&nbsp;Edit</label> :
                    <label className="btn-edit-file-disable" ><FaEdit style={{ marginBottom: '4px' }} />&nbsp;Edit</label>
                } */}
                <Button
                    disabled={disabledEdit && disabledMoveLink}
                    outline
                    className={(!disabledEdit || !disabledMoveLink) ? 'btn-edit-file' : 'btn-edit-file-disable'}
                    onClick={this.OnEditClick}
                >
                    <FaEdit style={{ marginBottom: '4px', marginRight: '4px' }} />
                    Edit/Move Link
                </Button>

                <Button id="btnEditFile" className="btn-edit-file-hide" color="info" size="sm" style={{ borderRadius: "5px" }} onClick={this.OnEditClick} >Edit</Button>
                <Modal isOpen={this.state.isOpenModal} className="dms-modal">
                    <ModalHeader style={{ fontFamily: "sans-serif" }} toggle={this.toggleModalEdit}> Edit Files </ModalHeader>
                    <ModalBody>
                        <div className="div-file-upload-list">
                            <Row>
                                <Col>
                                    <div className='trapezoid trapezoid-purple' style={{ width: 'auto', display: 'inline-block', marginLeft: '7px' }}>
                                        <span>
                                            Total Files : {this.props.GetDataSelectFileToButton().length}
                                        </span>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12}>
                                    <Row>
                                        <Col xs={6}>
                                            <Row className="align-items-center" style={{ gap: '6px 0' }}>
                                                <Col xs={3} className="text-right font-weight-bold">
                                                    Folder
                                                </Col>
                                                <Col xs={6}>
                                                    <OutsideClickFallback handleClickOutside={() => this.setState({ isShowFolders: false })}>
                                                        <div style={{ width: "100%", position: 'relative' }}>
                                                            <Input
                                                                type="text"
                                                                disabled={disabledMoveLink}
                                                                style={{ width: "100%" }}
                                                                // value={this.state.bucket}
                                                                value={convertFullpathToFolderName(this.state.folderName)}
                                                                placeholder="Select folder"
                                                                onKeyPress={(event) => event.preventDefault()} // Prevent user typing
                                                                // onChange={(e) => this.addTextvalue(e, "bucket")}
                                                                onFocus={() => this.setState({ isShowFolders: true })}
                                                            />
                                                            {
                                                                this.state.isShowFolders
                                                                && (
                                                                    <div
                                                                        className="bg-light rounded-sm shadow-lg"
                                                                        style={{
                                                                            width: '100%',
                                                                            maxHeight: '300px',
                                                                            overflow: 'auto',
                                                                            position: 'absolute',
                                                                            left: 0,
                                                                            zIndex: 99999
                                                                        }}
                                                                    >
                                                                        <FoldersComponent
                                                                            onSelectFolder={(fullPath: string, nodeCode: string, _nodeName: string, _metadata: string) => {
                                                                                if (nodeCode !== '') {
                                                                                    this.setState({ bucket: nodeCode, folderName: fullPath });
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </OutsideClickFallback>
                                                </Col>
                                                <Col xs={3}>
                                                    <Button
                                                        outline
                                                        disabled={disabledMoveLink}
                                                        color="success"
                                                        onClick={() => this.setValue("bucket")}
                                                    >
                                                        Mass Maintain
                                                    </Button>
                                                </Col>

                                                <Col xs={3} className="text-right font-weight-bold">
                                                    Group Id
                                                </Col>
                                                <Col xs={6}>
                                                    <Input
                                                        readOnly={disabledEdit}
                                                        type="text"
                                                        onChange={(e) => this.addTextvalue(e, "groupId")}
                                                    />
                                                </Col>
                                                <Col xs={3}>
                                                    <Button
                                                        disabled={disabledEdit}
                                                        outline
                                                        color="success"
                                                        onClick={() => this.setValue("groupId")}
                                                    >
                                                        Mass Maintain
                                                    </Button>
                                                </Col>

                                                <Col xs={3} className="text-right font-weight-bold">
                                                    Metadata String
                                                </Col>
                                                <Col xs={6} className="w-100 position-relative">
                                                    <CreatableSelect
                                                        isDisabled={disabledEdit}
                                                        components={components}
                                                        isMulti
                                                        isClearable
                                                        onChange={(e) => this.handleChange(e, "metadata", "")}
                                                    />
                                                    <span style={{ fontSize: "12px", position: 'absolute' }}>
                                                        หมายเหตุ: <span style={{ fontSize: "12px", color: "red" }}>กรุณากรองข้อความเเละกดปุ่ม Enter</span>
                                                    </span>
                                                </Col>
                                                <Col xs={3}>
                                                    <Button
                                                        disabled={disabledEdit}
                                                        outline
                                                        color="success"
                                                        onClick={() => this.setValue("metadata")}
                                                    >
                                                        Mass Maintain
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col xs={6} className="border border-darken-1 rounded-lg py-3">
                                            <F1ConnectForm
                                                folderSelectedCode={this.props.folderSelectedCode}
                                                handleClickApplyAll={this.handleClickApplyAll}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="mt-1 mb-3" style={{ gap: '1rem 0' }}>

                                {/* <Col xs="2" style={{ alignSelf: "flex-end", textAlignLast: "right", fontWeight: "bold" }}>
                                    Box Id
                                </Col>
                                <Col xs="3" style={{ alignSelf: "flex-end", marginBottom: -7, marginLeft: '-10px' }}>
                                    <div style={{ width: "100%" }}>
                                        <Input
                                            readOnly={disabledUpdateBoxId}
                                            type="text"
                                            style={{ width: "100%" }}
                                            onChange={(e) => this.addTextvalue(e, "boxId")}
                                        />
                                    </div>
                                </Col>
                                <Col xs="1" style={{ alignSelf: "flex-end", marginBottom: -7 }}>
                                    <Button
                                        disabled={disabledUpdateBoxId}
                                        outline
                                        color="success"
                                        onClick={() => this.setValue("boxId")}
                                        style={{ width: '130px' }}
                                    >
                                        Mass Maintain
                                    </Button>
                                </Col> */}
                                {/* <Col xs="2" style={{ textAlignLast: "right", fontWeight: "bold" }}>
                                    Sub Group Id
                                </Col>
                                <Col xs="3">
                                    <div style={{ width: "100%" }}>
                                        <Input
                                            readOnly={disabledEdit}
                                            type="text"
                                            style={{ width: "100%" }}
                                            onChange={(e) => this.addTextvalue(e, "subGroupId")}
                                        />
                                    </div>
                                </Col>
                                <Col xs="1">
                                    <Button
                                        disabled={disabledEdit}
                                        outline
                                        color="success"
                                        style={{ width: '130px' }}
                                        onClick={() => this.setValue("subGroupId")}
                                    >
                                        Mass Maintain
                                    </Button>
                                </Col> */}
                            </Row>
                            {/* <Row className="mt-3">
                                <Col xs="2" style={{ alignSelf: "flex-end", textAlignLast: "right", fontWeight: "bold" }}>
                                    Relation
                                </Col>
                                <Col xs="3" style={{ alignSelf: "flex-end", marginBottom: -7, marginLeft: '-10px' }}>
                                    <div style={{ width: "100%" }}>
                                        <CreatableSelect
                                            isDisabled
                                            components={components}
                                            isMulti
                                            isClearable
                                            onChange={(e) => this.handleChange(e, "relation", "")}
                                        />
                                    </div>
                                </Col>
                                <Col xs="1" style={{ alignSelf: "flex-end", marginBottom: -7 }}>
                                    <Button
                                        disabled
                                        outline
                                        color="success"
                                        style={{ width: '130px' }}
                                        onClick={() => this.setValue("relation")}
                                    >
                                        Mass Maintain
                                    </Button>
                                </Col>
                                <Col xs="2" style={{ alignSelf: "flex-end", textAlignLast: "right", fontWeight: "bold" }}>
                                    Search Key
                                </Col>
                                <Col xs="3" style={{ alignSelf: "flex-end", marginBottom: -7, marginLeft: '-10px' }}>
                                    <div style={{ width: "100%" }}>
                                        <CreatableSelect
                                            components={components}
                                            isMulti
                                            isClearable
                                            onChange={(e) => this.handleChange(e, "indexString", "")}
                                        />
                                    </div>
                                </Col>
                                <Col xs="1" style={{ alignSelf: "flex-end", marginBottom: -7 }}>
                                    <Button
                                        outline
                                        color="success"
                                        style={{ width: '130px' }}
                                        onClick={() => this.setValue("indexString")}
                                    >
                                        Mass Maintain
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="2" style={{ alignSelf: "flex-end", textAlignLast: "right", fontWeight: "bold" }}></Col>
                                <Col xs="3" style={{ alignSelf: "flex-end", marginBottom: -7, marginLeft: '-10px', marginTop: '7px' }}>
                                    <span style={{ fontSize: "12px" }}>หมายเหตุ: </span><span style={{ fontSize: "12px", color: "red" }}>กรุณากรองข้อความเเละกดปุ่ม Enter</span>
                                </Col>
                                <Col xs="1" style={{ alignSelf: "flex-end", marginBottom: -7 }}></Col>
                                <Col xs="2" style={{ alignSelf: "flex-end", textAlignLast: "right", fontWeight: "bold" }}></Col>
                                <Col xs="3" style={{ alignSelf: "flex-end", marginBottom: -7, marginLeft: '-10px', marginTop: '7px' }}>
                                    <span style={{ fontSize: "12px" }}>หมายเหตุ: </span><span style={{ fontSize: "12px", color: "red" }}>กรุณากรองข้อความเเละกดปุ่ม Enter</span>
                                </Col>
                                <Col xs="1" style={{ alignSelf: "flex-end", marginBottom: -7 }}></Col>
                            </Row> */}
                            <Row className="mt-4">
                                <Col>
                                    <div style={{ width: "99%", marginLeft: "0.5%", marginRight: "0.5%" }} className="div-main-style" >
                                        <table className='table theme-table table-hover table-responsive' >
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th style={{ minWidth: "300px" }}>Folder</th>
                                                    <th style={{ minWidth: "200px" }}>PMI</th>
                                                    <th style={{ minWidth: "300px" }}>File Name</th>
                                                    <th style={{ minWidth: "50px" }}>Detail</th>
                                                    <th style={{ minWidth: "155px" }}>Box Id</th>
                                                    <th style={{ minWidth: "200px" }}>Group Id</th>
                                                    <th style={{ minWidth: "140px" }}>Sub Group Id</th>
                                                    <th style={{ minWidth: "150px" }}>Relation</th>
                                                    <th style={{ minWidth: "250px" }}>Search Key</th>
                                                    <th style={{ minWidth: "500px" }}>Metadata String</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.fileSelected.length > 0 && this.state.fileSelected.map((item: Models.File, index) =>
                                                    <tr key={item.id}>
                                                        <td className="text-center">
                                                            <DeleteButton onClick={(_event) => this.removeSelectedFile(index)} />
                                                        </td>
                                                        <td style={(!disabledMoveLink) ? border_highlight_allow_edit : {}}>
                                                            {/*{console.log("[Debug 1] split full_path = ", item.full_path.split('>')[item.full_path.split('>').length - 2])}*/}
                                                            <OutsideClickFallback handleClickOutside={() => this.toggleShowFolders(index, false)}>
                                                                <div style={{ width: "100%", position: 'relative' }}>
                                                                    <Input placeholder="Folder"
                                                                        disabled={disabledMoveLink}
                                                                        key={index} type="text"
                                                                        Value={convertFullpathToFolderName(item.full_path)}
                                                                        // Value={item.bucket}
                                                                        onKeyPress={(event) => event.preventDefault()} // Prevent user typing
                                                                        // onChange={(e) => this.addDatavalue(e, index, "bucket")}
                                                                        onFocus={() => this.toggleShowFolders(index, true)}
                                                                    />
                                                                    {
                                                                        this.state.isShowFolderMap.get(index)
                                                                        && (
                                                                            <div
                                                                                className="bg-light rounded-sm shadow-lg"
                                                                                style={{
                                                                                    maxWidth: '300px',
                                                                                    maxHeight: '300px',
                                                                                    position: 'absolute',
                                                                                    overflow: 'auto',
                                                                                    left: 0,
                                                                                    zIndex: 99999
                                                                                }}
                                                                            >
                                                                                <FoldersComponent
                                                                                    onSelectFolder={(fullPath: string, nodeCode: string, _nodeName: string, _metadata: string) => {
                                                                                        if (nodeCode !== '') {
                                                                                            this.addDatavalue({ target: { value: fullPath } }, index, "full_path");
                                                                                            this.addDatavalue({ target: { value: nodeCode } }, index, "bucket");
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </OutsideClickFallback>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                key={index} type="text"
                                                                Value={item.primary_index}
                                                                readOnly
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                readOnly
                                                                placeholder="File Name"
                                                                key={index} type="text"
                                                                // value={item.file_name} onChange={(e) => this.addDatavalue(e, index, "fileName")}
                                                                value={item.file_name} onChange={(e) => this.addDatavalue(e, index, "file_name")}
                                                            />
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                readOnly={disabledUpdateBoxId}
                                                                placeholder="Box Id"
                                                                key={index} type="text"
                                                                // Value={item.box_id} onChange={(e) => this.addDatavalue(e, index, "boxId")}
                                                                Value={item.box_id} onChange={(e) => this.addDatavalue(e, index, "box_id")}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                readOnly={disabledEdit}
                                                                placeholder="Group Id"
                                                                key={index} type="text"
                                                                // Value={item.group_id} onChange={(e) => this.addDatavalue(e, index, "groupId")}
                                                                Value={item.group_id} onChange={(e) => this.addDatavalue(e, index, "group_id")}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                readOnly={disabledEdit}
                                                                placeholder="Sub Group Id"
                                                                type="text" key={index}
                                                                // Value={item.sub_group_id} onChange={(e) => this.addDatavalue(e, index, "subGroupId")}
                                                                Value={item.sub_group_id} onChange={(e) => this.addDatavalue(e, index, "sub_group_id")}
                                                            />
                                                        </td>
                                                        <td style={{ fontSize: "16px" }}>
                                                            <CreatableSelect
                                                                isDisabled
                                                                components={components}
                                                                isMulti
                                                                isClearable
                                                                defaultValue={this.split_val(item.relation)}
                                                                onChange={(e, a) => this.handleChangeData(e, "relation", a, index)}
                                                                value={this.split_val(item.relation)}
                                                            />
                                                        </td>
                                                        <td style={(!disabledEdit) ? border_highlight_allow_edit : {}}>
                                                            <CreatableSelect
                                                                isDisabled={disabledEdit}
                                                                components={components}
                                                                isMulti
                                                                isClearable
                                                                defaultValue={this.split_val(item.index_string)}
                                                                onChange={(e, a) => this.handleChangeData(e, "indexString", a, index)}
                                                                value={this.split_val(item.index_string)}
                                                            />
                                                        </td>
                                                        <td style={(!disabledEdit) ? border_highlight_allow_edit : {}}>
                                                            <CreatableSelect
                                                                isDisabled={disabledEdit}
                                                                components={components}
                                                                isMulti
                                                                isClearable
                                                                defaultValue={this.split_val(item.metadata)}
                                                                onChange={(e, a) => this.handleChangeData(e, "metadata", a, index)}
                                                                value={this.split_val(item.metadata)}
                                                            />
                                                        </td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" style={{ fontFamily: "sans-serif" }} onClick={this.OnEditFilesClick} >Save</Button>
                        <Button color="secondary" style={{ fontFamily: "sans-serif" }} onClick={this.toggleModalEdit}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );

    }
}

export default connect((state: ApplicationState) => state.fileEdit,
    Stores.actionCreators
)(ButtonEditFileV2Component);