import * as React from 'react';
import { connect } from 'react-redux';

import CreatableSelect from 'react-select/creatable';

import { ApplicationState } from '../../store';

import * as Stores from '../../store/EmployeeMaster';
import * as StoresImport from '../../store/ImportExcel';
import * as Models from '../../models/Employee';
import * as StoresRoleAccess from '../../store/RoleAccess';
import * as ModelsRoleAccess from '../../models/RoleAccess';
import * as StoresCompanyMaster from '../../store/CompanyMaster';
import * as ModelsCompanyMaster from '../../models/CompanyMaster';

import Pagination from '@material-ui/lab/Pagination';
import { Button, Container, Table, Accordion } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Row, Col, Form, FormGroup } from 'reactstrap';
import Swal from 'sweetalert2';
import { Spinner } from 'reactstrap';
import { FaPlusCircle, FaEdit, FaTimesCircle, FaUpload, FaFileDownload, FaFileExport, FaFileExcel, FaArrowRight } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import { Role } from '../../models/RoleAccess';

import EmployeeMasterSearchComponent from '../Employee/EmployeeMasterSearchComponent'

type Store = Models.EmployeeState & typeof Stores.actionCreators | Models.ImportEmployeeState & typeof StoresImport.actionCreators | ModelsRoleAccess.RoleAccessState & typeof StoresRoleAccess.actionCreators | ModelsCompanyMaster.CompanyMasterState & typeof StoresCompanyMaster.actionCreators;
const style = { color: "red", fontSize: "1.1em", marginBottom: '4px' }

interface State {
    openModalImport: boolean
    excelListEmployee: any
    openModalSelectCom: boolean
    selectCompany: string
    ExportTemplate: string
    roleAccess: string
    userName: string
    valueSelectSearch: string
    page: number
    pageSize: number
    pageBtn: any
    valueSelectEdit: any
    valueRole: any
    valueSelectRole: any
    roleList: Role[]
    selectedRoles: Role[]
    valueSelect: any
    multiValue: any
    version: number
    modelEmployee: any
    listModelEmployee: any
    isOpenModal: boolean
    manageMode: string
    page_excel: number
    page_size_excel: number
    pageBtn_excel: any
    excelListEmployeeBind: any
}

class EmployeeMasterComponent extends React.PureComponent<any, State, Store> {
    private readonly inputOpenFileRef: React.RefObject<HTMLInputElement>
    constructor(props: any) {
        super(props);

        this.state = {
            version: 0,
            modelEmployee: [],
            listModelEmployee: [],
            isOpenModal: false,
            manageMode: "Select",
            multiValue: [],
            valueSelectEdit: [],
            valueSelect: [],
            valueSelectRole: [], // deprecated
            roleList: [],
            selectedRoles: [],
            valueRole: [],
            pageBtn: [],
            pageSize: 25,
            page: 1,
            valueSelectSearch: "",
            userName: "",
            roleAccess: "",
            ExportTemplate: "",
            selectCompany: "",
            openModalSelectCom: false,
            excelListEmployee: undefined,
            openModalImport: false,
            page_excel: 1,
            page_size_excel: 25,
            pageBtn_excel: [],
            excelListEmployeeBind: undefined
        }
        this.inputOpenFileRef = React.createRef()

    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.setState({ manageMode: "Select" })
        this.props.requestFunction(this.state.version, "GET", this.state.modelEmployee, false, null, "", "", "", 1, 25);
        this.props.requestFunctionRole(true, this.state.version, "GET", { role_access_code: "" });
        this.props.requestFunctionCompanyMaster(true, "GET", "", "active", "");
        this.requestRoleAccess();
    }

    private async requestRoleAccess() {
        try {
            const response = await fetch('/v1/authorize');
            const data = await response.json();

            this.setState({ roleList: data.resultList || [] });
        } catch (error) {
            console.log('cannot fetch roles');
        }
    }

    toggleModal = () => {
        this.setState({ isOpenModal: !this.state.isOpenModal })
    }

    addClickAndEdit = () => {
        const { valueRole, manageMode } = this.state
        const { valueSelectSearch, userName, roleAccess } = this.state

        let node = ""
        let nameId = ""
        let checkCompany = ""

        if (manageMode === "Add") {
            node = "checkCompany"
            nameId = "userId"
        } else if (manageMode === "Edit") {
            node = "checkCompanyEdit"
            nameId = "userIdEdit"
        }

        let userId = document.getElementById(nameId) as HTMLInputElement;

        Array.from(document.querySelectorAll("input[name=" + node + "]"))
            .forEach((checkbox) => {
                let cb = document.getElementById(checkbox.id) as HTMLInputElement;
                if (cb.checked) {
                    checkCompany = "value"
                }
            })

        if (userId.value === "") {
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: 'กรุณากรองข้อมูลในช่อง User ID',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
            })
        } else if (checkCompany === "") {
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: 'กรุณาเลือก Company อย่างน้อย 1 Company',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
            })
        } else {

            let employeeList: any = []

            Array.from(document.querySelectorAll("input[name=" + node + "]"))
                .forEach((checkbox) => {
                    let cb = document.getElementById(checkbox.id) as HTMLInputElement;
                    if (cb.checked) {

                        let key: any = cb.dataset.key
                        let keyInt = parseInt(key)

                        let valueRoleStr: string = ""
                        if (valueRole.length > 0) {
                            for (let i = 0; i < valueRole[keyInt].length; i++) {
                                if (valueRole[keyInt][i] !== undefined) {
                                    if (valueRole[keyInt][i] !== undefined) {
                                        if (i === 0) {
                                            valueRoleStr += valueRole[keyInt][i].value
                                        } else {
                                            valueRoleStr += "|" + valueRole[keyInt][i].value
                                        }
                                    }
                                }
                            }
                        }

                        let dataset = {
                            sid: cb.dataset.valuecheck,
                            role_access: valueRoleStr
                        }

                        employeeList.push(dataset)
                    }
                })

            let data = [{
                id: userId.value,
                list_company: employeeList
            }]

            this.setState({
                page: 1
            })

            if (manageMode === "Add") {
                this.props.requestFunction(this.state.version, "POST", data, false, null, valueSelectSearch, userName, roleAccess, 1, this.state.pageSize);
            } else if (manageMode === "Edit") {
                this.props.requestFunction(this.state.version, "PUT", data, false, null, valueSelectSearch, userName, roleAccess, 1, this.state.pageSize);
            }
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        }
    }

    deleteClick = () => {
        const { valueSelectSearch, userName, roleAccess } = this.state

        this.setState({
            page: 1
        })

        this.props.requestFunction(this.state.version, "DELETE", this.state.valueSelect, false, null, valueSelectSearch, userName, roleAccess, 1, this.state.pageSize);
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
    }

    openModalAdd = () => {
        this.setState({
            manageMode: "Add",
            isOpenModal: true
        });
    }

    openModalEdit = (data: Models.EmployeeMain) => {
        var dataset = this.state.valueSelect

        var datasetDetailList: any = []
        var datasetRoleList: any = []

        dataset.map((item, ids) => {

            var status = false
            var role_access = ""

            data.list_company.map((items, idx) => {

                if (items.sid === item.value) {
                    status = true
                    role_access = items.role_access
                }
            })

            var datasetList: any = []
            if (role_access !== "") {
                var role_accessCout = role_access.split('|')
                role_accessCout.map((item, id) => {
                    dataset = {
                        value: item,
                        label: item
                    }
                    datasetList.push(dataset)
                })
            }

            let datasetDetail = {
                id: data.id,
                sid: item.value,
                label: item.label,
                role_access: role_access,
                status: status
            }

            datasetDetailList.push(datasetDetail)
            datasetRoleList.push(datasetList)
        })
        this.setState({
            valueSelectEdit: datasetDetailList,
            valueRole: datasetRoleList,
            manageMode: "Edit",
            isOpenModal: true
        });
    }

    openModalDelete = (data: Models.EmployeeMain) => {
        const { valueSelectSearch, userName, roleAccess } = this.state

        Swal.fire({
            title: 'ลบข้อมูล ' + data.id + ' ?',
            text: 'คุณต้องการลบหรือไม่ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ไม่',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this.setState({
                    page: 1
                })
                this.props.requestFunction(this.state.version, "DELETE", data, false, null, valueSelectSearch, userName, roleAccess, 1, this.state.pageSize);
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            }
        })
    }

    handleChange = (newValue: string, actionMeta: any, check: string, key: any) => {
        if (check === "Role") {
            var data = this.state.valueRole
            data[key] = newValue
            this.setState({ valueRole: data })
        }
    };

    handleCheckBox(key: any, e: any) {
        var checkVisible = document.getElementById('Select' + key) as HTMLInputElement;

        if (e.target.checked === true) {
            checkVisible.style.visibility = ""
        } else {
            checkVisible.style.visibility = "hidden"
        }
    }

    formatSelectCreate(value) {
        var dataset: any
        var datasetList: any = []

        if (value.role_access !== "") {
            var role_accessCout = value.role_access.split('|')
            role_accessCout.map((item, id) => {
                if (this.state.roleList.length > 0) {
                    const role = this.state.roleList.filter(role => item === role.id);
                }

                if (this.state.roleList.filter(role => item === role.id)[0]?.name !== undefined) {
                    dataset = {
                        value: item,
                        label: (this.state.roleList.length > 0)
                            ? this.state.roleList.filter(role => item === role.id)[0].name
                            : 'Loading...'
                    }
                    datasetList.push(dataset)
                }
            })
        }

        return datasetList
    }

    findDataCheckBox(value) {
        var model = this.state.valueSelect.filter(function (item) { return item.value === value });
        if (model[0] !== undefined) {
            return model[0]['label'].toString();
        } else {
            return value.toString();
        }
    }

    private formatRole(role: string): Array<string | undefined> {
        if (this.state.roleList.length > 0) {
            const roleList = this.state.roleList;

            const roleNumbers = role.split('|');
            const roleLabels = roleNumbers.map(roleNumber => roleList.find(role => role.id === roleNumber)?.name);

            return roleLabels;
        }

        return [];
    }

    setBodyModal = () => {
        const { manageMode, valueSelect, roleList } = this.state;

        if (manageMode === "Add") {
            return <div>
                <Row>
                    <Col xs="3"><span style={{ fontSize: 16, fontWeight: "bold" }}>User Name</span></Col>
                    <Col xs="3"><span style={{ fontSize: 16, fontWeight: "bold" }}>บริษัท</span></Col>
                    <Col xs="6"><span style={{ fontSize: 16, fontWeight: "bold" }}>Role Access</span></Col>
                </Row>
                {
                    this.state.valueSelect.length > 0 && this.state.valueSelect.map((item, id) => {
                        return (
                            <Row style={{ marginTop: "15px", height: "30px" }} key={id}>
                                <Col xs="3" hidden={id > 0 ? true : false}>
                                    <Form>
                                        <Input bsSize="sm" type="text" id="userId" />
                                    </Form>
                                </Col>
                                <Col xs={id > 0 ? { size: 3, offset: 3 } : { size: 3 }}>
                                    <Form>
                                        <FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="checkbox" name="checkCompany" id={id} value={item.value} data-key={id} data-valuecheck={item.value} onChange={(e) => this.handleCheckBox(id, e)} />{' '}
                                                    {item.label}
                                                </Label>
                                            </FormGroup>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col xs="6">
                                    <Form>
                                        <FormGroup id={"Select" + id} style={{ visibility: 'hidden' }} >
                                            {
                                                roleList.length > 0
                                                && (
                                                    <CreatableSelect
                                                        menuPortalTarget={document.body}
                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        isMulti
                                                        isClearable
                                                        onChange={(value, action) => this.handleChange(value, action, "Role", id)}
                                                        options={roleList.map(role => ({ value: role.id, label: role.name }))}
                                                    />
                                                )
                                            }
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                        )
                    })
                }
            </div>;
        } else if (manageMode === "Edit") {
            return <div>
                <Row >
                    <Col xs="3"><span style={{ fontSize: 16, fontWeight: "bold" }}>User Name</span></Col>
                    <Col xs="3"><span style={{ fontSize: 16, fontWeight: "bold" }}>บริษัท</span></Col>
                    <Col xs="6"><span style={{ fontSize: 16, fontWeight: "bold" }}>Role Access</span></Col>
                </Row>
                {
                    this.state.valueSelectEdit.length > 0 && this.state.valueSelectEdit.map((item, id) => {
                        return (
                            <Row
                                // style={{ marginTop: "15px", height: "30px" }}
                                key={id}
                            >
                                <Col xs="3" hidden={id > 0 ? true : false}>
                                    <Form>
                                        <Input bsSize="sm" type="text" id="userIdEdit" value={item.id} defaultValue={manageMode === "Edit" ? valueSelect.id : ""} disabled={manageMode === "Edit" ? true : false} />
                                    </Form>
                                </Col>
                                <Col xs={id > 0 ? { size: 3, offset: 3 } : { size: 3 }}>
                                    <Form>
                                        <FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="checkbox" name="checkCompanyEdit" id={id} data-key={id} defaultChecked={item.status} data-valuecheck={item.sid} onChange={(e) => this.handleCheckBox(id, e)} />{' '}
                                                    {item.label}
                                                </Label>
                                            </FormGroup>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                <Col xs="6">
                                    <Form >
                                        <FormGroup id={"Select" + id} style={{ visibility: item.status === false ? 'hidden' : 'initial' }} >
                                            <CreatableSelect
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                isMulti
                                                isClearable
                                                defaultValue={this.formatSelectCreate(item)}
                                                onChange={(value, action) => this.handleChange(value, action, "Role", id)}
                                                options={roleList.map(role => ({ value: role.id, label: role.name }))}
                                            />
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                        )
                    })
                }
            </div>;
        }
    }

    setButtonModal = () => {
        if (this.state.manageMode === "Add") {
            return <Button className="employee-font" onClick={this.addClickAndEdit}>Add</Button>;
        } else if (this.state.manageMode === "Edit") {
            return <Button className="employee-font" onClick={this.addClickAndEdit}>Edit</Button>;
        } else if (this.state.manageMode === "Delete") {
            return <Button className="employee-font" onClick={this.deleteClick}>Delete</Button>;
        }
    }

    onChangeFileHandler = (event: any) => {

        if (event.target.files.length > 0) {

            if (event.target.files[0].type.indexOf('.sheet') === -1) {
                Swal.fire({
                    title: 'Please select file excel.',
                    icon: 'warning'
                })

            } else {
                var file = event.target.files;

                Swal.fire({
                    title: 'Are you sure to Upload?',
                    text: "File : " + event.target.files[0].name,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.isConfirmed) {

                        this.props.requestFunction(this.state.version, "POST", this.state.modelEmployee, true, file);
                        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
                    } else {
                        this.inputFileElement.value = null;
                    }
                })
            }
        }
    }

    private inputFileElement: any;

    afterCallApiSuccess = () => {
        if (this.props.responseEmployee !== null && this.props.statusEmployee === 200) {

            var countingPage: any = 0
            var dataArr: any = []
            countingPage = Math.ceil(this.props.responseEmployee['total_count'] / this.state.pageSize)

            for (let i = 0; i < countingPage; i++) {
                var data: any = {}
                data['page'] = i + 1
                dataArr.push(data)
            }

            this.setState({
                pageBtn: dataArr
            })

            console.log(this.props.responseEmployee.result_list)

            this.setState({
                listModelEmployee: this.props.responseEmployee.result_list !== null ? this.props.responseEmployee.result_list as Array<Models.EmployeeMain> : [],
                isOpenModal: false
            });
        }
        else {
            this.setState({
                isOpenModal: false
            });

            if (this.state.manageMode !== "Select") {
                this.props.requestFunction(this.state.version, "GET", this.state.modelEmployee, false, null);
                this.props.requestFunctionRole(true, this.state.version, "GET", { role_access_code: "" });
                this.props.requestFunctionCompanyMaster(true, "GET", "", "active", "");
            }
        }

        document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
    }

    componentDidUpdate() {
        const { response, versionGroup, isLoading, statusEmployee, isLoadingEmployee, responseEmployee, isLoadingCompanyMaster, responseCompanyMaster, statusCompanyMaster, ImportEmployee } = this.props
        const { valueSelectSearch, userName, roleAccess } = this.state

        if (this.props.isLoadingRole) {
            if (response !== undefined) {
                this.state.valueSelectRole.splice(0)
                var DataSelectArray = this.state.valueSelectRole
                for (var i = 0; i < response.total_count; i++) {
                    let data = {}
                    data['value'] = response.result_list[i].role_access_code
                    data['label'] = response.result_list[i].role_access_name
                    DataSelectArray.push(data)
                }
            }

            this.setState({
                valueSelectRole: DataSelectArray
            });
        }

        if (!isLoadingEmployee && statusEmployee === 204) {
            this.setState({
                isOpenModal: false
            });
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'ไม่สำเร็จ',
                text: "User Name ซ้ำกับข้อมูลที่มีอยู่",
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ใช่',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestFunction(this.state.version, "GET", "", false, null, valueSelectSearch, userName, roleAccess, 1, this.state.pageSize);
                }
            })
        }

        if (isLoadingCompanyMaster && statusCompanyMaster === 200) {
            if (responseCompanyMaster !== undefined) {
                this.state.valueSelect.splice(0)
                var DataSelect = this.state.valueSelect
                for (var i = 0; i < responseCompanyMaster.total_count; i++) {
                    let data = {}
                    data['value'] = responseCompanyMaster.result_list[i].sid
                    data['label'] = responseCompanyMaster.result_list[i].company_name
                    data['status'] = false
                    DataSelect.push(data)
                }
            }
            this.setState({
                valueSelect: DataSelect
            })
        }

        if (this.state.ExportTemplate === "exportTemplate") {
            this.ExportMasterTemplate();
        }
        else if (this.state.ExportTemplate === "exportEmployee") {
            this.ExportTemplateUser();
        }

        if (ImportEmployee.isLaoadAddImportMasterEmployee && ImportEmployee.statusAddImportMasterEmployee === 200) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'สำเร็จ!',
                text: 'บันทึกข้อมูลสำเร็จ',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestImportMasterEmployee(true, "CLEAR")
                    this.setState({
                        openModalImport: false,
                        excelListEmployee: undefined,
                        pageBtn_excel: []
                    })
                    this.props.requestFunction(this.state.version, "GET", this.state.modelEmployee, false, null, "", "", "", 1, 25);
                }
            })
        } else if (ImportEmployee.isLaoadAddImportMasterEmployee && ImportEmployee.statusAddImportMasterEmployee !== 200) {
            console.log(ImportEmployee.messageAddImportMasterEmployee)
            //var msg = JSON.parse(ImportEmployee.messageAddImportMasterEmployee)
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                //text: ImportEmployee.messageAddImportMasterEmployee,
                html: ImportEmployee.messageAddImportMasterEmployee,
                icon: 'error',
                width: 'auto',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!',
                allowOutsideClick: false,
            }).then((result) => {
                if (result.value) {
                    this.props.requestImportMasterEmployee(true, "CLEAR")
                    this.props.requestFunction(this.state.version, "GET", this.state.modelEmployee, false, null, "", "", "", 1, 25);
                }
            })
        }
    }

    haddleChangePage = (pageNo: any, pagesize: any) => {
        const { valueSelectSearch, userName, roleAccess } = this.state

        var pagesizeUse: any
        if (pagesize !== "") {
            pagesizeUse = pagesize
        } else {
            pagesizeUse = this.state.pageSize
        }

        this.setState({
            page: pageNo
        })

        this.props.requestFunction(this.state.version, "GET", "", false, null, valueSelectSearch, userName, roleAccess, pageNo, pagesizeUse);
    }

    BeforehandleChangePage = (event, value) => {
        this.haddleChangePage(value, '')
    }

    handlePageSize = (e) => {
        this.setState({ pageSize: e.target.value })
        this.haddleChangePage(this.state.page, e.target.value)
    }

    handleSelectSearch = (value) => {
        this.setState({
            valueSelectSearch: value !== null && value !== undefined && value !== "" ? value['value'] : ""
        })
    }

    handleSearchValue = (userName, roleAccess) => {
        var valueSelectSearch = this.state.valueSelectSearch

        this.setState({
            userName: userName,
            roleAccess: roleAccess
        })

        this.props.requestFunction(this.state.version, "GET", this.state.modelEmployee, false, null, valueSelectSearch, userName, roleAccess, 1, this.state.pageSize);
    }

    onOpenModalImport = () => {
        this.resetExportTemplate()
        this.setState({ openModalImport: !this.state.openModalImport, excelListEmployee: undefined, excelListEmployeeBind: undefined, pageBtn_excel: [] })
    }

    resetExportTemplate = () => {
        this.setState({ ExportTemplate: "" });
    }

    handleChangeCompany = (event) => {
        this.setState({ selectCompany: event.target.value });
    };

    onOpenModalSelectCom = () => {
        this.resetExportTemplate()
        if (this.state.selectCompany === "" && this.state.valueSelect.length !== 0) {
            this.setState({ selectCompany: this.state.valueSelect[0].value })
        }
        this.setState({ openModalSelectCom: !this.state.openModalSelectCom })
    }

    ExportTemplate = (Mode) => {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        if (Mode === "exportEmployee") {
            this.onOpenModalSelectCom();
        }
        this.setState({ ExportTemplate: Mode });
        if (Mode === "importTemplate") {
            var formDataReq: any = [];
            this.state.excelListEmployee.map((item) => {
                let data = {}
                data["sid"] = item.sid;
                data["id"] = item.id;
                data["role_access"] = item.role_access;
                data["created_by"] = item.created_by;
                data["created_on"] = item.created_on;
                data["UpdatedBy"] = item.UpdatedBy;
                data["updated_by"] = item.updated_by;
                formDataReq.push(data);
            })
            this.props.requestImportMasterEmployee(true, "POST", formDataReq)
        }
    }

    ExportMasterTemplate = () => {
        this.resetExportTemplate();
        var date = new Date();
        var filename = 'Template_MasterEmployee_' + date.getTime().toString() + '.xlsx';
        var Company: any = []
        this.state.valueSelect.map((item) => {
            let data = {}
            data["sid"] = item.value;
            data["company_name"] = item.label;
            Company.push(data);
        })

        fetch("/v1/user/check-employee",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION')
                }
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    var Employee: any = []
                    result.map((item) => {
                        let data = {}
                        data["sid"] = item.sid;
                        data["id"] = item.id;
                        data["role_access"] = item.role_access;
                        Employee.push(data);
                    })
                    var ws_Company = XLSX.utils.json_to_sheet(Company);
                    var ws_Employee = XLSX.utils.json_to_sheet(Employee);
                    var wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws_Company, "Company");
                    XLSX.utils.book_append_sheet(wb, ws_Employee, "MasterEmployee");
                    XLSX.writeFile(wb, filename);
                    document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                },
                (error) => {
                    document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                }
            )
    }

    ExportTemplateUser = () => {
        this.resetExportTemplate();
        var date = new Date();
        var filename = 'MasterEmployee_' + date.getTime().toString() + '.xlsx';
        var Company: any = []
        this.state.valueSelect.map((item) => {
            let data = {}
            data["sid"] = item.value;
            data["company_name"] = item.label;
            Company.push(data);
        })

        fetch("/v1/user/check-employee?sid=" + this.state.selectCompany,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION')
                }
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    var Employee: any = []
                    result.map((item) => {
                        let data = {}
                        data["sid"] = item.sid;
                        data["id"] = item.id;
                        data["role_access"] = item.role_access;
                        data["created_by"] = item.created_by;
                        data["created_on"] = item.created_on;
                        data["UpdatedBy"] = item.UpdatedBy;
                        data["updated_by"] = item.updated_by;
                        Employee.push(data);
                    })
                    var ws_Company = XLSX.utils.json_to_sheet(Company);
                    var ws_Employee = XLSX.utils.json_to_sheet(Employee);
                    var wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws_Company, "Company");
                    XLSX.utils.book_append_sheet(wb, ws_Employee, "MasterEmployee");
                    XLSX.writeFile(wb, filename);
                    document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                },
                (error) => {
                    document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                }
            )
    }

    checkValueEmployeeOldOrNew = (Status, Old, New, Index) => {
        var value: any
        if (Status === "Insert") {
            value = New
        } else if (Status === "Update") {
            var OldValue: Models.employeeListOld = Old
            if (OldValue[Index] !== New) {
                value = <span>{OldValue[Index]} <FaArrowRight style={style} /><span style={{ fontWeight: "bold" }}> {New}</span></span>;
            } else {
                value = New
            }
        }
        return value
    }

    showDetailFile = (obj) => {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        if (obj.value !== "") {
            this.resetExportTemplate()
            var formData = new FormData();
            formData.append('file', obj.target.files[0]);
            fetch("/v1/user/upload-excell",
                {
                    method: 'POST',
                    headers: {
                        'authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION')
                    },
                    body: formData
                }
            )
                .then(res => res)
                .then(
                    (result) => {
                        if (result.ok) {
                            result.json().then(data => {
                                console.log(data)
                                this.setState({ excelListEmployee: data as Models.ExcelListemployee })
                                this.setPageBtnExcel(this.state.page_size_excel)
                                this.setDataExcelBind(this.state.page, this.state.page_size_excel)
                            })
                        } else {
                            result.json().then(data => {
                                Swal.fire({
                                    title: 'เกิดข้อผิดพลาด',
                                    text: data.message,
                                    icon: 'error',
                                    showCancelButton: false,
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'ยืนยัน',
                                })
                            })
                        }
                        document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                    },
                    (error) => {
                        document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                    }
                )
        }
    }

    handleClickFile = () => {
        this.inputOpenFileRef?.current?.click();
    }

    setPageBtnExcel = (page_size_num: any) => {
        const { excelListEmployee } = this.state
        var countingPage: any = 0
        var dataArr: any = []
        countingPage = Math.ceil(excelListEmployee.length / page_size_num)
        console.log(countingPage)

        for (let i = 0; i < countingPage; i++) {
            var data: any = {}
            data['page'] = i + 1
            data['pageTab'] = this.props.pageTab
            dataArr.push(data)
        }

        this.setState({
            pageBtn_excel: dataArr
        })
    }

    BeforehandleChangePageExcel = (event, value) => {
        console.log(value);
        this.setDataExcelBind(value, this.state.page_size_excel)
        this.setState({
            page_excel: value
        })

        //return this.haddleChangePage(this.props.pageTab, value)
    }

    setDataExcelBind = (page_num: any, page_size_num: any) => {
        const { excelListEmployee } = this.state
        if (excelListEmployee !== undefined) {

            var datas: any = []

            for (var i = 0; i < excelListEmployee.length; i++) {
                var frist = page_size_num * (page_num - 1)
                var last = (page_size_num * page_num) - 1
                if (i <= last && i >= frist) {
                    datas.push(excelListEmployee[i])
                }
            }

            this.setState({ excelListEmployeeBind: datas })
        }
    }

    handlePageSizeExcel = (e) => {
        //console.log(typeof e.target.value)
        var parse = parseInt(e.target.value)
        this.setPageBtnExcel(parse)
        this.setDataExcelBind(this.state.page, parse)
        this.setState({ page_size_excel: parse });
    }

    render() {
        const { listModelEmployee, valueSelectRole } = this.state
        if (this.props.versionEmployee > this.state.version) {
            if (this.props.versionEmployee - this.state.version === 2) {//get
                this.afterCallApiSuccess();

            } else if (this.props.versionEmployee - this.state.version === 3) {//post
                this.afterCallApiSuccess();

            } else if (this.props.versionEmployee - this.state.version === 4) {//post upload
                this.afterCallApiSuccess();

            } else if (this.props.versionEmployee - this.state.version === 5) {//put
                this.afterCallApiSuccess();

            } else if (this.props.versionEmployee - this.state.version === 6) {//delete
                this.afterCallApiSuccess();

            }

            this.setState({ version: this.props.versionEmployee });
        }

        return (
            <div>
                <div>
                    <div className='trapezoid' style={{ width: 'auto', display: 'inline-block' }}>
                        <span className="employee-font font-size-employee" >
                            Employee Master
                        </span>
                    </div>
                    <div style={{ display: 'inline', float: 'right' }}>
                        <Button className="mx-1 employee-font" variant="info" size="sm" style={{ float: 'right', marginLeft: '5px' }} onClick={this.openModalAdd}><FaPlusCircle style={{ marginBottom: '4px' }} /> Add</Button>
                        &nbsp;
                        {/*  <label htmlFor="inputUploadFilEmployee" className="btn-upload-file employee-font" style={{ float: 'right' }}  ><FaUpload style={{ marginBottom: '4px' }} /> Upload Excel</label>
                        <input type="file" name="file" id="inputUploadFilEmployee" onChange={this.onChangeFileHandler} className="inp-upload-file" ref={input => this.inputFileElement = input} />*/}
                        <Button className="mx-1 employee-font" variant="success" size="sm" style={{ float: 'right', marginLeft: '5px' }} onClick={() => this.ExportTemplate("exportTemplate")}><FaFileExport style={{ marginBottom: '4px' }} />&nbsp;Export Template</Button>
                        <Button className="mx-1 employee-font" variant="success" size="sm" style={{ float: 'right', marginLeft: '5px' }} onClick={() => this.onOpenModalSelectCom()}><FaFileExport style={{ marginBottom: '4px' }} />&nbsp;Export Employee</Button>
                        <Button className="mx-1 employee-font" variant="success" size="sm" style={{ float: 'right', marginLeft: '5px' }} onClick={() => this.onOpenModalImport()}><FaFileDownload style={{ marginBottom: '4px' }} />&nbsp;Import Template</Button>
                    </div>
                </div>
                <div className="mat-box employee-font font-size-employee s">
                    <Row >
                        <Col>
                            <Row className="trapezoid trapezoid-purple" style={{ margin: 'initial' }}>
                                <span style={{ fontSize: '15px' }}>
                                    Search menu
                                </span>
                            </Row>

                            <EmployeeMasterSearchComponent
                                valueSelect={this.state.valueSelect}
                                handleSelectSearch={this.handleSelectSearch.bind(this)}
                                handleSearchValue={this.handleSearchValue.bind(this)}
                                roleList={this.state.roleList}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xs={6}>
                            <div className='d-flex justify-content-start my-2 my-lg-0'>
                                <Form inline>
                                    <Label style={{ fontSize: '14px' }}>
                                        Show&nbsp;&nbsp;
                                    </Label>
                                    <Input
                                        type="select"
                                        name="select"
                                        bsSize="sm"
                                        value={this.state.pageSize}
                                        onChange={this.handlePageSize}
                                    >
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </Input >
                                    <Label style={{ fontSize: '14px' }}>
                                        &nbsp;&nbsp;entries
                                    </Label>
                                </Form>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className='d-flex justify-content-end my-2 my-lg-0'>
                                { /* <Pagination count={this.state.pageBtn.length !== 0 && this.state.pageBtn.length} shape="rounded" siblingCount={0} showFirstButton showLastButton onChange={this.BeforehandleChangePage} page={this.state.page} variant="outlined"/> */}
                                <Pagination count={this.state.pageBtn.length !== 0 && this.state.pageBtn.length} shape="rounded" showFirstButton showLastButton onChange={this.BeforehandleChangePage} page={this.state.page} />
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <Table className="table-bordered align-middle employee-font font-size-employee" size='sm'>
                                <thead>
                                    <tr style={{ width: '100%' }} className="text-center table-active thead-light">
                                        <th className="td-menu" style={{ width: '7%', top: -1, position: 'sticky', zIndex: 1 }} ></th>
                                        <th style={{ width: '30%', position: 'sticky', top: -1, zIndex: 1 }} className="td-id" >User Name</th>
                                        <th style={{ width: '30%', position: 'sticky', top: -1, zIndex: 1 }} className="td-id" >บริษัท</th>
                                        <th className="td-role-access" style={{ width: '30%', top: -1, position: 'sticky', zIndex: 1 }} >Role Access</th>
                                    </tr>
                                </thead>
                                {!this.props.isLoadingEmployee ? <tr><td colSpan={6} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner size="sm" color="dark" />{' '}Loading...</td></tr> : <tr></tr>}
                                {this.props.isLoadingEmployee && this.state.listModelEmployee.length === 0 ? <tr><td colSpan={6} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>No Data.</td></tr> : <tr></tr>}
                                {this.props.isLoadingEmployee && this.props.statusEmployee === 200 && this.state.listModelEmployee.map((item: Models.EmployeeMain, id) => {
                                    return (
                                        <tbody key={Math.random() + id}>
                                            <tr key={item.id + id}>
                                                <td className="td-menu text-center" style={{ width: '200px' }} rowSpan={item.list_company.length}>
                                                    <Button variant="outline-info" size="sm" onClick={() => this.openModalEdit(item)} ><FaEdit style={{ marginBottom: '4px' }} /></Button>{' '}
                                                    <Button className="mx-1" variant="outline-danger" size="sm" onClick={() => this.openModalDelete(item)}><FaTimesCircle style={{ marginBottom: '2px' }} /></Button>
                                                </td>
                                                <td style={{ width: '100px' }} className="td-id" rowSpan={item.list_company.length} >{item.id}</td>
                                                <td style={{ width: '100px' }} className="td-id" >
                                                    {item.list_company.map((items: Models.EmployeeMainList, id_sid) => {
                                                        if (id_sid === 0) {
                                                            return (
                                                                <span >{this.findDataCheckBox(items.sid)}</span>
                                                            )
                                                        }
                                                    })
                                                    }
                                                </td>
                                                <td className="td-role-access" style={{ width: '200px' }}>
                                                    {item.list_company.map((items: Models.EmployeeMainList, id_role) => {
                                                        if (id_role === 0) {
                                                            return this.formatRole(items.role_access).map(role => (
                                                                <span className="badge badge-primary mr-2">{role}</span>
                                                            ))
                                                        }
                                                    })}
                                                </td>
                                            </tr>
                                            {
                                                item.list_company.map((subItem: Models.EmployeeMainList, subId) => {
                                                    if (subId > 0) {
                                                        return (
                                                            <tr key={"subId" + subId + id}>
                                                                <td>
                                                                    <span>{this.findDataCheckBox(subItem.sid)}</span>
                                                                </td>
                                                                <td>
                                                                    <span>
                                                                        {this.formatRole(subItem.role_access).map(role => (
                                                                            <span className="badge badge-primary mr-2">{role}</span>
                                                                        ))}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                })
                                            }
                                        </tbody>
                                    )
                                })}

                            </Table>
                        </Col>
                    </Row>
                </div>
                <Modal isOpen={this.state.isOpenModal} className="dms-modal modal-upload-file">
                    <ModalHeader className="employee-font" toggle={this.toggleModal}>{this.state.manageMode + ' Employee'}</ModalHeader>
                    <ModalBody>
                        {this.setBodyModal()}
                    </ModalBody>
                    <ModalFooter>
                        {this.setButtonModal()}
                        <Button variant="danger" className="employee-font" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.openModalSelectCom} toggle={() => this.onOpenModalSelectCom()} size="md" className="dms-modal employee-font font-size-employee">
                    <ModalHeader toggle={() => this.onOpenModalSelectCom()}>Select Company</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col sm={12}>
                                <Input type="select" name="select" bsSize="sm" onChange={this.handleChangeCompany} defaultValue={this.state.selectCompany} value={this.state.selectCompany}>
                                    {this.state.valueSelect.length !== 0 && this.state.valueSelect.map((data, index) => {
                                        return (
                                            <option key={index} value={data.value}>{data.label}</option>
                                        )
                                    })}
                                </Input>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.ExportTemplate("exportEmployee")}>Export</Button>
                        <Button variant="danger" onClick={() => this.onOpenModalSelectCom()}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.openModalImport} toggle={() => this.onOpenModalImport()} size="xl" style={{ maxWidth: '1800px' }} className="dms-modal employee-font font-size-employee">
                    <ModalHeader toggle={() => this.onOpenModalImport()}>Import File</ModalHeader>
                    <ModalBody>
                        <input type="file" name="file" id="fileImport" ref={this.inputOpenFileRef} style={{ display: "none" }} onChange={this.showDetailFile} accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                        <Row>
                            <Col>
                                <Button className="mb-3 employee-font" variant="info" size="sm" onClick={this.handleClickFile} ><FaFileExcel style={{ marginBottom: '4px' }} />&nbsp;Select File</Button>
                            </Col>
                        </Row>
                        <Row style={{ display: "none" }}>
                            <Col xs={6} >
                                <div className='d-flex justify-content-start my-2 my-lg-0'>
                                    <Form inline>
                                        <Label style={{ fontSize: '14px' }}>
                                            Show&nbsp;&nbsp;
                                        </Label>
                                        <Input
                                            type="select"
                                            name="select"
                                            bsSize="sm"
                                            value="25"
                                            onChange={this.handlePageSize}
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </Input >
                                        <Label style={{ fontSize: '14px' }}>
                                            &nbsp;&nbsp;entries
                                        </Label>
                                    </Form>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className='d-flex justify-content-end my-2 my-lg-0 employee-font font-size-employee'>
                                    <Pagination count={this.state.pageBtn.length !== 0 && this.state.pageBtn.length} variant="outlined" color="primary" shape="rounded" showFirstButton showLastButton onChange={this.BeforehandleChangePage} page={this.state.page} />
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col xs={6}>
                                <div className='d-flex justify-content-start my-2 my-lg-0'>
                                    <Form inline>
                                        <Label style={{ fontSize: '14px' }}>
                                            Show&nbsp;&nbsp;
                                        </Label>
                                        <Input
                                            type="select"
                                            name="select"
                                            bsSize="sm"
                                            value={this.state.page_size_excel}
                                            onChange={this.handlePageSizeExcel}
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </Input >
                                        <Label style={{ fontSize: '14px' }}>
                                            &nbsp;&nbsp;entries
                                        </Label>
                                    </Form>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className='d-flex justify-content-end my-2 my-lg-0'>
                                    <Pagination count={this.state.pageBtn_excel.length !== 0 && this.state.pageBtn_excel.length} shape="rounded" siblingCount={0} showFirstButton showLastButton onChange={this.BeforehandleChangePageExcel} page={this.state.page_excel} />
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <div className="responsive-table">
                                    <Table className="table-bordered align-middle " size='sm' style={{ maxHeight: "calc(85vh - 190px)" }}>
                                        <thead>
                                            <tr style={{ width: '100%' }} className="text-center table-active thead-light">
                                                <th className="nowrap" style={{ width: '5%', maxWidth: '5%' }}></th>
                                                <th className="nowrap" style={{ width: '10%', maxWidth: '10%' }}>Status</th>
                                                <th className="nowrap">SID</th>
                                                <th className="nowrap">ID</th>
                                                <th className="nowrap">Role Access</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/*{!ListGetMasterEmployee.isLaoadListExcelMasterEmployee ? <tr><td colSpan={14} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}*/}
                                            {this.state.excelListEmployeeBind === undefined ? < tr > <td colSpan={14} style={{ background: "#ccc", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                                            {this.state.excelListEmployeeBind !== undefined && this.state.excelListEmployeeBind.map((item: Models.ExcelListemployee, idx) => {
                                                return (
                                                    <tr key={idx} className={item.status === "Update" ? "bg-warning nowrap" : item.status === "Insert" ? "bg-success" : ""}>
                                                        <td className="nowrap"><span>{idx + 1}</span></td>
                                                        <td className="nowrap"><span style={{ fontWeight: "bold" }}>{item.status}</span></td>
                                                        <td className="nowrap">{item.sid}</td>
                                                        <td className="nowrap">{this.checkValueEmployeeOldOrNew(item.status, item.employee_old, item.id, "id")}</td>
                                                        <td className="nowrap">{this.checkValueEmployeeOldOrNew(item.status, item.employee_old, item.role_access, "role_access")}</td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.ExportTemplate("importTemplate")}>Save</Button>
                        <Button variant="danger" onClick={() => this.onOpenModalImport()}>Cancel</Button>
                    </ModalFooter>
                </Modal>

            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({ ...state.employee, ...state.roleAccess, ...state.companyMaster, ...state.ImportExcel }),
    ({ ...Stores.actionCreators, ...StoresRoleAccess.actionCreators, ...StoresCompanyMaster.actionCreators, ...StoresImport.actionCreators })
)(EmployeeMasterComponent);