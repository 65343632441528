import * as React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Row, Col, Collapse, Card, CardBody } from 'reactstrap';
import * as FileUpload from '../../models/FileUpload';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as FileUploadStore from '../../store/FileUpload';
import * as FileModels from '../../models/File';
import Swal from 'sweetalert2';
import imgFile from '../../Images/Icon/icon-File.png'
import imgPDF from '../../Images/Icon/icon-PDF.png'
import imgXLS from '../../Images/Icon/icon-XLS.png'
import imgWord from '../../Images/Icon/icon-Word.png'
import imgPowerPoint from '../../Images/Icon/icon-PowerPoint.png'

import CreatableSelect from 'react-select/creatable';
import InputMetadataComponect from '../dms/InputMetadataComponect'
import FileDetailExportExcelComponent from './FileDetailExportExcelComponent';
import FileDetailImportExcelComponent from './FileDetailImportExcelComponent';
import { FaUpload, FaPlus, FaAngleDoubleDown, FaMinus, FaMinusCircle } from "react-icons/fa";
import KeyValuePairInput from '../common/KeyValuePairInput';
import { validateMetadataCollections } from '../../modules/common';
import DeleteButton from '../common/DeleteButton';
import F1ConnectForm, { F1ConnectFormValues } from '../common/F1ConnectForm';

interface Props {
    [key: string]: any;
    removeSelectedFile: (atIndex: number) => void;
}

interface State {
    alertSaveClick: string;
    isOpenModal: boolean;
    selectedFile: FileUpload.FileUpload[];
    relation: string;
    sFile: any;
    version: number;
    inputValue: string[];
    inputValueTotal: any;
    valueTotal: any;
    groupId: any;
    subGroupId: any;
    boxId: any;
    value: any;
    inputSearchKeyIndex: number;
    isOpenConfigMetadataMap: Map<number | string, boolean>;
    duplicatePrimaryIndexes: FileModels.File[];
}

const components = {
    DropdownIndicator: null,
};

const createOption = (label: string) => ({
    label,
    value: label,
});

class UploadFileComponent extends React.Component<Props, State> {
    private inputFileElement: any;

    constructor(props: any) {
        super(props);

        this.state = {
            alertSaveClick: "",
            isOpenModal: false,
            selectedFile: [{
                lastModified: "",
                lastModifiedDate: "",
                name: "",
                size: 0,
                full_path: "",
                type: "",
                webkitRelativePath: "",
                keyIndex: "",
                primaryIndex: "",
                role: "",
                metadata: [],
                sid: "",
                bucket: "",
                group_id: "",
                sub_group_id: "",
                box_id: "",
                relation: ""
            }],
            sFile: null,
            version: 0,
            inputSearchKeyIndex: 0,
            inputValue: [],
            value: [],
            inputValueTotal: "",
            valueTotal: [],
            groupId: "",
            subGroupId: "",
            boxId: "",
            relation: "",
            isOpenConfigMetadataMap: new Map(),
            duplicatePrimaryIndexes: []
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.previewFile = this.previewFile.bind(this);
        this.uploadClick = this.uploadClick.bind(this);
        this.handleChangePid = this.handleChangePid.bind(this);
        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.handleChangeMataData = this.handleChangeMataData.bind(this);
        this.fomatSizeFile = this.fomatSizeFile.bind(this);
        this.onMetadataChange = this.onMetadataChange.bind(this);
        this.addMetadata = this.addMetadata.bind(this);
        this.removeSelectedFile = this.removeSelectedFile.bind(this);
        this.handleClickApplyAll = this.handleClickApplyAll.bind(this);
    }

    componentDidUpdate() {
        if (this.props.version > this.state.version) {
            this.toggleModal();
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";

            if (this.props.version - this.state.version === 2) {

                Swal.fire({
                    title: 'สำเร็จ!',
                    text: 'กดปุ่มยืนยัน',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ยืนยัน!'
                }).then((result) => {
                    //this.props.onUploadSuccess();
                    this.props.reloading("reset");

                    // Clear store states, if don't do this it might be unnecessary open modal again
                    this.props.clear();
                })
            } else {

                Swal.fire(
                    'Save Fail.',
                    '',
                    'error'
                )
            }

            this.setState({ version: this.props.version });
        }
    }

    public toggleConfigMetaData = (key: number | string) => {
        this.setState(prevState => {
            const value = prevState.isOpenConfigMetadataMap.get(key) || false;
            const nextIsOpenConfigMetadataMap = new Map(prevState.isOpenConfigMetadataMap);

            return { isOpenConfigMetadataMap: nextIsOpenConfigMetadataMap.set(key, !value) };
        });
    }

    private async toggleModal() {
        if (this.state.isOpenModal) {
            this.inputFileElement.value = null;
        }

        // Check duplicate primary index
        const primaryIndexList = this.state.selectedFile.map(f => f.bucket + '__' + f.primaryIndex);
        const foundPrimaryIndexFiles = await this.fetchFilePrimaryIndexStore({
            key_list: primaryIndexList,
            bucket: this.state.selectedFile[0].bucket
        }) ?? [];

        //console.log("[Debug] buckets", this.state.selectedFile.map(s => s.bucket))
        console.log("[Debug] foundPrimaryIndexFiles", foundPrimaryIndexFiles)

        //const foundPrimaryIndexInBuckets = this.state.selectedFile.reduce<FileModels.File[]>((accumulate, selectedFile) => {
        //    const foundSameBucket = foundPrimaryIndexFiles.find(file => file.bucket === selectedFile.bucket);

        //    if (!foundSameBucket) {
        //        return accumulate;
        //    }

        //    accumulate.push(foundSameBucket);

        //    return accumulate;
        //}, []);

        //console.log("[Debug] foundPrimaryIndexInBuckets", foundPrimaryIndexInBuckets)

        if (!this.state.isOpenModal && foundPrimaryIndexFiles.length > 0) {
            this.popupAlertDuplicatePrimaryIndex(foundPrimaryIndexFiles);
        }

        this.setState({
            isOpenModal: !this.state.isOpenModal,
            alertSaveClick: "",
            inputValue: [],
            value: [],
            inputValueTotal: "",
            valueTotal: [],
            duplicatePrimaryIndexes: foundPrimaryIndexFiles
        });
    }

    private async fetchFilePrimaryIndexStore(req: FileModels.FindPrimaryFilesRequest) {
        const response = await fetch(`/v2/dms/primary`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req),
        });

        if (!response.ok) {
            return;
        }

        const data: FileModels.ResponseFile = await response.json();

        if (data.total_count === 0) {
            return;
        }

        return data.result_list;
    }

    public popupAlertDuplicatePrimaryIndex(duplicateFiles: FileModels.File[]) {
        const elements = duplicateFiles.map(file =>
            `<div style="text-align: left;">
                <span style="color: red;">${file.primary_index.split('__')[1].split('.')[0]}</span>
                <span> ที่ ${file.full_path.slice(2, file.full_path.length - 3)},</span>
            </div>`
        ).join('<br/>');

        Swal.fire({
            title: 'มี Primary Index ซ้ำในระบบ!',
            width: '85vw',
            html: elements,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ยืนยัน',
            footer: `<span style="color: red">กรุณาเปลี่ยน <b>Primary Index</b> เพื่อป้องกันข้อมูลสูญหาย</span>`
        });
    }

    // Deprecate (verbose, messy)
    // private onChangeFileHandler(event: any) {
    //     if (event.target.files.length === 1) {
    //         var listFileUpload: FileUpload.FileUpload;
    //         listFileUpload = event.target.files[0];
    //         listFileUpload.keyIndex = "";
    //         listFileUpload.primaryIndex = event.target.files[0].name;
    //         listFileUpload.role = "";
    //         listFileUpload.metadata = "";
    //         listFileUpload.bucket = this.props.folderSelectedCode;

    //         this.setState({
    //             selectedFile: [listFileUpload],
    //             sFile: event.target.files,
    //         });

    //         setTimeout(() => {
    //             this.toggleModal();
    //         }, 500);

    //     } else if (event.target.files.length> 1) {
    //         let listFileUpload: any = [];
    //         Array.from(event.target.files).forEach((file: any) => {
    //             console.log("file upload", file);

    //             var fileUpload: FileUpload.FileUpload;
    //             fileUpload = file;
    //             fileUpload.keyIndex = "";
    //             fileUpload.primaryIndex = file.name;
    //             fileUpload.role = "";
    //             fileUpload.metadata = "";
    //             fileUpload.bucket = this.props.folderSelectedCode;

    //             listFileUpload.push(fileUpload);
    //         });

    //         this.setState({
    //             selectedFile: listFileUpload,
    //             sFile: event.target.files,
    //         });

    //         setTimeout(() => {
    //             this.toggleModal();
    //         }, 500);
    //     }
    // }

    private handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;

        if (files === null || files.length === 0) {
            return;
        }

        const prepareFiles = Array
            .from<File & FileUpload.FileUpload>(files as any)
            .map(file => {
                file.keyIndex = '';
                file.primaryIndex = file.name;
                file.role = '';
                file.metadata = [];
                file.bucket = this.props.folderSelectedCode;

                return file;
            });

        this.setState({
            selectedFile: prepareFiles,
            sFile: event.target.files
        });

        setTimeout(() => this.toggleModal(), 500);
    }

    public onMetadataChange(atFileIndex: number, atMetadataIndex: number) {
        return (key: string, value: string) => {
            // console.log('ngame key/value change', [key, value]);
            this.setState(prevState => {
                const nextSelectedFileState = [...prevState.selectedFile];
                const metadataTarget = nextSelectedFileState[atFileIndex].metadata[atMetadataIndex];
                metadataTarget[0] = key;
                metadataTarget[1] = value;

                return {
                    ...prevState,
                    selectedFile: nextSelectedFileState
                };
            });
        }
    }

    public addMetadata(atFileIndex: number): void {
        this.setState(prevState => {
            const nextSelectedFileState = [...prevState.selectedFile];
            nextSelectedFileState[atFileIndex].metadata.push(['', '']);

            return {
                ...prevState,
                selectedFile: nextSelectedFileState
            };
        });
    }

    public removeMetadata(atFileIndex: number, removeAtIndex: number): void {
        this.setState(prevState => {
            const nextSelectedFileState = [...prevState.selectedFile];
            nextSelectedFileState[atFileIndex].metadata.splice(removeAtIndex, 1);

            return {
                ...prevState,
                selectedFile: nextSelectedFileState
            };
        });
    }

    addTextvalue(e, check) {
        var Group: string = ""
        Group = e.target.value;
        if (check === "groupId") {
            this.setState({
                groupId: Group
            })
        } else if (check === "subGroupId") {
            this.setState({
                subGroupId: Group
            })
        } else if (check === "boxId") {
            this.setState({
                boxId: Group
            })
        } else if (check === "relation") {
            this.setState({
                relation: Group
            })
        }
    }


    private previewFile(item: FileUpload.FileUpload) {

        if (item.type.indexOf('image') !== -1) {
            return URL.createObjectURL(item as any);
        } else if (item.type.indexOf('pdf') !== -1) {
            return imgPDF;
        } else if (item.type.indexOf('.sheet') !== -1) {
            return imgXLS;
        } else if (item.type.indexOf('.document') !== -1) {
            return imgWord;
        } else if (item.type.indexOf('.presentation') !== -1) {
            return imgPowerPoint;
        } else {
            return imgFile;
        }
    }

    private handleChangePid(event: any) {
        let fileindex = parseInt(event.target.getAttribute("data-fileindex"));
        if (typeof fileindex !== "number") {
            fileindex = 0;
        }

        var value = event.target.value;

        var listFileUpload = this.state.selectedFile;
        listFileUpload[fileindex].primaryIndex = value;

        console.log("handleChangePid");
        console.log("File index", fileindex);
        console.log("File", listFileUpload[fileindex])

        this.setState({
            selectedFile: listFileUpload
        });
    }

    private handleChangeRole(event: any) {
        var value = event.target.value;

        var listFileUpload = this.state.selectedFile;
        listFileUpload[0].role = value;

        this.setState({
            selectedFile: listFileUpload
        });
    }

    private handleChangeMataData(event: any) {
        var value = event.target.value;

        var listFileUpload = this.state.selectedFile;
        listFileUpload[0].metadata = value;

        this.setState({
            selectedFile: listFileUpload
        });
    }

    private fomatSizeFile(item: number) {
        var txtSize = "";

        if (item < 1024) {
            txtSize = item + " B";
        } else {
            var size = item / 1024;
            txtSize = parseInt(size.toString()) + " KB";
        }

        return txtSize;
    }

    private uploadClick() {
        const selectedFiles = this.state.selectedFile;

        try {
            for (let i = 0; i < selectedFiles.length; i++) {
                const file = selectedFiles[i];
                const metadataCollections = file.metadata;

                validateMetadataCollections(metadataCollections);
            }
        } catch (error) {
            Swal.fire({
                title: 'เกิดข้อผิดพลาด',
                text: error as any,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน',
            });
            return;
        }

        var checkValue = true;
        this.setState({ alertSaveClick: "" });

        // Validate & Prepar
        this.state.selectedFile.map((file, index) => {
            var listValue: Array<string> = [];

            if (this.state.value[index]) {
                for (var i = 0; i < this.state.value[index].length; i++) {
                    listValue.push(this.state.value[index][i].value);
                }
            }

            this.state.selectedFile[index].keyIndex = listValue.join("|");
            this.state.selectedFile[index].group_id = this.state.groupId
            this.state.selectedFile[index].sub_group_id = this.state.subGroupId
            this.state.selectedFile[index].box_id = this.state.boxId

            var pid = this.state.selectedFile[index].primaryIndex;

            if ((pid === null || pid === "")) {
                this.setState({ alertSaveClick: "Please insert Primary Index." });
                checkValue = false;
            }
        })

        if (checkValue) {
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
            // Process Upload
            this.state.selectedFile.map((file, index) => {
                let _sFile = [this.state.sFile[index]];
                //debugger;
                this.props.requestFunction(_sFile, this.state.selectedFile[index], this.state.version);
            })
        }
    }

    handleChange = (valueChange: any, actionMeta: any, checkRow: any) => {
        if (actionMeta === "Total") {
            this.setState({ valueTotal: valueChange });
        } else {
            const { value } = this.state;
            let _value = [...value];
            if (_value[checkRow] === undefined) {
                _value[checkRow] = [];
            }
            _value[checkRow] = valueChange;
            this.setState({
                value: _value
            });
        }
    };

    handleInputChange = (inputValueSearchKey: string, check: string) => {
        if (check === "Total") {
            this.setState({ inputValueTotal: inputValueSearchKey });
        }
        else {
            this.state.inputValue[this.state.inputSearchKeyIndex] = inputValueSearchKey;
            this.setState({ inputValue: this.state.inputValue });
        }
    };

    handleKeyDown = (event: any, check: string) => {
        if (check === "Total") {
            const { inputValueTotal, valueTotal } = this.state;
            if (!inputValueTotal) return;
            switch (event.key) {
                case 'Enter':
                case 'Tab':
                    this.setState({
                        inputValueTotal: '',
                        valueTotal: [...valueTotal, createOption(inputValueTotal)],
                    });
                    event.preventDefault();
            }
        } else {
            let _inputSearchKeyIndex: number;
            _inputSearchKeyIndex = parseInt(event.currentTarget.getAttribute("id"));
            console.log(_inputSearchKeyIndex)
            this.setState({ inputSearchKeyIndex: _inputSearchKeyIndex });

            const { inputValue, value, inputSearchKeyIndex } = this.state;
            if (!inputValue[inputSearchKeyIndex]) return;

            switch (event.key) {
                case 'Enter':
                case 'Tab':
                    let _value = [...value];
                    if (_value[inputSearchKeyIndex] === undefined) {
                        _value[inputSearchKeyIndex] = [];
                    }
                    _value[inputSearchKeyIndex] = [..._value[inputSearchKeyIndex], createOption(inputValue[inputSearchKeyIndex])];

                    this.setState({
                        inputValue: [],
                        value: _value,
                    });
                    event.preventDefault();
            }
        }
    };

    getValueSelectRoleAccess(valueRoleAccess, itemIndex) {
        this.state.selectedFile[itemIndex].role = valueRoleAccess;
    }

    getValueInputMetaData(value) {
        this.state.selectedFile[0].metadata = value;
    }

    setValueToSearch = () => {
        const { valueTotal, selectedFile, value } = this.state
        if (valueTotal.length > 0) {
            var objValue: any = []
            for (let j = 0; j < selectedFile.length; j++) {
                var objValueSub: any = []
                for (let i = 0; i < valueTotal.length; i++) {
                    let data = {}
                    data['label'] = valueTotal[i].value
                    data['value'] = valueTotal[i].value
                    objValueSub.push(data)
                }
                objValue.push(objValueSub)
            }
            this.setState({ value: objValue })
        }
    }

    public removeSelectedFile(atIndex: number) {
        this.setState(prevState => {
            const nextSelectedFiles = [...prevState.selectedFile];
            nextSelectedFiles.splice(atIndex, 1);

            return {
                ...prevState,
                selectedFile: nextSelectedFiles
            };
        });
    }

    public handleClickApplyAll(formValues: F1ConnectFormValues) {
        const keys: (keyof F1ConnectFormValues)[] = ['companyCode', 'documentType', 'fiscalYear', 'documentNo'];

        const prepareValue = keys
            .map(key => formValues[key])
            .filter(value => (value as string).length !== 0)
        const newGroupId = prepareValue.join('');
        const newSearchKey = prepareValue.map(value => ({ label: value, value }));

        this.setState({ groupId: newGroupId, valueTotal: newSearchKey });
    }

    render() {
        const { inputValue, value } = this.state;

        return (
            <div style={{ display: "inline" }}>

                {/* <FileDetailExportExcelComponent isReLoadFile={this.props.isReLoadFile} folderSelectedCode={this.props.folderSelectedCode} />
                &nbsp;&nbsp;

                <FileDetailImportExcelComponent isReLoadFile={this.props.isReLoadFile} folderSelectedCode={this.props.folderSelectedCode} />
                &nbsp;&nbsp; */}

                {/* {this.props.folderSelectedCode === "" ?
                    <label className="btn-manage-file-disable" title="Please select the folder."><FaUpload style={{ marginBottom: '4px' }} /> Upload File</label> :
                    <label htmlFor="inputUploadFile" className="btn-upload-file"><FaUpload style={{ marginBottom: '4px', }} /> Upload File</label>
                } */}
                <Button
                    disabled={this.props.disabled}
                    outline
                    className={(!this.props.disabled) ? 'btn-upload-file' : 'btn-manage-file-disable'}
                    onClick={() => this.inputFileElement.click()}
                >
                    <FaUpload style={{ marginBottom: '4px', marginRight: '4px' }} />
                    Upload File
                </Button>
                <input type="file" name="file" id="inputUploadFile" multiple onChange={this.handleFileChange}
                    className="inp-upload-file" ref={input => this.inputFileElement = input} />

                <Modal isOpen={this.state.isOpenModal} className="dms-modal modal-upload-file">
                    <ModalHeader toggle={this.toggleModal}>Upload File
                        {this.props.folderSelectedName !== "" ? ' To ' : ''}
                        <span style={{ color: "#117a8b" }}>{this.props.folderSelectedFullPath}.</span>
                    </ModalHeader>
                    <ModalBody>
                        <div className="div-file-upload-list">
                            <div>
                                <Row>
                                    <Col>
                                        <div className='trapezoid trapezoid-purple' style={{ width: 'auto', display: 'inline-block' }}>
                                            <span>
                                                Total Files : {this.state.selectedFile.length}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Row>
                                            <Col xs={6}>
                                                <Row className="align-items-center" style={{ gap: '6px 0' }}>
                                                    <Col xs={3} className="text-right font-weight-bold">
                                                        Group Id
                                                    </Col>
                                                    <Col xs={6} className="w-100 position-relative">
                                                        <Input
                                                            type="text"
                                                            value={this.state.groupId}
                                                            onChange={(e) => this.addTextvalue(e, "groupId")}
                                                        />
                                                    </Col>
                                                    <Col xs={3}></Col>

                                                    <Col xs={3} className="text-right font-weight-bold">
                                                        Search Key
                                                    </Col>
                                                    <Col xs={6} className="w-100 position-relative">
                                                        <CreatableSelect
                                                            components={components}
                                                            inputValue={this.state.inputValueTotal}
                                                            isClearable
                                                            isMulti
                                                            menuIsOpen={false}
                                                            onChange={(e) => this.handleChange(e, "Total", "")}
                                                            onInputChange={(e) => this.handleInputChange(e, "Total")}
                                                            onKeyDown={(e) => this.handleKeyDown(e, "Total")}
                                                            placeholder="Type something and press enter..."
                                                            value={this.state.valueTotal}
                                                        />
                                                        <span style={{ fontSize: "12px", position: 'absolute' }}>
                                                            หมายเหตุ: <span style={{ fontSize: "12px", color: "red" }}>กรุณากรองข้อความเเละกดปุ่ม Enter</span>
                                                        </span>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Button outline color="success" onClick={this.setValueToSearch}>Mass Maintain</Button>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col xs={6} className="border border-darken-1 rounded-lg py-3">
                                                <F1ConnectForm
                                                    folderSelectedCode={this.props.folderSelectedCode}
                                                    handleClickApplyAll={this.handleClickApplyAll}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {/* <Row className="align-items-center">
                                    <Col xs="2" style={{ fontWeight: "bold", textAlign: "right" }}>
                                        Sub Group Id
                                    </Col>
                                    <Col xs="4" className="w-100">
                                        <Input type="text" onChange={(e) => this.addTextvalue(e, "subGroupId")} />
                                    </Col>
                                </Row> */}
                                <Row>
                                    {/* <Col xs="1" style={{ alignSelf: "flex-end", textAlign: "right", fontWeight: "bold", marginLeft: '50px' }}>
                                        Box Id
                                    </Col>
                                    <Col xs="3" style={{ alignSelf: "flex-end", marginBottom: -7, marginLeft: '-10px' }}>
                                        <div style={{ width: "415px" }}>
                                            <Input type="text" style={{ width: "415px" }} onChange={(e) => this.addTextvalue(e, "boxId")} />
                                        </div>
                                    </Col> */}
                                </Row>
                                {/* <Row className="mt-4">
                                    <Col xs="2" style={{ alignSelf: "flex-end", textAlign: "right", fontWeight: "bold" }}>
                                        Relation Id
                                    </Col>
                                    <Col xs="3" style={{ alignSelf: "flex-end", marginBottom: -7, marginLeft: '-10px' }}>
                                        <div style={{ width: "415px" }}>
                                            <Input type="text" style={{ width: "415px" }} onChange={(e) => this.addTextvalue(e, "relation")} />
                                        </div>
                                    </Col>
                                    <Col xs="2"></Col>
                                    <Col></Col>
                                </Row> */}
                                <br />
                                <div className="row" style={{ padding: "5px" }}>
                                    <div className="col-12">
                                        <div className="form-group div-main-style">
                                            <table className="table custom-table table-hover ">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th className="td-icon th-center" style={{ width: '90px' }}>ID</th>
                                                        <th className="td-icon th-center" style={{ width: '90px' }}>
                                                            <img src={imgFile} alt="file" width="25" height="25" />
                                                        </th>
                                                        <th style={{ width: '130px' }}>Size</th>
                                                        <th className="td-detail">Primary Index<span style={{ color: "red" }}> *</span></th>
                                                        <th className="td-meta-data">Search Key</th>
                                                    </tr>
                                                </thead>
                                                {this.state.selectedFile.map((file, index) =>
                                                    <tbody key={index}>
                                                        <tr key={index}>
                                                            <td className="text-center">
                                                                <DeleteButton onClick={(_event) => this.removeSelectedFile(index)} />
                                                            </td>
                                                            <td className="th-center">{index + 1}</td>
                                                            <td className="td-icon th-center"><img src={this.previewFile(file)} alt="file" width="25" height="25" /></td>
                                                            <td>{this.fomatSizeFile(file.size)}</td>
                                                            <td>
                                                                <Input
                                                                    placeholder="Primary Index"
                                                                    type="text"
                                                                    value={(file.primaryIndex.split('.'))[0]}
                                                                    data-fileindex={index}
                                                                    onChange={this.handleChangePid}
                                                                />
                                                            </td>
                                                            <td style={{ width: '400px' }}><div>
                                                                <CreatableSelect
                                                                    id={index}
                                                                    components={components}
                                                                    inputValue={inputValue[index]}
                                                                    isClearable
                                                                    isMulti
                                                                    menuIsOpen={false}
                                                                    onChange={(e, a) => this.handleChange(e, a, index)}
                                                                    onInputChange={(e) => this.handleInputChange(e, "")}
                                                                    onKeyDown={(e) => this.handleKeyDown(e, "")}
                                                                    placeholder="Type something and press enter..."
                                                                    value={value[index]}
                                                                />
                                                            </div></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="p-0" colSpan={100}>
                                                                <Collapse isOpen={this.state.isOpenConfigMetadataMap.get(index)}>
                                                                    <CardBody className="p-2">
                                                                        {
                                                                            this.state.selectedFile[index].metadata.length > 0
                                                                            && (
                                                                                <Row>
                                                                                    <Col xs={3} className="font-weight-bold">Key</Col>
                                                                                    <Col xs={3} className="font-weight-bold">Value</Col>
                                                                                </Row>
                                                                            )
                                                                        }
                                                                        {
                                                                            this.state.selectedFile[index].metadata.map(([key, value], metadataIndex) => (
                                                                                <Row>
                                                                                    <KeyValuePairInput
                                                                                        showPlaceholder
                                                                                        bsSize="sm"
                                                                                        span={3}
                                                                                        valuePair={{ key, value }}
                                                                                        onChange={this.onMetadataChange(index, metadataIndex)}
                                                                                    />
                                                                                    <Col>
                                                                                        <Button
                                                                                            outline
                                                                                            size="sm"
                                                                                            color="danger"
                                                                                            onClick={(_event) => this.removeMetadata(index, metadataIndex)}
                                                                                        >
                                                                                            <FaMinus />
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>
                                                                            ))
                                                                        }
                                                                        <Row>
                                                                            <Col xs={6}>
                                                                                <Button
                                                                                    size="sm"
                                                                                    color="primary"
                                                                                    onClick={(_event) => this.addMetadata(index)}
                                                                                >
                                                                                    <FaPlus />
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                </Collapse>
                                                                <Button
                                                                    size="sm"
                                                                    color="info"
                                                                    className="w-100 p-0 rounded-0"
                                                                    onClick={() => this.toggleConfigMetaData(index)}
                                                                >
                                                                    Config Metadata {index + 1}
                                                                    &nbsp;
                                                                    <FaAngleDoubleDown
                                                                        style={{
                                                                            width: '18px',
                                                                            height: '18px',
                                                                            transition: 'transform 0.2s ease',
                                                                            transform: `rotate(${(this.state.isOpenConfigMetadataMap.get(index)) ? '-3.142rad' : '0'})`
                                                                        }}
                                                                    />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={100} className="p-1"></td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                                {
                                                    this.state.selectedFile.length === 0
                                                    && (
                                                        <td colSpan={100} className="text-center p-3">
                                                            <Button
                                                                disabled={this.props.disabled}
                                                                outline
                                                                className={(!this.props.disabled) ? 'btn-upload-file' : 'btn-manage-file-disable'}
                                                                onClick={() => this.inputFileElement.click()}
                                                            >
                                                                <FaUpload style={{ marginBottom: '4px', marginRight: '4px' }} />
                                                                Upload File
                                                            </Button>
                                                        </td>
                                                    )
                                                }
                                            </table>
                                        </div>
                                    </div>
                                    {/*<div className="col-12">
                                                <div className='trapezoid' style={{ width: 'auto', display: 'inline-block', marginLeft: '-15px' }}>
                                                    <span>
                                                        {index + 1}.  {file.name}  : {this.fomatSizeFile(file.size)}
                                                    </span>
                                                </div>
                                                <div className="row" style={{ border: "2px solid #3397c3", padding: "10px", borderRadius:"0px 7px 0px 7px" }}>
                                                    <div className="col-3" style={{textAlign:"center"}}>
                                                        <img src={this.previewFile(file)} width="120px" height="120px" />
                                                    </div>

                                                    <div className="col-9">
                                                        <div style={{ marginBottom: 9}}>
                                                            <InputGroup>
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>Primary Index <span style={{ color: "Red" }}> *</span></InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input placeholder="Primary Index" type="text"
                                                                    value={file.primaryIndex}
                                                                    data-fileindex={index}
                                                                    onChange={this.handleChangePid} />
                                                            </InputGroup>
                                                        </div>
                                                        <div style={{ marginBottom: 9 }}>
                                                            <InputGroup>
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>Search Key</InputGroupText>
                                                                </InputGroupAddon>
                                                                <div style={{ width: 'calc(100% - 105px)' }}>
                                                                    <CreatableSelect
                                                                        id={index}
                                                                        components={components}
                                                                        inputValue={inputValue[index]}
                                                                        isClearable
                                                                        isMulti
                                                                        menuIsOpen={false}
                                                                        onChange={this.handleChange}
                                                                        onInputChange={this.handleInputChange}
                                                                        onKeyDown={this.handleKeyDown}
                                                                        placeholder="Type something and press enter..."
                                                                        value={value[index]}/>
                                                                </div>
                                                            </InputGroup>
                                                        </div>
                                                        <div style={{ marginBottom: 0 }}>
                                                            <InputGroup>
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>Role Access</InputGroupText>
                                                                </InputGroupAddon>
                                                                <div style={{ width: 'calc(100% - 110px)' }}>
                                                            <SelectRoleAccess callback={this.getValueSelectRoleAccess.bind(this)}
                                                                itemIndex={index} />
                                                        </div>
                                                    </InputGroup>
                                                </div>


                                                    </div>
                                                </div>
                                            </div>*/}
                                    {/* <div className="col-12">
                                                <hr />
                                            </div>
                                            <div className="col-1 text-right">
                                                <b>{index+1} :</b>
                                            </div>
                                            <div className="col-3">
                                                <img src={this.previewFile(file)} width="100%" />
                                            </div>
                                            <div className="col-8">
                                                <div style={{float: "right"}}><b>Size</b> : {this.fomatSizeFile(file.size)} </div>
                                                <div style={{width: "calc(100% - 100px)"}}><b>File name</b> : {file.name}</div>

                                                <div style={{marginBottom: 7}}>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>Primary Index <span style={{ color: "red" }}> *</span></InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input placeholder="Primary Index" type="text"
                                                            value={file.primaryIndex}
                                                            data-fileindex={index}
                                                            onChange={this.handleChangePid} />
                                                    </InputGroup>
                                                </div>

                                                <div style={{ marginBottom: 7 }}>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>Search Key</InputGroupText>
                                                        </InputGroupAddon>
                                                        <div style={{ width: 'calc(100% - 105px)' }}>
                                                            <CreatableSelect
                                                                id={index}
                                                                components={components}
                                                                inputValue={inputValue[index]}
                                                                isClearable
                                                                isMulti
                                                                menuIsOpen={false}
                                                                onChange={this.handleChange}
                                                                onInputChange={this.handleInputChange}
                                                                onKeyDown={this.handleKeyDown}
                                                                placeholder="Type something and press enter..."
                                                                value={value[index]}
                                                            />
                                                        </div>
                                                    </InputGroup>
                                                </div>

                                                <div style={{ marginBottom: 7 }}>
                                                    <InputGroup>
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>Role Access</InputGroupText>
                                                        </InputGroupAddon>
                                                        {/*<Input placeholder="Role Access" type="text" value={this.state.selectedFile.role} onChange={this.handleChangeRole} />*/}
                                    {/*<div style={{ width: 'calc(100% - 110px)' }}>
                                                            <SelectRoleAccess callback={this.getValueSelectRoleAccess.bind(this)}
                                                                itemIndex={index} />
                                                        </div>
                                                    </InputGroup>
                                                </div>
                                            </div>*/}
                                </div>
                                {/* <Row>
                                    <Col>
                                        <div className='trapezoid trapezoid-purple' style={{ width: 'auto', display: 'inline-block' }}>
                                            <span>
                                                Metadata
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <Row>
                                            <KeyValuePairInput
                                                showPlaceholder
                                                span={4}
                                                bsSize="sm"
                                            />
                                            <Col>
                                                <Button
                                                    outline
                                                    color="primary"
                                                    size="sm"
                                               >
                                                    <FaPlus style={{ marginBottom: '4px' }} />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row> */}
                                {/* <InputMetadataComponect folderSelectedCode={this.props.folderSelectedCode} metadataGroup={this.props.metadataGroup} callback={this.getValueInputMetaData.bind(this)} /> */}

                                {/* end loop list files */}
                            </div>

                            {/*<div className="row" style={{ display: 'none' }}>                                                                                         */}
                            {/*    <div className="col-3">                                                                                                               */}
                            {/*        <img src={this.previewFile(this.state.selectedFile[0])} width="100%" />                                                           */}
                            {/*    </div>                                                                                                                                */}
                            {/*    <div className="col-9">                                                                                                               */}
                            {/*        <p><b>File name</b> : {this.state.selectedFile[0].name}</p>                                                                       */}
                            {/*        <p><b>Size</b> : {this.fomatSizeFile(this.state.selectedFile[0].size)} </p>                                                       */}
                            {/*                                                                                                                                          */}
                            {/*                                                                                                                                          */}
                            {/*        <InputGroup>                                                                                                                      */}
                            {/*            <InputGroupAddon addonType="prepend">                                                                                         */}
                            {/*                <InputGroupText>Search Key</InputGroupText>                                                                               */}
                            {/*            </InputGroupAddon>                                                                                                            */}
                            {/*            <div style={{ width: 'calc(100% - 105px)' }}>                                                                                 */}
                            {/*                <CreatableSelect                                                                                                          */}
                            {/*                    components={components}                                                                                               */}
                            {/*                    inputValue={inputValue}                                                                                               */}
                            {/*                    isClearable                                                                                                           */}
                            {/*                    isMulti                                                                                                               */}
                            {/*                    menuIsOpen={false}                                                                                                    */}
                            {/*                    onChange={this.handleChange}                                                                                          */}
                            {/*                    onInputChange={this.handleInputChange}                                                                                */}
                            {/*                    onKeyDown={this.handleKeyDown}                                                                                        */}
                            {/*                    placeholder="Type something and press enter..."                                                                       */}
                            {/*                    value={value}                                                                                                         */}
                            {/*                />                                                                                                                        */}
                            {/*            </div>                                                                                                                        */}
                            {/*        </InputGroup>                                                                                                                     */}
                            {/*        <br />                                                                                                                            */}
                            {/*        <InputGroup>                                                                                                                      */}
                            {/*            <InputGroupAddon addonType="prepend">                                                                                         */}
                            {/*                <InputGroupText>Primary Index <span style={{ color: "red" }}> *</span></InputGroupText>                                   */}
                            {/*            </InputGroupAddon>                                                                                                            */}
                            {/*            <Input placeholder="Primary Index" type="text" value={this.state.selectedFile[0].primaryIndex}                                */}
                            {/*                onChange={this.handleChangePid} />                                                                                        */}
                            {/*        </InputGroup>                                                                                                                     */}
                            {/*        <br />                                                                                                                            */}
                            {/*        <InputGroup>                                                                                                                      */}
                            {/*            <InputGroupAddon addonType="prepend">                                                                                         */}
                            {/*                <InputGroupText>Role Access</InputGroupText>                                                                              */}
                            {/*            </InputGroupAddon>                                                                                                            */}
                            {/*            <div style={{ width: 'calc(100% - 110px)' }}>                                                                                 */}
                            {/*                <SelectRoleAccess callback={this.getValueSelectRoleAccess.bind(this)} />                                                  */}
                            {/*            </div>                                                                                                                        */}
                            {/*        </InputGroup>                                                                                                                     */}
                            {/*        <br />                                                                                                                            */}
                            {/*        <InputMetadataComponect folderSelectedCode={this.props.folderSelectedCode} callback={this.getValueInputMetaData.bind(this)} />    */}
                            {/*    </div>                                                                                                                                */}
                            {/*</div>                                                                                                                                    */}
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <p style={{ color: "red" }}>{this.state.alertSaveClick}</p>
                        <Button color="success" onClick={this.uploadClick}>Save & Upload</Button>
                        <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.fileUpload, FileUploadStore.actionCreators
)(UploadFileComponent);
