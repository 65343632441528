import * as React from 'react';
import { Redirect } from "react-router";

class SignOut extends React.Component {

    constructor(props: any) {
        super(props);
    }

    public render() {
        localStorage.removeItem('DMS_APPLICATION');
        
        return (<Redirect to="/auth/signin" />)
    }
}

export default SignOut;