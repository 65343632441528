import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/AccessManagement';
import * as Models from '../../models/AccessManagement';
import * as StoresActionLog from '../../store/ActionLog';
import * as ModelsActionLog from '../../models/ActionLog';
import * as GenerateTokenStore from '../../store/GenerateToken'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroupAddon, InputGroupText, Input, Col, Row, Spinner, Table } from 'reactstrap';
import { InputGroup, FormControl, ButtonGroup, Form } from 'react-bootstrap'
import { FaPlusCircle, FaEdit, FaTimesCircle, FaList, FaRegCopy, FaKey, FaCheckCircle } from 'react-icons/fa';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Swal from 'sweetalert2';

type Store = Models.AccessManagementState & typeof Stores.actionCreators | ModelsActionLog.ActionLogState & typeof Stores.actionCreators | Models.GenerateTokenState & typeof GenerateTokenStore.actionCreators;

class AccessManagement extends React.PureComponent<any, { pageLoad: boolean, isOpenModal: boolean, modelAccessManagement: Models.AccessManagement, manageMode: string, version: number, isShowTempModal: boolean, modelListGet: Array<Models.AccessManagement>, modelListGetActionLog: Array<ModelsActionLog.ActionLog>, company: string, checkGenerate: boolean }, Store> {

    constructor(props: any) {
        super(props);

        this.state = {
            pageLoad: true,
            isOpenModal: false,
            modelAccessManagement: {
                sid: "",
                legacy_id: "",
                token: "",
                private_key: "",
                address: "",
                bucket_id: "",
                is_active: "Y",
                created_by: "created_by",
                created_on: "created_on",
                updated_by: "updated_by",
                updated_on: "updated_on",
            },
            manageMode: "",
            version: 0,
            modelListGet: [],
            modelListGetActionLog: [],
            isShowTempModal: false,
            company: "",
            checkGenerate: false
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.replaceDate = this.replaceDate.bind(this);
        this.handleCloseTemModal = this.handleCloseTemModal.bind(this);
        //this.handleChangeMataDataName = this.handleChangeMataDataName.bind(this);
        //this.actionsClick = this.actionsClick.bind(this)
    }

    public componentDidMount() {
        //this.ensureDataFetched();

        //if (this.state.company !== this.props.company) {
        //    this.setState({
        //        company: this.props.company
        //    })
        //}
        if (this.state.company !== this.props.company) {
            this.setState({
                company: this.props.company
            })
            this.ensureDataFetched();
        }
    }

    componentDidUpdate() {
        if (this.state.company !== this.props.company) {
            this.setState({
                company: this.props.company
            })
            this.ensureDataFetched();
        }

        if (this.props.isLoadingGenerate && this.props.statusGenerate === 200) {
            var dataArr: Models.AccessManagement = this.state.modelAccessManagement
            dataArr.token = this.props.responseGenerate.token
            this.setState({
                modelAccessManagement: dataArr
            })
            this.props.requestGenerateToken(true, "CLEAR", "", "", "", "")
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        } else if (this.props.isLoadingGenerate && this.props.statusGenerate === 204) {
            var msg = JSON.parse(this.props.messageGenerate)
            this.props.requestGenerateToken(true, "CLEAR", "", "", "", "")
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: msg.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ตกลง'
            })
        }
    }

    private ensureDataFetched() {
        this.setState({
            modelListGet: [],
        })
        this.props.requestFunctionAccessManagement(this.state.version, "GET", this.state.modelAccessManagement, this.props.company);
    }

    private toggleModal() {
        this.setState({
            isOpenModal: !this.state.isOpenModal
        });
    }

    private replaceDate(e: string) {

        var date = "";
        if (e !== null) {
            if (e.length >= 10) {
                var date = e.substring(0, 10);
            }
        }

        return date;
    }

    private handleChangeAccessManagement(e: any, key: string) {
        var data = this.state.modelAccessManagement;
        data[key] = e.target.value;

        this.setState({
            modelAccessManagement: data
        });

    }

    //private handleChangeMataDataName(e: any) {
    //    var data = this.state.modelMetaData;
    //    data.metadata_name = e.target.value;

    //    this.setState({
    //        modelMetaData: data
    //    });

    //}

    private actionsClick(type) {
        const { modelAccessManagement } = this.state
        if (modelAccessManagement.legacy_id !== "" && modelAccessManagement.token !== "") {
            this.props.requestFunctionAccessManagement(this.state.version, type, this.state.modelAccessManagement, this.props.company);
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        } else {
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: 'โปรดกรอกข้อมูลให้ครบถ้วน',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            })
        }
    }

    openModal = (type: string, dataAccessManagement?: Models.AccessManagement) => {
        var data = this.state.modelAccessManagement;
        if (type === "Add") {
            data.sid = this.props.company
            data.legacy_id = ""
            data.token = ""
            data.private_key = ""
            data.address = ""
            data.bucket_id = ""
            data.is_active = ""
        } else if (type === "Edit" && dataAccessManagement !== undefined) {
            data.sid = dataAccessManagement.sid
            data.legacy_id = dataAccessManagement.legacy_id
            data.token = dataAccessManagement.token
            data.private_key = dataAccessManagement.private_key
            data.address = dataAccessManagement.address
            data.bucket_id = dataAccessManagement.bucket_id
            data.is_active = dataAccessManagement.is_active
        }

        this.setState({ manageMode: type, modelAccessManagement: data });

        this.toggleModal();
    }

    setBodyModal = () => {
        const { modelAccessManagement } = this.state
        if (this.state.manageMode === "Add") {
            return <div>
                <Row>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>SID</Form.Label>
                            <Form.Control type="text" placeholder="" onChange={(e) => this.handleChangeAccessManagement(e, "sid")} readOnly defaultValue={this.state.modelAccessManagement.sid} />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Legacy ID</Form.Label>
                            <Form.Control type="text" placeholder="" onChange={(e) => this.handleChangeAccessManagement(e, "legacy_id")} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Form.Label>Token </Form.Label>
                        <InputGroup>
                            <FormControl as="textarea" aria-label="With textarea"
                                rows={3}
                                readOnly={true}
                                placeholder=""
                                onChange={(e) => this.handleChangeAccessManagement(e, "token")}
                                value={this.state.modelAccessManagement.token}
                            />
                            <InputGroup.Append>
                                <ButtonGroup vertical>
                                    <Button variant="info" onClick={() => this.generateToken()}><FaKey /></Button>
                                    <CopyToClipboard onCopy={this.onCopy} text={modelAccessManagement.token}>
                                        <Button variant="primary"><FaRegCopy /></Button>
                                    </CopyToClipboard>
                                </ButtonGroup>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Private Key</Form.Label>
                            <Form.Control type="text" placeholder="" onChange={(e) => this.handleChangeAccessManagement(e, "private_key")} />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="text" placeholder="" onChange={(e) => this.handleChangeAccessManagement(e, "address")} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            {/*<Form.Label>Bucket ID</Form.Label>*/}
                            <Form.Label>Folder ID</Form.Label>
                            <Form.Control type="text" placeholder="" onChange={(e) => this.handleChangeAccessManagement(e, "bucket_id")} />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col>
                                    <Form.Label>Is Active</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BootstrapSwitchButton
                                        checked={this.state.modelAccessManagement.is_active === "Y"
                                            ? true
                                            : false
                                        }
                                        onlabel='Y'
                                        offlabel='N'
                                        onChange={(checked: boolean) => {
                                            this.onchangeSwitch(checked, 'is_active')
                                        }}
                                        size="sm"
                                        onstyle="info"
                                        offstyle="dark"
                                        width={80}
                                    />
                                </Col>
                            </Row>
                            {/*<Form.Control type="text" placeholder="" onChange={(e) => this.handleChangeAccessManagement(e, "is_active")} />*/}
                        </Form.Group>
                    </Col>
                </Row>

            </div>;
        } else if (this.state.manageMode === "Edit") {
            return <div>
                <Row>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>SID</Form.Label>
                            <Form.Control type="text" placeholder="" onChange={(e) => this.handleChangeAccessManagement(e, "sid")} readOnly defaultValue={this.state.modelAccessManagement.sid} />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Legacy ID</Form.Label>
                            <Form.Control type="text" placeholder="" onChange={(e) => this.handleChangeAccessManagement(e, "legacy_id")} readOnly defaultValue={modelAccessManagement.legacy_id} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Form.Label>Token </Form.Label>
                        <InputGroup>
                            <FormControl as="textarea" aria-label="With textarea"
                                rows={3}
                                readOnly={true}
                                placeholder=""
                                onChange={(e) => this.handleChangeAccessManagement(e, "token")}
                                value={this.state.modelAccessManagement.token}
                            />
                            <InputGroup.Append>
                                <ButtonGroup vertical>
                                    <Button variant="info" onClick={() => this.generateToken()} readOnly><FaKey /></Button>
                                    <CopyToClipboard onCopy={this.onCopy} text={modelAccessManagement.token}>
                                        <Button variant="primary" readOnly><FaRegCopy /></Button>
                                    </CopyToClipboard>
                                </ButtonGroup>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Private Key</Form.Label>
                            <Form.Control type="text" placeholder="" onChange={(e) => this.handleChangeAccessManagement(e, "private_key")} defaultValue={modelAccessManagement.private_key} />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="text" placeholder="" onChange={(e) => this.handleChangeAccessManagement(e, "address")} defaultValue={modelAccessManagement.address} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            {/*<Form.Label>Bucket ID</Form.Label>*/}
                            <Form.Label>Folder ID</Form.Label>
                            <Form.Control type="text" placeholder="" onChange={(e) => this.handleChangeAccessManagement(e, "bucket_id")} defaultValue={modelAccessManagement.bucket_id} />
                        </Form.Group>
                    </Col>
                    <Col lg={6}>
                        <Form.Group className="mb-3">
                            <Row>
                                <Col>
                                    <Form.Label>Is Active</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BootstrapSwitchButton
                                        checked={this.state.modelAccessManagement.is_active === "Y"
                                            ? true
                                            : false
                                        }
                                        onlabel='Y'
                                        offlabel='N'
                                        onChange={(checked: boolean) => {
                                            this.onchangeSwitch(checked, 'is_active')
                                        }}
                                        size="sm"
                                        onstyle="info"
                                        offstyle="dark"
                                        width={80}
                                    />
                                </Col>
                            </Row>
                            {/*<Form.Control type="text" placeholder="" onChange={(e) => this.handleChangeAccessManagement(e, "is_active")} defaultValue={modelAccessManagement.is_active} />*/}
                        </Form.Group>
                    </Col>
                </Row>


                {/*<Row>*/}
                {/*    <Col lg={6}>*/}
                {/*        <InputGroup>*/}
                {/*            <InputGroupAddon addonType="prepend">*/}
                {/*                <InputGroupText className="metadata-font">SID</InputGroupText>*/}
                {/*            </InputGroupAddon>*/}
                {/*            <Input placeholder="" type="text" defaultValue={modelAccessManagement.sid} onChange={(e) => this.handleChangeAccessManagement(e, "sid")} readOnly />*/}
                {/*        </InputGroup>*/}
                {/*    </Col>*/}
                {/*    <Col lg={6}>*/}
                {/*        <InputGroup>*/}
                {/*            <InputGroupAddon addonType="prepend">*/}
                {/*                <InputGroupText className="metadata-font">Legacy ID</InputGroupText>*/}
                {/*            </InputGroupAddon>*/}
                {/*            <Input placeholder="" type="text" defaultValue={modelAccessManagement.legacy_id} onChange={(e) => this.handleChangeAccessManagement(e, "legacy_id")} readOnly />*/}
                {/*        </InputGroup>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<br />*/}
                {/*<Row>*/}
                {/*    <Col lg={6}>*/}
                {/*        <InputGroup>*/}
                {/*            <InputGroupAddon addonType="prepend">*/}
                {/*                <InputGroupText className="metadata-font">Token</InputGroupText>*/}
                {/*            </InputGroupAddon>*/}
                {/*            <Input placeholder="" type="text" defaultValue={modelAccessManagement.token} onChange={(e) => this.handleChangeAccessManagement(e, "token")} readOnly />*/}
                {/*        </InputGroup>*/}
                {/*    </Col>*/}
                {/*    <Col lg={6}>*/}
                {/*        <InputGroup>*/}
                {/*            <InputGroupAddon addonType="prepend">*/}
                {/*                <InputGroupText className="metadata-font">Private Key</InputGroupText>*/}
                {/*            </InputGroupAddon>*/}
                {/*            <Input placeholder="" type="text" defaultValue={modelAccessManagement.private_key} onChange={(e) => this.handleChangeAccessManagement(e, "private_key")} />*/}
                {/*        </InputGroup>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<br />*/}
                {/*<Row>*/}
                {/*    <Col lg={6}>*/}
                {/*        <InputGroup>*/}
                {/*            <InputGroupAddon addonType="prepend">*/}
                {/*                <InputGroupText className="metadata-font">Address</InputGroupText>*/}
                {/*            </InputGroupAddon>*/}
                {/*            <Input placeholder="" type="text" defaultValue={modelAccessManagement.address} onChange={(e) => this.handleChangeAccessManagement(e, "address")} />*/}
                {/*        </InputGroup>*/}
                {/*    </Col>*/}
                {/*    <Col lg={6}>*/}
                {/*        <InputGroup>*/}
                {/*            <InputGroupAddon addonType="prepend">*/}
                {/*                <InputGroupText className="metadata-font">Bucket ID</InputGroupText>*/}
                {/*            </InputGroupAddon>*/}
                {/*            <Input placeholder="" type="text" defaultValue={modelAccessManagement.bucket_id} onChange={(e) => this.handleChangeAccessManagement(e, "bucket_id")} />*/}
                {/*        </InputGroup>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<br />*/}
                {/*<Row>*/}
                {/*    <Col lg={6}>*/}
                {/*        <InputGroup>*/}
                {/*            <InputGroupAddon addonType="prepend">*/}
                {/*                <InputGroupText className="metadata-font">Is Active</InputGroupText>*/}
                {/*            </InputGroupAddon>*/}
                {/*            <Input placeholder="" type="text" defaultValue={modelAccessManagement.is_active} onChange={(e) => this.handleChangeAccessManagement(e, "is_active")} />*/}
                {/*        </InputGroup>*/}
                {/*    </Col>*/}
                {/*</Row>*/}

            </div>;
        }
    }

    setButtonModal = () => {

        if (this.state.manageMode === "Add") {
            return <Button color="primary" className="metadata-font" onClick={() => this.actionsClick('POST')}>Add</Button>;
        } else if (this.state.manageMode === "Edit") {
            return <Button color="warning" className="metadata-font" onClick={() => this.actionsClick('PUT')}>Edit</Button>;
        }

    }

    handleCloseTemModal = () => {
        if (this.state.isShowTempModal === false) {
            this.setState({
                isShowTempModal: true
            })
            this.props.requestFunctionActionLog(this.state.version, "GET");
        } else {
            this.props.requestFunctionActionLog(this.state.version, "CLEAR");
            this.setState({
                isShowTempModal: false,
                modelListGetActionLog: []
            })
        }
    }

    generateToken = () => {
        const { modelAccessManagement } = this.state
        console.log('sid :' + modelAccessManagement.sid)
        console.log('legacy_id :' + modelAccessManagement.legacy_id)
        console.log('address :' + modelAccessManagement.address)
        console.log('bucket_id :' + modelAccessManagement.bucket_id)
        if (modelAccessManagement.sid !== "" || modelAccessManagement.legacy_id !== "" || modelAccessManagement.address !== "" || modelAccessManagement.bucket_id !== "") {
            this.props.requestGenerateToken(true, "GET", modelAccessManagement.sid, modelAccessManagement.legacy_id, modelAccessManagement.address, modelAccessManagement.bucket_id)
            this.setState({
                checkGenerate: true
            })
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";
        } else {
            /*alertError*/
            Swal.fire({
                title: 'ไม่สำเร็จ!',
                text: 'กรุณาป้อนข้อมูลให้ครบถ้วน',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'ยืนยัน!'
            }).then((result) => {
                if (result.value) {
                }
            })
        }
    }

    onCopy = () => {
        //this.setState({ copied: true });
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: 'Copy success!'
        })
    };

    onchangeSwitch = (value: any, key: any) => {
        var data: Models.AccessManagement = this.state.modelAccessManagement
        if (value === true) {
            data[key] = "Y"
        } else {
            data[key] = "N"
        }
        this.setState({
            modelAccessManagement: data
        })
    }

    render() {
        console.log('this.props.responseActionLog')
        console.log(this.props.responseActionLog)
        const { responseActionLog, isLoadingActionLog } = this.props
        if (this.props.isLoading) {
            this.setState({ modelListGet: this.props.response.result_list as Array<Models.AccessManagement>, isOpenModal: false });
            document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
        }
        if (isLoadingActionLog) {
            this.setState({ modelListGetActionLog: responseActionLog.result_list as Array<ModelsActionLog.ActionLog>, isOpenModal: false });
        }
        return (
            <div className="div-meta-data-main div-meta-role">
                <div>
                    <div className='trapezoid' style={{ width: 'auto', display: 'inline-block' }}>
                        <span className="metadata-font">
                            Access Management
                        </span>
                    </div>
                    <div style={{ display: 'inline', float: 'right' }}>
                        <Button className="btn-add metadata-font" onClick={() => this.openModal('Add', undefined)}> <FaPlusCircle style={{ marginBottom: '4px' }} /> Add</Button>

                    </div>
                </div>
                <Table className='table table-hover' size="sm" bordered>
                    <thead className="text-center table-active">
                        <tr>
                            <th style={{ width: '3%' }}>No</th>
                            <th style={{ width: '15%' }}>SID</th>
                            <th style={{ width: '8%' }}>Legacy ID</th>
                            <th style={{ width: '32%' }}>Token</th>
                            <th style={{ width: '10%' }}>Private Key</th>
                            <th style={{ width: '10%' }}>Address</th>
                            <th style={{ width: '7%' }}>Folder ID</th>
                            <th style={{ width: '5%' }}>Is Active</th>
                            <th style={{ width: '10%' }}>Menu</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoadingActionLog && this.state.modelListGet.length === 0 && <tr><th className="td-code text-center" colSpan={9}><Spinner color="dark" size="sm" /> Loading...</th></tr>}
                        {isLoadingActionLog && this.state.modelListGet.length === 0 && <tr><th className="td-code text-center" colSpan={9}>No Data...</th></tr>}
                        {this.state.modelListGet !== undefined && this.state.modelListGet.length > 0 && this.state.modelListGet.map((item: Models.AccessManagement, idx: any) =>
                            <tr key={idx} className="table-tbody">
                                <td className="text-center">{idx + 1}</td>
                                <td className="td-code">{item.sid}</td>
                                <td className="td-name">{item.legacy_id}</td>
                                <td className="td-update-on" >
                                    <InputGroup>
                                        <FormControl as="textarea" aria-label="With textarea"
                                            rows={3}
                                            readOnly={true}
                                            placeholder=""
                                            value={item.token}
                                        />
                                        <InputGroup.Append>
                                            <ButtonGroup vertical>
                                                {/*<Button variant="info" onClick={() => this.generateToken(item.sid, idx)}><FaKey /></Button>*/}
                                                <CopyToClipboard onCopy={this.onCopy} text={item.token}>
                                                    <Button variant="primary"><FaRegCopy /></Button>
                                                </CopyToClipboard>
                                            </ButtonGroup>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </td>
                                <td className="td-update-by" >{item.private_key}</td>
                                <td className="td-menu" >{item.address}</td>
                                <td className="td-menu" >{item.bucket_id}</td>
                                <td className="td-menu text-center" >{item.is_active === "Y" ? <FaCheckCircle size="24px" color="green" /> : <FaTimesCircle size="24px" color="red" />}</td>
                                <td className="td-menu" >
                                    <Button className="btn-edit-file" size="sm" onClick={() => this.openModal('Edit', item)}><FaEdit style={{ marginBottom: '4px' }} /> Edit</Button>{' '}
                                    <Button className="btn-copy-file" size="sm" style={{ padding: '3px 5px' }} onClick={() => this.handleCloseTemModal()}><FaList style={{ marginBottom: '2px' }} /> View</Button>
                                </td>
                            </tr>
                        )}

                        {this.state.modelListGet === null ? <tr><td colSpan={5} style={{ color: "black", fontSize: '10pt', textAlign: "center" }}>No data.</td></tr> : ''}
                    </tbody>
                </Table>

                <Modal isOpen={this.state.isOpenModal} className="modal-upload-file">
                    <ModalHeader className="metadata-font" toggle={this.toggleModal}>{this.state.manageMode + ' Access Management'}</ModalHeader>
                    <ModalBody>

                        {this.setBodyModal()}

                    </ModalBody>
                    <ModalFooter>
                        {this.setButtonModal()}
                        <Button color="secondary" className="metadata-font" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                {/*Create From Template*/}
                <Modal isOpen={this.state.isShowTempModal} size="lg" backdrop="static" keyboard={false} className="modal-upload-file">
                    <ModalHeader className="metadata-font" toggle={this.handleCloseTemModal}>{'Action Log'}</ModalHeader>
                    <ModalBody>
                        <Row>
                            <table className='table custom-table table-hover'>
                                <thead>
                                    <tr>
                                        <th className="td-code">No</th>
                                        <th className="td-code">SID</th>
                                        <th className="td-update-on">Legacy ID</th>
                                        <th className="td-name" >Token</th>
                                        <th className="td-update-by" >Address</th>
                                        <th className="td-menu" >Action</th>
                                        <th className="td-menu" >Access Url</th>
                                        <th className="td-menu" >Access Bucket</th>
                                        <th className="td-menu" >File ID</th>
                                        <th className="td-menu" >Primary Index</th>
                                        <th className="td-menu" >Access On</th>
                                        <th className="td-menu" >Log Type</th>
                                        <th className="td-menu" >GUID</th>
                                        <th className="td-menu" >Message</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {this.state.modelListGetActionLog !== undefined && this.state.modelListGetActionLog.length > 0 && this.state.modelListGetActionLog.map((item: ModelsActionLog.ActionLog, idx: any) =>
                                        <tr key={idx} className="table-tbody">

                                            <td className="td-code">{idx + 1}</td>
                                            <td className="td-code">{item.sid}</td>
                                            <td className="td-name">{item.legacy_id}</td>
                                            <td className="td-update-on" >
                                                {item.token}
                                            </td>
                                            <td className="td-update-by" >{item.address}</td>
                                            <td className="td-menu" >{item.action}</td>
                                            <td className="td-menu" >{item.access_url}</td>
                                            <td className="td-menu" >{item.access_bucket}</td>
                                            <td className="td-menu" >{item.file_id}</td>
                                            <td className="td-menu" >{item.primary_index}</td>
                                            <td className="td-menu" >{item.access_on}</td>
                                            <td className="td-menu" >{item.log_type}</td>
                                            <td className="td-menu" >{item.message}</td>
                                        </tr>
                                    )}

                                    {this.state.modelListGetActionLog === null ? <tr><td colSpan={5} style={{ color: "black", fontSize: '10pt', textAlign: "center" }}>No data.</td></tr> : ''}
                                </tbody>
                            </table>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="danger" onClick={this.handleCloseTemModal}>
                            <FaTimesCircle /> Close
                        </Button>
                    </ModalFooter>
                </Modal>

            </div >
        );
    }
}

export default connect((state: ApplicationState) => ({ ...state.accessManagement, ...state.actionLog, ...state.appState, ...state.generateToken }),
    ({ ...Stores.actionCreators, ...StoresActionLog.actionCreators, ...GenerateTokenStore.actionCreators })
)(AccessManagement);