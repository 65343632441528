import * as QueryString from 'query-string';
import * as React from 'react';
import { Redirect } from 'react-router';
import FileComponent from '../../components/dms/FilesComponent';
import { parseJwt } from '../../modules/common';

type State = {
    isLogged: 'pending' | 'success' | 'failed';
    loggingError?: string;
};

class Ingress extends React.Component<any, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            isLogged: 'pending',
        };
    }

    componentDidMount() {
        window.history.pushState({}, 'ingress', '/');
        console.log('/Ingress');
        const { token } = QueryString.parse(this.props.location.search);

        if (!token) return;

        var jwt = parseJwt(String(token));
        var extra = JSON.parse(jwt.extra);
        var data = {
            access_token: token,
        };

        localStorage.removeItem('DMS_APPLICATION');
        localStorage.setItem('DMS_APPLICATION', JSON.stringify(data));

        this.logAuthenticationHistory(extra.citizen_id);
    }

    logAuthenticationHistory = async (
        citizenId: string,
        onSuccess?: () => void
    ) => {
        try {
            this.setState({ isLogged: 'pending' });

            const body = {
                citizenId,
                mode: localStorage.getItem('mode') || 'web',
            };

            const response = await fetch('/v1/log-auth-history', {
                method: 'POST',
                headers: {
                    Authorization:
                        'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });
            if (!response.ok) {
                throw new Error(await response.json());
            }

            this.setState({ isLogged: 'success' });
            onSuccess?.();
        } catch (error) {
            console.error('log-auth-history', error);

            if (error instanceof Error) {
                console.error('logging authentication error:', error.message);
                this.setState({
                    isLogged: 'failed',
                    loggingError: JSON.stringify(error.message),
                });
            }
        }
    };

    render() {
        const { isLogged, loggingError } = this.state;

        if (isLogged === 'pending') return null;

        if (isLogged === 'failed') {
            return (
                <div>
                    <p className="font-bold">Failed to logged authentication</p>
                    <p>Error: {loggingError}</p>
                </div>
            );
        }

        return <Redirect to="/" />;
    }
}

export default Ingress;
