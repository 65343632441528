import { Action, Reducer, combineReducers } from 'redux';
import { AppThunkAction } from './';
// models
import * as Models from '../models/Employee';

// #region interface AddImportMasterEmployee
interface BeforeAddImportMasterEmployee {
    type: 'ADD_IMPORTMASTEREMPLOYEE'
}
interface AddImportMasterEmployee {
    type: 'ADDIMPORTMASTEREMPLOYEE'
    response: string
    status: number,
    statusMessage: String
}
interface AddFailImportMasterEmployee {
    type: 'ADDIMPORTMASTEREMPLOYEEFAIL'
    status: number,
    statusMessage: any
}
interface ClearAddImportMasterEmployee {
    type: 'CLEARADDIMPORTMASTEREMPLOYEE'
}
// #endregion


type KnownAction = BeforeAddImportMasterEmployee | AddImportMasterEmployee | AddFailImportMasterEmployee | ClearAddImportMasterEmployee;

export const actionCreators = {
    requestImportMasterEmployee: (p: boolean, method: string, formData: Models.employeeListOld): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.ImportExcel && p && method === 'POST') {
            fetch(`/v1/user/import-employee`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: JSON.stringify(formData)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err)
                            dispatch({ type: 'ADDIMPORTMASTEREMPLOYEEFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        dispatch({ type: 'ADDIMPORTMASTEREMPLOYEE', response: 'success', status: 200, statusMessage: '' });
                    }
                })
            dispatch({ type: 'ADD_IMPORTMASTEREMPLOYEE' });
        } else if (method === 'CLEAR') {
            dispatch({ type: 'CLEARADDIMPORTMASTEREMPLOYEE' });
        }
    }
}

const unloadedImportState: Models.ImportEmployeeState = { isLaoadAddImportMasterEmployee: false };

const reducerImport: Reducer<Models.ImportEmployeeState> = (state: Models.ImportEmployeeState | undefined, incomingAction: Action): Models.ImportEmployeeState => {
    if (state === undefined) {
        return unloadedImportState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ADD_IMPORTMASTEREMPLOYEE':
            return {
                isLaoadAddImportMasterEmployee: false,
            }

        case 'ADDIMPORTMASTEREMPLOYEE':
            return {
                isLaoadAddImportMasterEmployee: true,
                statusAddImportMasterEmployee: action.status
            }

        case 'ADDIMPORTMASTEREMPLOYEEFAIL':
            return {
                isLaoadAddImportMasterEmployee: true,
                messageAddImportMasterEmployee: action.statusMessage,
                statusAddImportMasterEmployee: action.status
            }

        case 'CLEARADDIMPORTMASTEREMPLOYEE':
            return {
                isLaoadAddImportMasterEmployee: false,
                messageAddImportMasterEmployee: '',
                statusAddImportMasterEmployee: 400
            }
        default: return state;
    }
}

export const rootReducer = combineReducers({ ImportEmployee: reducerImport });