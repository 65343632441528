import React, { DetailedHTMLProps } from 'react';
import { FaMinusCircle } from 'react-icons/fa';

interface ComponentProps {
    iconSize?: string
}
type Props =
    & ComponentProps
    & DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

function DeleteButton(props: Props) {
    const {
        iconSize = '20px'
    } = props;

    return (
        <span
            className="text-danger"
            style={{ cursor: 'pointer' }}
            {...props}
        >
            <FaMinusCircle style={{ height: iconSize, width: iconSize }} />
        </span>
    );
}

export default DeleteButton;