import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as Employee from '../models/Employee';

interface RequestFunctionAction {
    type: 'REQUEST_EMPLOYEEMASTER';
    version: number;
    status: number;
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_EMPLOYEEMASTER';
    model: Employee.ResponseEmployee;
    version: number;
    status: number;
    displayEmployee?: Employee.DisplayEmployee[];
}

interface ClearFunctionAction {
    type: 'CLEAR_EMPLOYEEMASTER';
    status: number;
}

interface WhaitFunctionAction {
    type: 'WHAIT_EMPLOYEEMASTER';
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction | ClearFunctionAction | WhaitFunctionAction

export const actionCreators = {
    requestFunction: (version: number, method: string, body: Employee.EmployeeMain, isUploadExcel: boolean, FileUpload: any, sid: string, id: string, role_access: string, page: string, page_size: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.fileDetail && method === "GET") {

            fetch(`/v1/user?sid=` + sid + `&id=` + id + `&role_access=` + role_access + `&page=` + page + `&page_size=` + page_size, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION')
                }
            })
                .then(response => {
                    return response.json() as Promise<Employee.ResponseEmployee>
                })
                .then(data => {
                    //console.log('[Sakaz Debug] data =', data);

                    const displayEmployee: Employee.DisplayEmployee[] = [];
                    displayEmployee.push({ label: 'select all user', value: '*' });
                    data?.result_list.map(e => displayEmployee.push({ label: e.id, value: e.id }));

                    dispatch({ type: 'RECEIVE_EMPLOYEEMASTER', model: data, version: version + 2, status: 200, displayEmployee });
                })
                .catch(error => {
                    dispatch({ type: 'REQUEST_EMPLOYEEMASTER', version: version + 1, status: 204 });
                });
        }

        if (appState && appState.fileDetail && method === "POST" && !isUploadExcel) {
            fetch(`/v1/user/create?page=` + page + `&page_size=` + page_size, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
            })
                .then(response => {
                    return response
                })
                .then(data => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'REQUEST_EMPLOYEEMASTER', version: version + 1, status: 204 });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'RECEIVE_EMPLOYEEMASTER', model: data, version: version + 3, status: 200 });
                        })
                    }

                })
        }

        if (appState && appState.fileDetail && method === "POST" && isUploadExcel) {// upload

            const formData = new FormData();
            formData.append("files", FileUpload[0]);
            console.log(FileUpload[0]);

            fetch(`/v1/user/import`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION')
                },
                body: formData,
            })
                .then(response => {
                    return response.json() as Promise<Employee.ResponseEmployee>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_EMPLOYEEMASTER', model: data, version: version + 4, status: 200 });
                })
                .catch(error => {
                    dispatch({ type: 'REQUEST_EMPLOYEEMASTER', version: version + 1, status: 204 });
                });
        }

        if (appState && appState.fileDetail && method === "PUT") {

            fetch(`/v1/user/edit?page=` + page + `&page_size=` + page_size, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
            })
                .then(response => {
                    return response.json() as Promise<Employee.ResponseEmployee>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_EMPLOYEEMASTER', model: data, version: version + 5, status: 200 });
                })
                .catch(error => {
                    dispatch({ type: 'REQUEST_EMPLOYEEMASTER', version: version + 1, status: 204 });
                });
        }

        if (appState && appState.fileDetail && method === "DELETE") {

            fetch(`/v1/user?id=` + body.id + `&page=` + page + ` &page_size=` + page_size, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION')
                }
            })
                .then(response => {
                    return response.json() as Promise<Employee.ResponseEmployee>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_EMPLOYEEMASTER', model: data, version: version + 6, status: 200 });
                })
                .catch(error => {
                    dispatch({ type: 'REQUEST_EMPLOYEEMASTER', version: version + 1, status: 204 });
                });
        }

        dispatch({ type: 'WHAIT_EMPLOYEEMASTER' });

        if (method === "CLEAR") {
            dispatch({ type: 'CLEAR_EMPLOYEEMASTER', status: 400 });
        }

    }
};

const unloadedState: Employee.EmployeeState = { isLoadingEmployee: false, responseEmployee: undefined, versionEmployee: 0, statusEmployee: 0, displayEmployee: [] };

export const reducer: Reducer<Employee.EmployeeState> = (state: Employee.EmployeeState | undefined, incomingAction: Action): Employee.EmployeeState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'WHAIT_EMPLOYEEMASTER':
            return {
                ...state,
                isLoadingEmployee: false,
                statusEmployee: 400,
            };
        case 'REQUEST_EMPLOYEEMASTER':
            return {
                ...state,
                isLoadingEmployee: false,
                versionEmployee: action.version,
                statusEmployee: action.status,
            };
        case 'RECEIVE_EMPLOYEEMASTER':
            return {
                ...state,
                isLoadingEmployee: true,
                responseEmployee: action.model,
                versionEmployee: action.version,
                statusEmployee: action.status,
                displayEmployee: action.displayEmployee,
            };
        case 'CLEAR_EMPLOYEEMASTER':
            return {
                ...state,
                isLoadingEmployee: false,
                statusEmployee: action.status
            };

        default: return state;
    }
};