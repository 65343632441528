import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import * as MetaData from '../models/MetaData';

interface RequestFunctionAction {
    type: 'REQUEST_METADATA';
    version: number;
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_METADATA';
    model: MetaData.ResponseMetaData;
    version: number;
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction

export const actionCreators = {
    requestFunction: (version: number, method: string, body: MetaData.MetaData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.file && method === "GET") {

            fetch(`/v1/metadata`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                },
            })
                .then(response => response.json() as Promise<MetaData.ResponseMetaData>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_METADATA', model: data, version: version + 2 });
                })
                .then(error => {
                    dispatch({ type: 'REQUEST_METADATA', version: version + 1 });
                });
        }

        if (appState && appState.file && method === "POST") {
            console.log(localStorage.getItem('DMS_APPLICATION'))
            fetch(`/v1/metadata`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
                .then(response => response.json() as Promise<MetaData.ResponseMetaData>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_METADATA', model: data, version: version + 3 });
                })
                .then(error => {
                    dispatch({ type: 'REQUEST_METADATA', version: version + 1 });
                });
        }

        if (appState && appState.file && method === "PUT") {

            fetch(`/v1/metadata`, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
                .then(response => response.json() as Promise<MetaData.ResponseMetaData>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_METADATA', model: data, version: version + 4 });
                })
                .then(error => {
                    dispatch({ type: 'REQUEST_METADATA', version: version + 1 });
                });
        }

        if (appState && appState.file && method === "DEL") {
            console.log(localStorage.getItem('DMS_APPLICATION'))
            fetch(`/v1/metadata?code=` + body.metadata_code, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                },
            })
                .then(response => response.json() as Promise<MetaData.ResponseMetaData>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_METADATA', model: data, version: version + 5 });
                })
                .then(error => {
                    dispatch({ type: 'REQUEST_METADATA', version: version + 1 });
                });
        }
    }
};

const unloadedState: MetaData.MetaDataState = { isLoading: false, response: undefined, version: 0 };

export const reducer: Reducer<MetaData.MetaDataState> = (state: MetaData.MetaDataState | undefined, incomingAction: Action): MetaData.MetaDataState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_METADATA':
            return {
                isLoading: false,
                version: action.version
            };
        case 'RECEIVE_METADATA':
            return {
                isLoading: true,
                response: action.model,
                version: action.version
            };

        default: return state;
    }
};