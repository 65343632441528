import { ResponseList } from './Common';

export interface AuthorizeStateStore {
    isLoading: boolean;
    response: ResponseList<Authorize> | null;
    displayAuthorize: DisplayAuthorize[];
    status: number;
    error: any;
}

export interface Authorize {
    id: string;
    name: string;
}

export interface DisplayAuthorize {
    label: string;
    value: string;
}

export const DefaultRoleAuthorize: DisplayAuthorize[] = [
    {
        label: "Create",
        value: "01"
    },
    {
        label: "Edit",
        value: "02"
    },
    {
        label: "Display",
        value: "03"
    },
    {
        label: "Delete",
        value: "04"
    },
    {
        label: "Copy Link",
        value: "05"
    },
    {
        label: "Move Link",
        value: "06"
    }
]