import React, { FormEvent, memo, useRef } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';

import { FaSearch } from 'react-icons/fa';

type Props = {
    pageSize: number;
    setPage: (page: number) => void;
    setPageSize: (pageSize: number) => void;
    onSubmitSearch: (citizenId: string) => void;
};

function AuthenticationHistorySearchBar(props: Props) {
    const { pageSize, setPage, setPageSize, onSubmitSearch } = props;

    const citizenIdInputRef = useRef<HTMLInputElement>(null);

    const handleSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const citizenIdInput = citizenIdInputRef.current;
        if (!citizenIdInput) return;

        setPage(1);
        onSubmitSearch(citizenIdInput.value.trim());
    };

    return (
        <form onSubmit={handleSubmitSearch}>
            <Row>
                <Col>
                    <Card>
                        <Card.Header
                            className="h-50 d-inline-block"
                            style={{
                                marginBottom: '-12px',
                                marginTop: '-12px',
                            }}
                        >
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <Form.Label
                                    column
                                    sm="3"
                                >
                                    ค้นหาข้อมูล
                                </Form.Label>
                            </div>
                        </Card.Header>
                        <Card.Body style={{ overflow: 'none' }}>
                            <Row className="mt-2">
                                <Col xs={6}>
                                    <Form.Label
                                        column
                                        sm={12}
                                        // className="text-center"
                                    >
                                        Page Size
                                    </Form.Label>
                                    <Form.Group>
                                        <Select
                                            options={[
                                                { label: 25, value: 25 },
                                                { label: 50, value: 50 },
                                                { label: 100, value: 100 },
                                            ]}
                                            value={{
                                                label: pageSize,
                                                value: pageSize,
                                            }}
                                            onChange={(newValue) =>
                                                newValue &&
                                                setPageSize(newValue.value)
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Label
                                        column
                                        sm={12}
                                    >
                                        Citizen ID
                                    </Form.Label>
                                    <Form.Group controlId="company">
                                        <Form.Control ref={citizenIdInputRef} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button
                                        type="submit"
                                        style={{
                                            marginBottom: '10px',
                                            marginRight: '10px',
                                            float: 'right',
                                        }}
                                        variant="outline-success"
                                        size="sm"
                                    >
                                        <FaSearch />
                                        &nbsp;ค้นหา
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </form>
    );
}

export default memo(AuthenticationHistorySearchBar);
