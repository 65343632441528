import * as FileModel from './../models/File';
import * as FileUploadModel from './../models/FileUpload';
import * as SignInModel from './../models/SignIn';
import * as FolderModel from './../models/Folder';
import * as MetaDataModel from './../models/MetaData';
import * as MetaDataGroupModel from './../models/MetaDataGroup';
import * as RoleAccessModel from './../models/RoleAccess';
import * as EmployeeModel from './../models/Employee';
import * as CompanyMasterModel from './../models/CompanyMaster';
import * as AccessManagementModel from './../models/AccessManagement';
import * as ActionLogModel from './../models/ActionLog';
import * as AppStateModel from './../models/AppState'
import * as CompanyShortNameModel from './../models/CompanyShortName'
import * as GenerateTokenModel from './../models/AccessManagement'
import * as LoggerModel from './../models/Logger'
import * as SearchCriteriaModel from '../models/SearchCriteria';
import * as FileLogModel from '../models/FileLog';
import * as AuthorizeModel from '../models/Authorize';
import * as FolderAuthorizeUserMappingModel from '../models/FolderAuthorizeUserMapping';
import * as FolderAuthorizeModel from '../models/FolderAuthorize';
import { FolderRoleStoreState } from './ui/Role';

import * as File from './File';
import * as FileUpload from './FileUpload';
import * as SignIn from './SignIn';
import * as Folder from './Folder';
import * as FolderManage from './FolderManage';
import * as MetaData from './MetaData';
import * as MetaDataGroup from './MetaDataGroup';
import * as RoleAccess from './RoleAccess';
import * as FileDetail from './FileDetail';
import * as FileManage from './FileManage';
import * as Employee from './EmployeeMaster';
import * as CompanyMaster from './CompanyMaster';
import * as AccessManagement from './AccessManagement';
import * as ActionLog from './ActionLog';
import * as AppStateStore from './AppState'
import * as CompanyShortNameStore from './CompanyShortName'
import * as CheckCompanyUserStore from './CheckCompanyUser'
import * as GenerateTokenStore from './GenerateToken'
import * as LoggerStore from './Logger'
import * as ImportExcelStore from './ImportExcel'
import * as SearchCriteriaStore from './SearchCriteria';
import * as FileLogStore from './FileLog';
import * as AuthorizeStore from './Authorize';
import * as FolderAuthorizeUserMappingStore from './FolderAuthorizeUserMapping';
import * as FolderAuthorizeStore from './FolderAuthorize';
import * as FolderRoleStore from './ui/Role';

// The top-level state object
export interface ApplicationState {
    file: FileModel.FileState | undefined;
    fileDelete: FileModel.FileState | undefined;
    fileEdit: FileModel.FileState | undefined;
    fileDetailImportExcel: FileModel.FileState | undefined;
    fileUpload: FileUploadModel.FileState | undefined;
    signIn: SignInModel.SignInState | undefined;
    folderMap: FolderModel.FolderMapState | undefined;
    folder: FolderModel.FolderState | undefined;
    metaData: MetaDataModel.MetaDataState | undefined;
    metaDataGroup: MetaDataGroupModel.MetaDataGroupState | undefined;
    roleAccess: RoleAccessModel.RoleAccessState | undefined;
    fileDetail: FileModel.FileState | undefined;
    employee: EmployeeModel.EmployeeState | undefined;
    companyMaster: CompanyMasterModel.CompanyMasterState | undefined;
    accessManagement: AccessManagementModel.AccessManagementState | undefined;
    actionLog: ActionLogModel.ActionLogState | undefined;
    appState: AppStateModel.AppState | undefined;
    CompanyShortName: CompanyShortNameModel.CompanyShortNameState | undefined;
    CheckCompanyUser: EmployeeModel.EmployeeState | undefined;
    generateToken: GenerateTokenModel.GenerateTokenState | undefined;
    Logger: LoggerModel.GetLoggerState | undefined;
    ImportExcel: EmployeeModel.ImportEmployeeState | undefined;
    searchCriteria: SearchCriteriaModel.SearchCriteriaStoreState | undefined;
    fileLog: FileLogModel.FileLogStoreState | undefined;
    authorize: AuthorizeModel.AuthorizeStateStore | undefined;
    folderAuthorize: FolderAuthorizeModel.FolderAuthorizeStateStore | undefined;
    folderAuthorizeUserMapping: FolderAuthorizeUserMappingModel.FolderAuthorizeUserMappingStateStore | undefined;
    folderRole: FolderRoleStoreState | undefined;
}

export const reducers = {
    file: File.reducer,
    fileDelete: FileManage.reducer,
    fileEdit: FileManage.reducer,
    fileDetailImportExcel: FileManage.reducer,
    fileUpload: FileUpload.reducer,
    signIn: SignIn.reducer,
    folderMap: Folder.reducer,
    folder: FolderManage.reducer,
    metaData: MetaData.reducer,
    roleAccess: RoleAccess.reducer,
    fileDetail: FileDetail.reducer,
    metaDataGroup: MetaDataGroup.reducer,
    employee: Employee.reducer,
    companyMaster: CompanyMaster.reducer,
    accessManagement: AccessManagement.reducer,
    actionLog: ActionLog.reducer,
    appState: AppStateStore.reducer,
    CompanyShortName: CompanyShortNameStore.reducer,
    CheckCompanyUser: CheckCompanyUserStore.reducer,
    generateToken: GenerateTokenStore.reducer,
    Logger: LoggerStore.rootReducer,
    ImportExcel: ImportExcelStore.rootReducer,
    searchCriteria: SearchCriteriaStore.reducer,
    fileLog: FileLogStore.reducer,
    authorize: AuthorizeStore.reducer,
    folderAuthorize: FolderAuthorizeStore.reducer,
    folderAuthorizeUserMapping: FolderAuthorizeUserMappingStore.reducer,
    folderRole: FolderRoleStore.reducer
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
