import { Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ResponseList } from '../models/Common';
import { FolderAuthorizeUserMappingStateStore, FolderAuthorizeUserMapping } from '../models/FolderAuthorizeUserMapping';

interface RequestFolderAuthUserMapping {
    type: 'REQUEST_FOLDERAUTHUSERMAPPING';
}

interface ReceiveFolderAuthUserMapping {
    type: 'RECEIVE_FOLDERAUTHUSERMAPPING';
    data: ResponseList<FolderAuthorizeUserMapping>;
}

interface FailureFolderAuthUserMapping {
    type: 'FAILURE_FOLDERAUTHUSERMAPPING';
    error: any;
}

interface ClearFolderAuthUserMapping {
    type: 'CLEAR_FOLDERAUTHUSERMAPPING',
}

type KnownAction =
    | RequestFolderAuthUserMapping
    | ReceiveFolderAuthUserMapping
    | FailureFolderAuthUserMapping
    | ClearFolderAuthUserMapping;

interface Params {
    app_id: string | string[];
    username: string;
    role: string;
    is_active: boolean;
    bucket_id: string;
}

export const actionCreators = {
    requestGetFolderAuthUserMapping: (options: Partial<Params>): AppThunkAction<KnownAction> => (dispatch, _getState) => {
        dispatch({ type: 'REQUEST_FOLDERAUTHUSERMAPPING' });

        fetch('/v1/folder-auth-user-mapping?' + new URLSearchParams(options as any), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION')
            }
        })
            .then(response => {
                //console.log(response)
                if (response.status === 200) {
                    return response.json();
                } else {
                    Promise.reject(response.json());
                }
            })
            .then(data => dispatch({ type: 'RECEIVE_FOLDERAUTHUSERMAPPING', data }))
            .catch(error => dispatch({ type: 'FAILURE_FOLDERAUTHUSERMAPPING', error }));
    }
}

const initialState: FolderAuthorizeUserMappingStateStore = {
    isLoadingFAUM: false,
    responseFAUM: null,
    statusFAUM: 0,
    errorFAUM: null
};

export const reducer: Reducer<FolderAuthorizeUserMappingStateStore> = (state = initialState, action: KnownAction) => {
    switch (action.type) {
        case 'REQUEST_FOLDERAUTHUSERMAPPING': {
            return {
                ...state,
                isLoadingFAUM: true
            }
        }
        case 'RECEIVE_FOLDERAUTHUSERMAPPING': {
            return {
                ...state,
                isLoadingFAUM: false,
                responseFAUM: action.data,
                statusFAUM: 200
            }
        }
        case 'FAILURE_FOLDERAUTHUSERMAPPING': {
            return {
                ...state,
                isLoadingFAUM: false,
                statusFAUM: 400,
                errorFAUM: action.error
            }
        }
        case 'CLEAR_FOLDERAUTHUSERMAPPING': {
            return initialState;
        }
        default: return state;
    }
}