const extractSignature = require('./extractSignature');
const {
    sortCertificateChain,
    getClientCertificate
} = require('./certsUtils');
/*const certsUtils = require('./certsUtils');*/
const {
    checkForSubFilter,
    getSignatureMeta,
    getMessageFromSignature,
    preparePDF,
    getLastModify
} = require('./general')
//const generalHelpers = require('./general');
const {
    authenticateSignature,
    verifyCaBundle,
    verifyRootCert,
    isCertsExpired,
} = require('./verification');
//const verificationHelpers = require('./verification');

//module.exports = {
//  extractSignature,
//  ...certsUtils,
//  ...generalHelpers,
//  ...verificationHelpers,
//};

export {
    extractSignature,

    sortCertificateChain,
    getClientCertificate,

    checkForSubFilter,
    getSignatureMeta,
    getMessageFromSignature,
    preparePDF,
    getLastModify,

    authenticateSignature,
    verifyCaBundle,
    verifyRootCert,
    isCertsExpired,
}


