import * as React from 'react';
import "./View.css";
import * as QueryString from 'query-string'
import PDFView from './PDFView'
import IMGView from './IMGView'
import TXTView from './TXTView'
import DownloadView from './DownloadView'

class SecureView extends React.Component<any, { file_path: string, file_type: string, download: boolean }> {
    constructor(props: any) {
        super(props);

        this.state = {
            file_path: '',
            file_type: '',
            download: false
        };

        this.renderSwitch = this.renderSwitch.bind(this);
    }

    componentDidMount() {
        if (this.props.location.search) {
            window.history.pushState({}, "DMS View", "/view");
            //console.log(this.props.location.search)
            let { key } = QueryString.parse(this.props.location.search);

            fetch("/v1/secure-view?key=" + key)
                .then(res => res.json())
                .then(
                    (result) => {
                        var queryString = result.message;

                        if (queryString) {
                            fetch("/v1/env")
                                .then(res => res.json())
                                .then(
                                    (result) => {
                                        var resultData = result.apiUrl;

                                        if (queryString != undefined) {
                                            var fileExtension = '';
                                            let p = resultData + '/v1/dms/file' + queryString;
                                            var queryParams = new URLSearchParams(queryString);
                                            var file_name = queryParams.get("file_name");
                                            if (file_name) {
                                                fileExtension = String(file_name).substr(String(file_name).lastIndexOf('.'));
                                            }

                                            var d = false
                                            if (queryString.toLowerCase().includes("&download=true")) {
                                                d = true
                                            }

                                            this.setState({
                                                file_path: p,
                                                file_type: fileExtension,
                                                download: d
                                            });
                                        }
                                    },
                                    (error) => {
                                    }
                                )
                        }
                    },
                    (error) => {
                    }
                )
        }
    }

    renderSwitch(file_path: any, file_type: any, download: any) {
        if (download == true) {
            return <DownloadView filePath={file_path} />;
        }
        else {
            switch (file_type.toLowerCase()) {
                case '.pdf':
                    return <PDFView filePath={file_path} />;
                case '.txt':
                case '.json':
                    return <TXTView filePath={file_path} />;
                case '.png':
                case '.gif':
                case '.jpg':
                case '.jpeg':
                    return <IMGView filePath={file_path} />;
                default:
                    return <DownloadView filePath={file_path} />;
            }
        }
    }

    render() {
        let { file_path, file_type, download } = this.state
        if (file_path) {
            return (
                <div>
                    {this.renderSwitch(file_path, file_type, download)}
                </div>
            );
        } else {
            return (
                <div>
                </div>
            );
        }
    }
}

export default SecureView;