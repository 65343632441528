import * as Promise from 'bluebird';
import { Action, Reducer } from 'redux';
import * as MetaDataGroup from '../models/MetaDataGroup';
import { AppThunkAction } from './';

interface RequestFunctionAction {
    type: 'REQUEST_METADATAGROUP';
    version: number;
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_METADATAGROUP';
    model: MetaDataGroup.ResponseMetaDataGroup;
    version: number;
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction

export const actionCreators = {
    requestFunctionGroup: (version: number, method: string, body: MetaDataGroup.MetaDataGroup, valueselect: any, metadata_code: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.file && method === "GET") {
            fetch(`/v1/metadata-group`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                },
            })
                .then(response => response.json() as Promise<MetaDataGroup.ResponseMetaDataGroup>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_METADATAGROUP', model: data, version: version + 2 });
                })
                .then(error => {
                    dispatch({ type: 'REQUEST_METADATAGROUP', version: version + 1 });
                });
        }

        if (appState && appState.file && method === "POST" || method === "POST_SELECT") {
            Promise.all(valueselect.map(value =>
                fetch(`/v1/metadata-group`, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                        'Content-Type': 'application/json',
                    },

                    body: JSON.stringify({
                        metadata_group_code: body.metadata_group_code,
                        metadata_group_name: body.metadata_group_name,
                        metadata_code: value
                    }),
                })
                    .then(function (response) {
                        return response.json() as Promise<MetaDataGroup.ResponseMetaDataGroup>
                    })
            ))
                .then(data => {
                    fetch(`/v1/metadata-group`, {
                        method: 'GET',
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                        },
                    })
                        .then(response => response.json() as Promise<MetaDataGroup.ResponseMetaDataGroup>)
                        .then(data => {
                            if (method === "POST_SELECT") {
                                dispatch({ type: 'RECEIVE_METADATAGROUP', model: data, version: version + 10 });
                            } else {
                                dispatch({ type: 'RECEIVE_METADATAGROUP', model: data, version: version + 2 });
                            }
                        })
                        .then(error => {
                            if (method === "POST_SELECT") {
                                dispatch({ type: 'REQUEST_METADATAGROUP', version: version + 10 });
                            } else {
                                dispatch({ type: 'REQUEST_METADATAGROUP', version: version + 1 });
                            }
                        });
                })
                .catch(error => {

                    dispatch({ type: 'REQUEST_METADATAGROUP', version: version + 1 });

                });
        }

        if (appState && appState.file && method === "PUT") {

            fetch(`/v1/metadata-group`, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
                .then(response => response.json() as Promise<MetaDataGroup.ResponseMetaDataGroup>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_METADATAGROUP', model: data, version: version + 4 });
                })
                .then(error => {
                    dispatch({ type: 'REQUEST_METADATAGROUP', version: version + 1 });
                });
        }

        if (appState && appState.file && method === "DEL") {
            fetch(`/v1/metadata-group?code=` + body.metadata_group_code, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                },
            })
                .then(response => response.json() as Promise<MetaDataGroup.ResponseMetaDataGroup>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_METADATAGROUP', model: data, version: version + 5 });
                })
                .then(error => {
                    dispatch({ type: 'REQUEST_METADATAGROUP', version: version + 1 });
                });
        }

        if (appState && appState.file && method === "DEL_META") {
            fetch('/v1/metadata-mapping?metadata_group_code=' + body.metadata_group_code + '&metadata_code=' + metadata_code, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('DMS_APPLICATION'),
                },
            })
                .then(response => response.json() as Promise<MetaDataGroup.ResponseMetaDataGroup>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_METADATAGROUP', model: data, version: version + 10 });
                })
                .then(error => {
                    dispatch({ type: 'REQUEST_METADATAGROUP', version: version + 10 });
                });
        }

        if (appState && appState.file && method === "CLEAR") {
            dispatch({ type: 'REQUEST_METADATAGROUP', version: version + 1 });
        }
    }
};

const unloadedState: MetaDataGroup.MetaDataGroupState = { isLoadingGroup: false, responseGroup: undefined, versionGroup: 0 };

export const reducer: Reducer<MetaDataGroup.MetaDataGroupState> = (state: MetaDataGroup.MetaDataGroupState | undefined, incomingAction: Action): MetaDataGroup.MetaDataGroupState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_METADATAGROUP':
            return {
                isLoadingGroup: false,
                versionGroup: action.version
            };
        case 'RECEIVE_METADATAGROUP':
            return {
                isLoadingGroup: true,
                responseGroup: action.model,
                versionGroup: action.version
            };

        default: return state;
    }
};