import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/FileDetail';
import * as Models from '../../models/File';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import Swal from 'sweetalert2';
import { formatYMDToDMY } from '../../modules/common'
import CreatableSelect from 'react-select/creatable';
import { UserContext, IUserContext } from '../../context/UserProvider';

const components = {
    DropdownIndicator: null,
};

type Store = Models.FileDetailState & typeof Stores.actionCreators;

class FileDetailComponent extends React.PureComponent<any, {
    checkDetail: boolean, pageLoad: boolean, version: number, isModalDetailOpen: boolean, fileSelected: Models.File, isEditBucket: boolean, bucket: string, index_string: string, role_access: string, file_name: string, metadata: string, group_id: string, sub_group_id: string, box_id: string, relation: string
}, Store> {

    static contextType: React.Context<any> | undefined = UserContext;

    constructor(props: any) {
        super(props);

        this.state = {
            pageLoad: true,
            version: 0,
            isModalDetailOpen: false,
            fileSelected: {
                id: "",
                primary_index: "",
                version: 0,
                verify: false,
                index_string: "",
                role_access: "",
                file_name: "",
                file_path: "",
                created_by: "",
                created_on: "",
                updated_by: "",
                updated_on: "",
                sid: "",
                metadata: "",
                bucket: "",
                full_path: "",
                group_id: "",
                sub_group_id: "",
                box_id: "",
                relation: ""
            },
            isEditBucket: false,
            bucket: "",
            index_string: "",
            role_access: "",
            file_name: "",
            metadata: "",
            group_id: "",
            sub_group_id: "",
            box_id: "",
            relation: "",
            checkDetail: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeData = this.handleChangeData.bind(this);
        this.split_val = this.split_val.bind(this);
    }

    componentDidUpdate() {
        if (this.props.versionDetail > this.state.version) {
            if (this.props.versionDetail - this.state.version === 2 && this.state.checkDetail === true) {
                document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
                Swal.fire({
                    title: 'Save Success',
                    icon: 'success'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.toggleModalDetail();
                        this.props.afterSaveModalDetail();
                    }
                })

            }

            this.setState({ version: this.props.versionDetail, checkDetail: false });
        }

    }

    toggleModalDetail = () => {
        if (this.state.isModalDetailOpen) {
            this.setState({
                isModalDetailOpen: false
            })
        } else {
            var file = this.props.fileSelected as Models.File;
            this.setState({
                fileSelected: file,
                bucket: file.bucket,
                index_string: file.index_string,
                role_access: file.role_access,
                file_name: file.file_name,
                metadata: file.metadata,
                group_id: file.group_id,
                sub_group_id: file.sub_group_id,
                box_id: file.box_id,
                relation: file.relation,
                isModalDetailOpen: true
            })
        }
    }

    private handleChange(e: any) {
        if (e.target.name === "bucket") {
            this.setState({ bucket: e.target.value })
        } else if (e.target.name === "role_access") {
            this.setState({ role_access: e.target.value })
        } else if (e.target.name === "file_name") {
            this.setState({ file_name: e.target.value })
        } else if (e.target.name === "group_id") {
            this.setState({ group_id: e.target.value })
        } else if (e.target.name === "sub_group_id") {
            this.setState({ sub_group_id: e.target.value })
        } else if (e.target.name === "box_id") {
            this.setState({ box_id: e.target.value })
        }

    }

    private split_val = (data) => {
        var DataSelectArray: any = []
        var Data: any = []
        var val = "";

        if (data !== null && data !== undefined && data !== "") {
            if (data.length === 1) {
                if (data[0].value !== undefined) {
                    let arr = {}
                    arr['value'] = data[0].value
                    arr['label'] = data[0].label
                    DataSelectArray.push(arr)

                } else {
                    val = data
                    for (var i = 0; i < val.length; i++) {
                        let data = {}
                        data['value'] = val[i]
                        data['label'] = val[i]
                        DataSelectArray.push(data)
                    }
                }

            } else {
                val = data.split("|")
                for (var i = 0; i < val.length; i++) {
                    let data = {}
                    data['value'] = val[i]
                    data['label'] = val[i]
                    DataSelectArray.push(data)
                }
            }


        }
        return DataSelectArray;
    }

    handleChangeData = (valueChange: any, actionMeta: any, checkRow: any, key: any) => {
        const { fileSelected } = this.state
        var onclickMass: any = fileSelected
        if (actionMeta === "relation") {
            var data: string = ""
            if (valueChange.length === 1) {
                data = valueChange[0].value
            } else if (valueChange.length > 1) {
                for (var i = 0; i < valueChange.length; i++) {
                    if (valueChange.length <= 1) {
                        data += valueChange[i].value
                    } else if (i >= valueChange.length - 1) {
                        data += valueChange[i].value
                    } else {
                        data += valueChange[i].value + "|"
                    }
                }
            }
            onclickMass.relation = data
            this.setState({ relation: data })
        } else if (actionMeta === "indexString") {
            var data: string = ""
            if (valueChange.length === 1) {
                data = valueChange[0].value
            } else if (valueChange.length > 1) {
                for (var i = 0; i < valueChange.length; i++) {
                    if (valueChange.length <= 1) {
                        data += valueChange[i].value
                    } else if (i >= valueChange.length - 1) {
                        data += valueChange[i].value
                    } else {
                        data += valueChange[i].value + "|"
                    }
                }
            }
            onclickMass.index_string = data
            this.setState({ index_string: data })
        } else if (actionMeta === "metadata") {
            var data: string = ""
            if (valueChange.length === 1) {
                data = valueChange[0].value
            } else if (valueChange.length > 1) {
                for (var i = 0; i < valueChange.length; i++) {
                    if (valueChange.length <= 1) {
                        data += valueChange[i].value
                    } else if (i >= valueChange.length - 1) {
                        data += valueChange[i].value
                    } else {
                        data += valueChange[i].value + "|"
                    }
                }
            }
            onclickMass.metadata = data
            this.setState({ metadata: data })
        }
    }

    handleInputChange = (inputValueSearchKey: string, check: string) => {
        if (check === "Total") {
            /*this.setState({ inputValueTotal: inputValueSearchKey });*/
            /*console.log('inputValueSearchKey')
            console.log(inputValueSearchKey)*/
        }
        else {
            /*this.state.inputValue[this.state.inputSearchKeyIndex] = inputValueSearchKey;
            this.setState({ inputValue: this.state.inputValue });*/
        }
    };

    saveBucketClick = () => {
        document.getElementsByClassName('Loader__background')[0].className = "Loader__background show";

        var file: Models.File = this.state.fileSelected;
        file.bucket = this.state.bucket;
        file.index_string = this.state.index_string
        file.role_access = this.state.role_access
        file.file_name = this.state.file_name
        file.metadata = this.state.metadata
        file.group_id = this.state.group_id
        file.sub_group_id = this.state.sub_group_id
        file.box_id = this.state.box_id
        file.relation = this.state.relation

        this.setState({ fileSelected: file });

        setTimeout(() => {
            this.setState({ checkDetail: true })
            this.props.requestFunctionMeta(this.state.pageLoad, this.state.version, "PUT", this.state.fileSelected);
        }, 500);
    }

    public hasValidDigits(boxId: string): number {
        switch (boxId.length) {
            case 15: {
                return 1
            }
            case 0: {
                return -1
            }
            default: {
                return 0
            }
        }
    }

    public hasBoxIdAnd15Digits(): boolean {
        if (this.state.box_id) {
            return this.state.box_id.length === 15;
        }
        else {
            return false
        }
    }

    render() {
        const { disabledEdit } = this.props;

        if (this.props.isModalDetailOpen) {
            this.toggleModalDetail();

            this.props.afterOpenModalDetail();
        }

        const { roles }: any = this.context;

        return (
            <div className="">

                <Modal isOpen={this.state.isModalDetailOpen} className="modal-file-detail">
                    <ModalHeader toggle={this.toggleModalDetail} className="file-detail" >
                        Detail
                        <span style={{ color: "#117a8b" }}>{this.state.fileSelected.file_name}</span>
                    </ModalHeader>
                    <ModalBody toggle={this.toggleModalDetail} style={{ maxHeight: 'calc(100vh - 100px)' }}>
                        <div className="div-table" >
                            <div className='trapezoid' style={{ width: 'auto', display: 'inline-block', marginLeft: '0px' }}>
                                <span style={{ lineHeight: '0px' }} className="file-detail">
                                    {this.state.fileSelected.file_name}
                                </span>
                            </div>
                            <table className='table custom-table table-hover'>
                                <thead>
                                    <tr>
                                        <th style={{ width: '150px', fontFamily: "sans-serif" }} >Header</th>
                                        <th className="file-detail" >Detail</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td className="td-header" >Id</td>
                                        <td style={{ paddingBottom: "5px", paddingTop: "5px" }} >{this.state.fileSelected.id}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-header" >Primary Index</td>
                                        <td style={{ paddingBottom: "5px", paddingTop: "5px" }} >{this.state.fileSelected.primary_index}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-header" style={{ height: "22px" }}>File Path</td>
                                        <td style={{ paddingBottom: "5px", paddingTop: "5px" }} >{this.state.fileSelected.file_path}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-header" style={{ height: "22px" }}>File Name</td>
                                        <td style={{ paddingBottom: "5px", paddingTop: "5px" }} >
                                            {/*<Input placeholder="File Name" type="text" name="file_name" value={this.state.file_name} onChange={this.handleChange} style={{ width: "100%", height: "22px", display: "inline", fontSize: "12px" }} />*/}
                                            {this.state.file_name}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="td-header" style={{ height: "22px" }} >Folder</td>
                                        <td style={{ paddingBottom: "5px", paddingTop: "5px" }} >
                                            {/*<Input placeholder="Bucket" type="text" name="bucket" value={this.state.bucket} onChange={this.handleChange} style={{ width: "100%", height: "22px", display: "inline", fontSize: "12px" }} />{' '}*/}
                                            {this.state.bucket}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="td-header" style={{ height: "22px" }}>Group Id</td>
                                        <td style={{ paddingBottom: "5px", paddingTop: "5px" }} >
                                            {/*<Input placeholder="Group Id" type="text" name="group_id" value={this.state.group_id} onChange={this.handleChange} style={{ width: "100%", height: "22px", display: "inline", fontSize: "12px" }} />*/}
                                            {this.state.group_id}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="td-header" style={{ height: "22px" }}>Sub Group Id</td>
                                        <td style={{ paddingBottom: "5px", paddingTop: "5px" }} >
                                            {/*<Input placeholder="Sub Group Id" type="text" name="sub_group_id" value={this.state.sub_group_id} onChange={this.handleChange} style={{ width: "100%", height: "22px", display: "inline", fontSize: "12px" }} />*/}
                                            {this.state.sub_group_id}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="td-header" style={{ height: "22px" }}>Box Id</td>
                                        <td style={{ paddingBottom: "5px", paddingTop: "5px" }} >
                                            {this.state.box_id}
                                            {/*<Input*/}
                                            {/*    placeholder="Box Id"*/}
                                            {/*    type="text"*/}
                                            {/*    name="box_id"*/}
                                            {/*    value={this.state.box_id}*/}
                                            {/*    onChange={this.handleChange}*/}
                                            {/*    style={{*/}
                                            {/*        width: "100%",*/}
                                            {/*        height: "22px",*/}
                                            {/*        display: "inline",*/}
                                            {/*        fontSize: "12px",*/}
                                            {/*        borderColor: this.hasBoxIdAnd15Digits() ? 'green' : 'red',*/}
                                            {/*        color: this.hasBoxIdAnd15Digits() ? 'green' : 'red'*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="td-header" style={{ height: "22px" }}>Role Access</td>
                                        <td style={{ paddingBottom: "5px", paddingTop: "5px" }} >
                                            {/*<Input placeholder="Role Access" type="text" name="role_access" value={this.state.role_access} onChange={this.handleChange} style={{ width: "100%", height: "22px", display: "inline", fontSize: "12px" }} />*/}
                                            {this.state.role_access}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="td-header" >Relation Id</td>
                                        <td style={{ paddingBottom: "5px", paddingTop: "5px" }} >
                                            <CreatableSelect
                                                components={components}
                                                isMulti
                                                isClearable
                                                defaultValue={this.split_val(this.state.relation)}
                                                onChange={(e, a) => this.handleChangeData(e, "relation", a, "")}
                                                onInputChange={(e, a) => this.handleInputChange(e, a)}
                                                value={this.split_val(this.state.relation)}
                                                isDisabled
                                            />
                                            {/*<span style={{ fontSize: "12px" }}>หมายเหตุ: </span><span style={{ fontSize: "12px", color: "red" }}>กรุณากรองข้อความเเละกดปุ่ม Enter</span>*/}
                                        </td>
                                    </tr>
                                    <tr>
                                        {/*<td className="td-header" >Index String</td>*/}
                                        <td className="td-header"> Search Key</td>
                                        <td style={{ paddingBottom: "5px", paddingTop: "5px" }} >
                                            <CreatableSelect
                                                isDisabled={disabledEdit}
                                                components={components}
                                                isMulti
                                                isClearable
                                                defaultValue={this.split_val(this.state.index_string)}
                                                onChange={(e, a) => this.handleChangeData(e, "indexString", a, "")}
                                                onInputChange={(e, a) => this.handleInputChange(e, a)}
                                                value={this.split_val(this.state.index_string)}
                                            />
                                            <span style={{ fontSize: "12px" }}>หมายเหตุ: </span><span style={{ fontSize: "12px", color: "red" }}>กรุณากรองข้อความเเละกดปุ่ม Enter</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="td-header" style={{ height: "22px" }} >Metadata</td>
                                        <td style={{ paddingBottom: "5px", paddingTop: "5px" }} >
                                            <CreatableSelect
                                                isDisabled={disabledEdit}
                                                components={components}
                                                isMulti
                                                isClearable
                                                defaultValue={this.split_val(this.state.metadata)}
                                                onChange={(e, a) => this.handleChangeData(e, "metadata", a, "")}
                                                onInputChange={(e, a) => this.handleInputChange(e, a)}
                                                value={this.split_val(this.state.metadata)}
                                            />
                                            <span style={{ fontSize: "12px" }}>หมายเหตุ: </span><span style={{ fontSize: "12px", color: "red" }}>กรุณากรองข้อความเเละกดปุ่ม Enter</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="td-header" >Version</td>
                                        <td style={{ paddingBottom: "5px", paddingTop: "5px" }} >{this.state.fileSelected.version}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-header" >Created By</td>
                                        <td style={{ paddingBottom: "5px", paddingTop: "5px" }} >{this.state.fileSelected.created_by}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-header" >Created On</td>
                                        <td style={{ paddingBottom: "5px", paddingTop: "5px" }} >{formatYMDToDMY(this.state.fileSelected.created_on)}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-header" >Updated By</td>
                                        <td style={{ paddingBottom: "5px", paddingTop: "5px" }} >{this.state.fileSelected.updated_by}</td>
                                    </tr>
                                    <tr>
                                        <td className="td-header" >Updated On</td>
                                        <td style={{ paddingBottom: "5px", paddingTop: "5px" }} >{formatYMDToDMY(this.state.fileSelected.updated_on)}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {/* !this.hasBoxIdAnd15Digits() && <span className="text-danger" style={{ fontSize: '12px' }}>กรุณากรอก Box Id ให้ครบ 15 หลัก</span> */}
                        <Button
                            color="success"
                            style={{ fontFamily: "sans-serif" }}
                            onClick={this.saveBucketClick}
                            // disabled={
                            //     !this.hasBoxIdAnd15Digits() ||
                            //         (this.props.strictMode)
                            //         ? !roles.includes('02')
                            //         : false
                            // }
                            disabled={disabledEdit}
                        >
                            Save
                        </Button>
                        <Button style={{ margin: '-5px 0px -5px 0px' }} color="secondary" className="file-detail" onClick={this.toggleModalDetail}>Close</Button>
                    </ModalFooter>
                </Modal>

            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({ ...state.fileDetail, ...state.folderRole }),
    Stores.actionCreators
)(FileDetailComponent);

