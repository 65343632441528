import React, { useState, useEffect, createContext } from 'react';
import { ResponseList } from '../models/Common';
import { IDefaultValue, DefaultValueContextType } from '../models/DefaultValue';

const DefaultValueContext = createContext<DefaultValueContextType | null>(null);

function DefaultValueProvider({ children }) {
    const [defaultValues, setDefaultValues] = useState<IDefaultValue[]>([]);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        getDefaultValues();
    }, []);

    function getDefaultValue(key: string): IDefaultValue | null {
        const found = defaultValues.find(element => element.key === key);

        return (found !== undefined)
            ? found
            : null;
    }

    async function getDefaultValues(): Promise<void> {
        try {
            const response = await fetch('/v1/default-value', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('DMS_APPLICATION')}`
                }
            });

            if (response.ok) {
                const result: ResponseList<IDefaultValue> = await response.json();

                if (result.total_count > 0) {
                    setDefaultValues(result.result_list);
                }
            } else {
                throw await response.json();
            }
        } catch (error) {
            setError(error);
        }
    }

    const provideValue: DefaultValueContextType = {
        defaultValues,
        error,
        getDefaultValue
    }

    return (
        <DefaultValueContext.Provider value={provideValue}>
            {children}
        </DefaultValueContext.Provider>
    );
}

export {
    DefaultValueContext
}
export default DefaultValueProvider;